import React from 'react';
import Spinner from 'assets/images/loader.gif';

const Loader = () => {
  return (
    <div className="d-flex align-items-center h_100vh">
      <img src={Spinner} alt='' className='m-auto' style={{ width: '4.5rem' }} />
    </div>
  )
}

export default Loader;