import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import OneWayGoogleAddress from './OneWayGoogleAddress'
import { CarterX_API } from 'api/api';
import axios from 'utils/axios';
import { toast } from 'react-toastify';
import { usePlacesWidget } from "react-google-autocomplete";


export default function RoundTrip(props) {
  const { activeTab, airPortMasterList, cityList, roundPickUp, setRoundPickUp, roundPickDropDetails, setRoundPickDropDetails, setRoundFullAddress, setMainPinCode1, minDate, setValue, register, errors, slots, setSlotTwo, setRoundArea, errorRoundCity, setErrorRoundCity, getValues, setRoundMinutes, setRoundHours, roundHours, roundMinutes, trip } = props;
  const year = new Date().getFullYear();
  const month = new Date().getMonth();
  const [airPortOption, setAirPortOption] = useState(null)
  // const [selectedCity1, setSelectedCity1] = useState('')
  // const [selectedAirPort1, setSelectedAirPort1] = useState('')
  const [minDate1, setMinDate1] = useState('')
  const [cusDateError, setCusDateError] = useState('');
  const [addressLine, setAddressLine] = useState('');
  const [selectedAddress, setSelectedAddress] = useState({
    addressLine1: '',
    addressLine2: '',
    city: ''
  });
  // const [errorAddressCity, setErrorAddressCity] = useState(false)
  const [fullAddress, setFullAddress] = useState('')
  // const [pickUp, setPickUp] = useState('');
  // const [hours, setHours] = useState(0);
  // const [minutes, setMinutes] = useState(0);
  const [timePickerShow, setTimePickerShow] = useState(false);


  useEffect(() => {
    if (roundPickUp != '' && roundPickUp != 'Airport: Drop off Point' && roundPickUp != 'Airport: Pickup Point') {
      document.getElementById('addressLineText1').value = ''
    }
    // document.getElementById('addressLineText1').value = '',
    setSelectedAddress({
      addressLine1: '',
      addressLine2: '',
      city: ''
    })
    setAddressLine('')
    let changeDay = new Date(minDate).getDate();
    let changeMonth = new Date(minDate).getMonth();
    let changeYear = new Date(minDate).getFullYear();
    if (activeTab === 'arrival') {
      if (new Date(minDate).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
        setValue('serDate1', new Date(changeYear, changeMonth, changeDay + ((new Date().getHours() + 0 > 23 || roundPickUp === 'Doorstep Pickup') ? 1 : 1), 10, 33, 30, 0).toISOString().split("T")[0])
        setMinDate1(new Date(changeYear, changeMonth, changeDay + ((new Date().getHours() + 0 > 23 || roundPickUp === 'Doorstep Pickup') ? 1 : 1), 10, 33, 30, 0).toISOString().split("T")[0])
      } else {
        setValue('serDate1', new Date(changeYear, changeMonth, changeDay , 10, 33, 30, 0).toISOString().split("T")[0])
        setMinDate1(new Date(changeYear, changeMonth, changeDay , 10, 33, 30, 0).toISOString().split("T")[0])
      }

    } else {
      setValue('serDate1', new Date(changeYear, changeMonth, changeDay, 10, 33, 30, 0).toISOString().split("T")[0])
      setMinDate1(new Date(changeYear, changeMonth, changeDay, 10, 33, 30, 0).toISOString().split("T")[0])
    }
    // setValue('roundPickUp', activeTab != 'departure' ? 'Airport: Drop off Point' : 'Airport: Pickup Point')
    // setRoundPickUp(activeTab === 'departure' ? 'Airport: Drop off Point' : 'Airport: Pickup Point')

    let filterAirPortData = []
    airPortMasterList && airPortMasterList.map((item) => {
      if (Number(item.airport_name_id) == 3 || 
      // Number(item.airport_name_id) == 7 ||
      //  Number(item.airport_name_id) == 8 || Number(item.airport_name_id) == 9 || 
       Number(item.airport_name_id) == 12 || Number(item.airport_name_id) == 13 || Number(item.airport_name_id) == 14 || Number(item.airport_name_id) == 28) {
        filterAirPortData.push(item);
      }
    })
    setAirPortOption(_.compact(filterAirPortData))
    verify_date_time();
  }, [minDate, airPortMasterList])

  useEffect(() => {
    setValue('roundPickUp', activeTab != 'departure' ? 'Airport: Drop off Point' : 'Airport: Pickup Point')
    setRoundPickUp(activeTab != 'departure' ? 'Airport: Drop off Point' : 'Airport: Pickup Point')
    verify_date_time();
  }, [])

  useEffect(() => {
    console.log('calling..2..')
    verify_date_time();
  }, [trip])



  const setSelectedCityHandler = (cityId) => {
    // setSelectedCity1(cityId)
    let filterAirPort = airPortMasterList && airPortMasterList.map(data => data.fk_tbl_city_of_operation_region_id === cityId && data)
    setAirPortOption(_.compact(filterAirPort))
    setMainPinCode1(_.find(cityList, ['region_id', cityId]).pin_code)
    let ctyOne = document.getElementById('cityOne').value ? document.getElementById('cityOne').value : ''
    let ctyTwo = document.getElementById('roundTripCity').value ? document.getElementById('roundTripCity').value : ''
    if (ctyOne == ctyTwo) {
      toast.info('Pickup City and Delivery City sholuld not same')
      setValue('city1', '')
      setValue('airport1', '')
    }
    handleCityChange()
  }


  const dateValidationHandle = (value) => {
    setValue("slots1", '');
    setSlotTwo("")
    // var d1 = new Date(minDate);
    // var d2 = new Date(value);

    // if (activeTab === 'arrival') {
    // if (d1 < d2) {
    //   setCusDateError("");
    // } else {
    //   setCusDateError("Service needs to be booked a day prior");
    // }
    // } else {
    // if (d1 <= d2) {
    //   setCusDateError("");
    // } else {
    //   setCusDateError("Service needs to be booked a day prior");
    // }
    // }

    verify_date_time()
  }

  function verify_date_time() {
    setRoundMinutes(0)
    setRoundHours(0)
    let round_pickUp = (roundPickUp ? roundPickUp : (document.getElementById('round_pickUp').value ? document.getElementById('round_pickUp').value : ''))
    let current_date, selected_date;
    if (activeTab === "departure") {
      current_date = new Date();
         current_date.setHours(0, 0, 0, 0);
         current_date.setDate(current_date.getDate() + 1);
   
         selected_date = new Date(getValues('serDate1') ? getValues('serDate1') : new Date());
         selected_date.setHours(0, 0, 0, 0);
      if (current_date.getTime() === selected_date.getTime()) {
        setRoundMinutes(new Date().getMinutes())
        setRoundHours(new Date().getHours());
      } else {
        setRoundMinutes(0);
        setRoundHours(0);
     }
    }else if (activeTab != "departure" && round_pickUp === 'Airport: Drop off Point') {
      current_date = new Date();
         current_date.setHours(0, 0, 0, 0);
         current_date.setDate(current_date.getDate() + 1); 
   
         selected_date = new Date(getValues('serDate1') ? getValues('serDate1') : new Date());
         selected_date.setHours(0, 0, 0, 0);
      if (current_date.getTime() === selected_date.getTime()) {
        setRoundMinutes(new Date().getMinutes());
        setRoundHours(new Date().getHours());
     } else {
        setRoundMinutes(0);
        setRoundHours(0);
     }
    }
  }

  // const setPinHandler1 = (value) => {
  //   if (value.length === 6) {
  //     pinCodeValidation(value);
  //   }
  // }

  // const pinCodeValidation = (value) => {
  //   axios.request({
  //     method: CarterX_API.checkPinCode.method,
  //     url: CarterX_API.checkPinCode.url,
  //     data: [{
  //       "pincode": value,
  //       "service_type": 2,        // 1 for arrival, 2 for departure
  //       "airport_name_id": selectedAirPort1,
  //       "city_id": selectedCity1
  //     }]
  //   })
  //     .then((res) => {
  //       if (!res.data.status) {
  //         setValue('roundPin', '')
  //         toast.info(res.data.message[0])
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     })
  // }

  //get address by google api

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyBYZ8y8XMESY-rrKXVXahIbNmOTnvsuIhM",
    onPlaceSelected: (place) => {
      handleAddress(place)
    },
    options: {
      types: []
    }
  });

  const submitAddress = () => {
    let str = document.getElementById('addressLineText1').value
    let name = str.split(',')[0]
    if (name && fullAddress != '') {
      setAddressLine(selectedAddress.addressLine2 + ' ' + name + fullAddress)
      setRoundFullAddress(selectedAddress.addressLine2 + ' ' + name + fullAddress)
      setErrorRoundCity(false)
    } else {
      setErrorRoundCity(true)
    }
    document.getElementById('roundAddressForm').classList.remove('show')
  }

  const handleAddress = (value) => {
    const city_detail = value.address_components.filter(res => { return res.types[0] == 'locality' })
    const city_ids = document.getElementById("roundTripCity").value
    const area = value.address_components.filter(res => { return res.types[0] == 'sublocality_level_1' })
    if (area.length != 0 && area[0].long_name) {
      setRoundArea(area[0].long_name)
    }
    const pinCode = value.address_components.filter(res => { return res.types[0] == 'postal_code' })
    if (pinCode.length != 0 && pinCode[0].long_name) { setMainPinCode1(pinCode[0].long_name) }
    const selected_city = city_ids ? cityList.filter(res => { return res.region_id == city_ids }) : undefined
    let city_name = city_detail[0].long_name
    let selected_city_name = city_ids ? selected_city[0].region_name : undefined
    setFullAddress(value.name ? value.name : '' + ', ' + value.formatted_address)
    if ((selected_city_name == 'Mumbai & Navi Mumbai') && (city_name == 'Navi Mumbai' || city_name == 'Thane' || city_name == 'Mumbai' || city_name == 'Mumbai & Navi Mumbai')) {
      setSelectedAddress({ ...selectedAddress, city: 'Mumbai & Navi Mumbai' });
    }
    else if ((selected_city_name == 'New Delhi & NCR') && (city_name == 'New Delhi' || city_name == 'Noida' || city_name == 'Faridabad' || city_name == 'Ghaziabad' || city_name == 'Gurugram' || city_name == 'New Delhi & NCR')) {
      setSelectedAddress({ ...selectedAddress, city: 'New Delhi' });
    }
    else if ((selected_city_name == 'Hyderabad') && (city_name == 'Telangana' || city_name == 'Hyderabad')) {
      setSelectedAddress({ ...selectedAddress, city: 'Hydrabad' });
    }
    else if ((selected_city_name == 'Bengaluru' || selected_city_name == 'Bangalore') && (city_name == 'Bengaluru' || city_name == 'Bangalore')) {
      setSelectedAddress({ ...selectedAddress, city: 'Bengaluru' });
    }
    else if (!selected_city_name || !city_name) {
      toast.info('city should be same as ' + (activeTab != 'departure' ? 'departure ' : 'arrival ') + 'city')
      handleCityChange()
      // setAddressLine('')
      // document.getElementById('addressLineText1').value = ''
      // setSelectedAddress({ ...selectedAddress, city: '' });
      // setFullAddress('')
    }
    else {
      toast.info('city should be same as ' + (activeTab != 'departure' ? 'departure ' : 'arrival ') + 'city');
      handleCityChange()
      // setAddressLine('')
      // document.getElementById('addressLineText1').value = ''
      // setSelectedAddress({ ...selectedAddress, city: '' });
      // setFullAddress('')
    }
  }

  const findSlotValue = (event) => {
    let value = event.target.value
    slots.filter((response) => {
      if (response.time_description == value) {
        activeTab != 'departure' ? selectTimeSlot(response) : selectTimeSlotTwo(response)
      }
    })
  }
  const handleCityChange = () => {
    setAddressLine('')
    // document.getElementById('addressLineText1').value = ''
    setSelectedAddress({ ...selectedAddress, addressLine2: '', city: '' });
    setFullAddress('')
  }

  const selectTimeSlot = (value) => {
    var delivery_date
    var a = value.time_description
    var times = value.slot_end_time;
    let serDate = document.getElementById('datePicker2').value
    var timenumber = times.slice(0, 2);
    var serDate1 = serDate
    var timeOnwards
    var afterBefore
    var show_delivery_time
    var show_select_date_one
    var show_select_date_two

    // round trip
    if (value.id_slots === 7) {
      timeOnwards = 2;
      timeOnwards = Number(timeOnwards)
      show_select_date_one = new Date(serDate1)
      show_select_date_one = show_select_date_one.setDate(new Date(serDate1).getDate() + 1)
      show_select_date_one = new Date(show_select_date_one)
      show_select_date_two = new Date(serDate1)
      show_select_date_two = show_select_date_two.setDate(new Date(serDate1).getDate() + 2)
      show_select_date_two = new Date(show_select_date_two)
      delivery_date = new Date(serDate1)
      delivery_date = delivery_date.setDate(new Date(serDate1).getDate() + 1)
      delivery_date = new Date(delivery_date)
      show_delivery_time = timeOnwards + ':00 hours'
      afterBefore = 'After'
      setSlotTwo(delivery_date.toString().split(" ")[2] + ' ' + delivery_date.toString().split(" ")[1] + ' ' + delivery_date.toString().split(" ")[3] + ' ' + afterBefore + ' ' + show_delivery_time)

    } else {
      timeOnwards = Number(timenumber)
      timeOnwards = timeOnwards + 2
      timeOnwards = Number(timeOnwards)
      delivery_date = new Date(serDate1)
      afterBefore = 'After'
      show_delivery_time = timeOnwards + ':00 hours'
      setSlotTwo(delivery_date.toString().split(" ")[2] + ' ' + delivery_date.toString().split(" ")[1] + ' ' + delivery_date.toString().split(" ")[3] + ' ' + afterBefore + ' ' + show_delivery_time)
    }
  }

  const selectTimeSlotTwo = (value) => {
    let delivery_date_Two = ' ';
    let times = value.slot_end_time;
    let timenumber = times.slice(0, 2);
    let timeOnwards;
    let serDate = document.getElementById('datePicker2').value
    let selected_date_for_date_picker = serDate;
    let show_delivery_time_two
    let afterBeforeTwo;
    if (value.id_slots === 5) {
      timeOnwards = 15;
      timeOnwards = Number(timeOnwards);
      delivery_date_Two = new Date(selected_date_for_date_picker);
      delivery_date_Two = delivery_date_Two.setDate(new Date(selected_date_for_date_picker).getDate() + 1);
      delivery_date_Two = new Date(delivery_date_Two);
      show_delivery_time_two = 14 + ':00 hours';
      afterBeforeTwo = 'Before ';
      setSlotTwo(delivery_date_Two.toString().split(" ")[2] + ' ' + delivery_date_Two.toString().split(" ")[1] + ' ' + delivery_date_Two.toString().split(" ")[3] + ' ' + afterBeforeTwo + show_delivery_time_two + ' ')
    } else {
      timeOnwards = Number(timenumber);
      timeOnwards = 15;
      timeOnwards = Number(timeOnwards);
      delivery_date_Two = new Date(selected_date_for_date_picker);
      delivery_date_Two = new Date(delivery_date_Two).setDate(new Date(selected_date_for_date_picker).getDate());
      delivery_date_Two = new Date(delivery_date_Two);
      show_delivery_time_two = 23 + ':55 hours';
      afterBeforeTwo = 'Before ';
      setSlotTwo(delivery_date_Two.toString().split(" ")[2] + ' ' + delivery_date_Two.toString().split(" ")[1] + ' ' + delivery_date_Two.toString().split(" ")[3] + ' ' + afterBeforeTwo + show_delivery_time_two + ' ')
    }


  }

  const setAddressLine2 = (value) => {
    setSelectedAddress({
      addressLine2: value
    });
  }

  const setSelectedPickupHandler = (e) => {
    setValue('airport1', '')
    setRoundFullAddress('')
    setRoundPickDropDetails('')
    setRoundPickUp(e)
    setValue('roundPickUp', e);

    if (activeTab === 'arrival') {
      if (new Date(minDate).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
        setValue('serDate1', new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + ((new Date().getHours() + 3 > 23 || e === 'Doorstep Pickup') ? 1 : 0), 10, 33, 30, 0).toISOString().split("T")[0])
        setMinDate1(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + ((new Date().getHours() + 3 > 23 || e === 'Doorstep Pickup') ? 1 : 0), 10, 33, 30, 0).toISOString().split("T")[0])
      }
    } else {
      if (new Date(minDate).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
        setValue('serDate1', new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + ((new Date().getHours() + 3 > 23 || e === 'Doorstep Pickup') ? 1 : 0), 10, 33, 30, 0).toISOString().split("T")[0])
        setMinDate1(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + ((new Date().getHours() + 3 > 23 || e === 'Doorstep Pickup') ? 1 : 0), 10, 33, 30, 0).toISOString().split("T")[0])
      }
    }

  }

  const setSelectedAirportHandler = (pincode) => {

    let airport_detail = airPortOption.find((res) => { return pincode == res.airport_name_id })
    setMainPinCode1(_.find(cityList, ['region_id', airport_detail.fk_tbl_city_of_operation_region_id]).pin_code)

    let cityId = airport_detail.fk_tbl_city_of_operation_region_id
    setValue('city1', cityId);
    // pinCodeValidationAndPricing(airport_detail.airport_pincode, 'main')
    if (roundPickUp == 'Airport: Drop off Point' || roundPickUp == 'Airport: Pickup Point') {
      document.getElementById('staticModalHandler').click();
      axios.request({
        method: CarterX_API.PICKUP_DROP_ADDRESS.method,
        url: CarterX_API.PICKUP_DROP_ADDRESS.url,
        data: {
          "pickup_type": 1,
          "departure_type": activeTab != 'departure' ? 1 : 2,
          "airport_id": Number(getValues("airport1")),
          "delivery_type": 2
        }
      }).then((res) => {
        if (res.data.status && res.data.subscriber_detail.length != 0) {
          setRoundFullAddress(res.data.subscriber_detail[0].pick_drop_address)
          setRoundPickDropDetails(...res.data.subscriber_detail)
        } else {
          setRoundPickDropDetails([]);
        }
        document.getElementById('staticModalHandler').click();
      })
        .catch((error) => {
          console.log(error);
        })
    }

  }

  // function increaseDepartureHour() {
  //   if (roundHours < 23) {
  //     setRoundHours(roundHours + 1)
  //   }

  // }

  // function decreaseDepartureHour() {
  //   if (roundHours > 0) {
  //     setRoundHours(roundHours - 1)
  //   }
  // }

  // function increaseDepartureMin() {
  //   if (roundMinutes < 59) {
  //     setRoundMinutes(roundMinutes + 1)
  //   }

  // }

  // function decreaseDepartureMin() {
  //   if (roundMinutes > 0) {
  //     setRoundMinutes(roundMinutes - 1)
  //   }
  // }

  function openDepartureTimePicker() {
    setTimePickerShow(!timePickerShow)
  }

  function increaseDepartureHour() {
    let dDate = new Date();
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    let tomorrow = new Date(currentDate);
    tomorrow.setDate(tomorrow.getDate() + 1);
    let hour
    if (activeTab != "departure" && roundPickUp === 'Airport: Drop off Point') {
      if (tomorrow.setHours(0, 0, 0, 0) === new Date(getValues('serDate1')).setHours(0, 0, 0, 0)) {
        hour = dDate.getHours() + 0
      }
      // hour = dDate.getHours() + 3
    } else if (activeTab === "departure") {
      if (tomorrow.setHours(0, 0, 0, 0) === new Date(getValues('serDate1')).setHours(0, 0, 0, 0)) {
        hour = dDate.getHours() + 0
      } else {
        hour = 0;
      }
    } else {
      hour = 0;
    }

    if (hour > 23) {
      hour = Number(hour) - 24;
    }

    if (roundHours >= 23) {
      setRoundHours(hour ? hour : 0)
      setMin(hour, hour)
    } else {
      setRoundHours(Number(roundHours) + 1)
      setMin(Number(roundHours) + 1, hour)
    }
  }

  function decreaseDepartureHour() {

    let dDate = new Date();
    let currentDate = new Date();
         currentDate.setHours(0, 0, 0, 0);
      let tomorrow = new Date(currentDate);
          tomorrow.setDate(tomorrow.getDate() + 1);
    let hour
    if (activeTab != "departure" && roundPickUp === 'Airport: Drop off Point') {
      if (tomorrow.setHours(0, 0, 0, 0) === new Date(getValues('serDate1')).setHours(0, 0, 0, 0)) {
        hour = dDate.getHours() + 0
      }
    } else if (activeTab === "departure") {
      if (tomorrow.setHours(0, 0, 0, 0) === new Date(getValues('serDate1')).setHours(0, 0, 0, 0)) {
        hour = dDate.getHours() + 0
      } else {
        hour = 0;
      }
    } else {
      hour = 0;
    }

    if (hour > 23) {
      hour = Number(hour) - 24;
    }

    if (roundHours === hour || hour === 0) {
      setRoundHours(23)
      setMin(23, hour)
    } else {
      setRoundHours(Number(roundHours) - 1)
      setMin(Number(roundHours) - 1, hour)
    }
  }

  function setMin(hours, hour) {
    if (hours === hour) {
      setRoundMinutes(new Date().getMinutes());
    } else {
      setRoundMinutes(0);
    }
  }

  function increaseDepartureMin() {
    let dDate = new Date();
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    let tomorrow = new Date(currentDate);
    tomorrow.setDate(tomorrow.getDate() + 1);
    let min, hour

    hour = new Date().getHours() + (activeTab != "departure" && roundPickUp === 'Airport: Drop off Point' ? 0 : 0);
    if (hour > 23) {
      hour = Number(hour) - 24
    }

    if (activeTab != "departure" && roundPickUp === 'Airport: Drop off Point') {
      if (tomorrow.setHours(0, 0, 0, 0) === new Date(getValues('serDate1')).setHours(0, 0, 0, 0) && roundHours === hour) {
        min = dDate.getMinutes()
      }
    } else if (activeTab === "departure") {
      if (tomorrow.setHours(0, 0, 0, 0) === new Date(getValues('serDate1')).setHours(0, 0, 0, 0) && roundHours === hour) {
        min = dDate.getMinutes()
      } else {
        min = 0;
      }
    } else {
      min = 0;
    }

    if (roundMinutes >= 59) {
      setRoundMinutes(min ? min : 0);
    } else {
      setRoundMinutes(roundMinutes + 1);
    }

  }

  function decreaseDepartureMin() {
    let dDate = new Date();
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    let tomorrow = new Date(currentDate);
    tomorrow.setDate(tomorrow.getDate() + 1);
    let min, hour

    hour = new Date().getHours() + (activeTab != "departure" && roundPickUp === 'Airport: Drop off Point' ? 0 : 0);
    if (hour > 23) {
      hour = Number(hour) - 24
    }

    if (activeTab != "departure" && roundPickUp === 'Airport: Drop off Point') {
      if (tomorrow.setHours(0, 0, 0, 0) === new Date(getValues('serDate1')).setHours(0, 0, 0, 0) && roundHours === hour) {
        min = dDate.getMinutes()
      }
    } else if (activeTab === "departure") {
      if (tomorrow.setHours(0, 0, 0, 0) === new Date(getValues('serDate1')).setHours(0, 0, 0, 0) && roundHours === hour) {
        min = dDate.getMinutes()
      } else {
        min = 0;
      }
    } else {
      min = 0;
    }

    if (roundMinutes == min || roundMinutes == 0) {
      setRoundMinutes(59);
    } else {
      setRoundMinutes(roundMinutes - 1);
    }
  }



  return (
    <div className="mt-3">
      <div className="row gap">

        <div className="col-md-4">
          <select id='round_pickUp' className="form-control mb-0" name="roundPickUp" ref={register({ required: true })} onChange={(e) => setSelectedPickupHandler(e.target.value)}>
            <option value="" disabled selected>Select Pickup*</option>
            {
              (activeTab != 'departure' ? ['Airport: Drop off Point', 'Doorstep Pickup'] :
                ['Airport: Pickup Point', 'Doorstep Delivery']).map(data => (
                  <option value={data}>{data}</option>
                ))
            }
          </select>
        </div>

        <div className="col-md-4 d-none">
          <select id='roundTripCity' className={`form-control mb-0 ${errors.city1 && 'cusErrors'}`} name="city1" ref={register({ required: true })} onChange={(e) => setSelectedCityHandler(e.target.value)}>
            <option value="" disabled selected>City*</option>
            {
              cityList && cityList.length > 0 &&
              cityList.map(data => (
                <option value={data.region_id}>{data.region_name}</option>
              ))
            }
          </select>
          <span className="cusError">{errors.city1 && 'Please select city'}</span>
        </div>

        <div className="col-md-4">
          <select className={`form-control mb-0 ${errors.airport1 && 'cusErrors'}`} name="airport1" ref={register({ required: true })} onChange={(e) => setSelectedAirportHandler(e.target.value)}>
            <option value="" disabled selected>Airport*</option>
            {
              airPortOption && airPortOption.length > 0 &&
              airPortOption.map(data => (
                <option className={getValues('city') == data.fk_tbl_city_of_operation_region_id ? 'd-none' : null} value={data.airport_name_id}>{data.airport_name}</option>
              ))
            }
          </select>
          <span className="cusError">{errors.airport1 && 'Please select airport'}</span>
        </div>

        <div className="col-md-4">
          <div>
            <input
              id='datePicker2'
              type="date"
              min={minDate1}
              placeholder="Select Service Date*"
              className={`form-control txt-left mb-0 ${errors.serDate1 && 'cusErrors'}`}
              name="serDate1"
              ref={register({ required: true })}
              onChange={(e) => dateValidationHandle(e.target.value)}
            />
            <span className="cusError">{cusDateError}</span>
          </div>
        </div>

        <div className="col-md-4">
          <div className="w-100">
            <input
              type="text"
              placeholder="PNR*"
              maxLength="6"
              className={`form-control mb-0 text-uppercase ${errors.pnr1 && 'cusErrors'}`}
              name="pnr1"
              ref={register({ required: true, pattern: /^[a-zA-Z0-9]{6,}$/i })}
            />
            <span className="cusError">{errors.pnr1 && 'Enter the valid PNR'}</span>
          </div>
        </div>

        {roundPickUp === 'Doorstep Pickup' || roundPickUp === 'Doorstep Delivery' ?
          <OneWayGoogleAddress activeTab={activeTab == 'departure' ? 'arrival' : 'departure'} errorAddressCity={errorRoundCity} addressLine={addressLine} selectedAddress={selectedAddress} submitAddress={submitAddress} handleAddress={handleAddress} setAddressLine2={setAddressLine2} type={2}></OneWayGoogleAddress>
          :
          <div className={roundPickDropDetails.length == 0 ? 'd-none ' : 'col-md-8 d-block'}>
            <div className="form-control">
              {roundPickDropDetails.length != 0 ? roundPickDropDetails.pick_drop_address : ''}
            </div>
          </div>
        }

        {
          roundPickUp === 'Doorstep Pickup' || roundPickUp === 'Doorstep Delivery' ?
            <div className="col-md-6">
              <select name="slots1" className={`form-control ${errors.slots1 && 'cusErrors'}`} id="pickUpSlot"
                ref={register({ required: true })}
                onChange={(e) => findSlotValue(e)}
              >
                <option value="" disabled selected>{activeTab === 'departure' ? 'Flight Arrival Slot*' : 'Pick up Slot*'}</option>
                {activeTab != 'departure' ?
                  slots?.map(data => (
                    data.slot_type == 0 && data.time_description != null && data.time_description != "" ?
                      <option value={data.time_description} >
                        {data.time_description}
                      </option>
                      :
                      null
                  ))
                  : slots?.map(data => (
                    data.slot_type != 0 && data.time_description != null && data.time_description != "" ?
                      <option value={data.time_description} >
                        {data.time_description}
                      </option>
                      :
                      null
                  ))
                }
              </select>
              <span className="cusError">{errors.slots1 ? activeTab != 'departure' ? 'Please Select Pick up Slot' : 'Please Select Flight Arrival Slot' : ''}</span>
            </div>
            :
            <div className="col-md-4">
              <div className="dropdown customDropdown">
                <div className='form-control dropdown-toggle' onClick={() => setTimePickerShow(true)}>
                  <div>
                    {roundHours < 10 ? <span>0</span> : null}{roundHours} : {roundMinutes < 10 ? <span>0</span> : null}{roundMinutes}
                  </div>
                </div>
                <div className={`dropdown-menu timeToMeet pt-4 ${timePickerShow ? "show" : ""}`}>
                  <div className="">
                    <div className='d-flex justify-content-around'>
                      <div className='text-center'>
                        <h5 className='timeHeading'>Hour</h5>
                        <div className='pb-3' onClick={() => increaseDepartureHour()}>
                          <i className="fa-sharp fa-solid fa-chevron-up"></i>
                        </div>
                        <div className='pb-3'>
                          {roundHours < 10 ? <span>0</span> : null}{roundHours}
                        </div>
                        <div onClick={() => decreaseDepartureHour()}>
                          <i className="fa-sharp fa-solid fa-chevron-down"></i>
                        </div>
                      </div>
                      <div className='text-center'>
                        <h5 className='timeHeading'>Min</h5>
                        <div className='pb-3' onClick={() => increaseDepartureMin()}>
                          <i className="fa-sharp fa-solid fa-chevron-up"></i>
                        </div>
                        <div className='pb-3'>
                          {roundMinutes < 10 ? <span>0</span> : null}{roundMinutes}
                        </div>
                        <div onClick={() => decreaseDepartureMin()}>
                          <i className="fa-sharp fa-solid fa-chevron-down"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='text-center mb-2 mt-4'>
                    <span className='confirm cursorPointer' onClick={() => openDepartureTimePicker()}>CONFIRM</span>
                  </div>
                </div>

              </div>
              <div class="message">
                <span class="mIcon">i</span>
                <p>
                  Maximum wait time for Porter allocated is 15 minutes (+/-) after
                  time entered.
                </p>
              </div>
            </div>

        }



      </div>
    </div>
  )
}