import React from 'react';

export default function TermsAndConditions() {
  return (
    <div className="container-fluid">
      <div className="row">
        <section className="terms-and-condtions col-12">
          <div className="tnc-body">
            <div className="col-12">
              <div className="body-header">
                <h4 className="body-heading bold mb-5 text-center">Terms and Conditions/General Terms & Conditions</h4>
                <p className="text-left">Terms and Conditions for Acceptable Use of Website and Services : <a href="https://www.6EBagPort.carterporter.in">https://www.6EBagPort.carterporter.in</a></p>
                <p className="text-left">This is an electronic contract in terms of Information Technology Act, 2000 and rules there under, as applicable. This electronic contract is generated by a computer system and does not require any physical or digital signatures. </p>
                <p className="text-left">The Terms and Conditions for Acceptable Use of Website/ Mobile App and Services (Terms and Conditions) sets out, inter alia, a list of acceptable and unacceptable conduct for use of the Website/Mobile App and our services (hereinafter Service). These Terms and Conditions create a binding legal agreement between a person using or accessing this Website or availing the Service through the Website and Cater Porter.</p>
                <p className="text-left">If we believe a violation of these Terms and Conditions is deliberate, repeated or presents a credible risk of harm to other users, or the Services or any third parties, we may suspend or terminate your access. </p>
                <p className="text-left">Please take a few minutes to read these terms carefully before you use Services. If the booking was made on your behalf by a third party, your continued use of our Services means you agree to these Terms and Conditions.</p>
                <p className="text-left">If you do not accept these Terms and Conditions, please do not use our Services and, if you have a booking that has been made on your behalf, please exercise your right to cancel your booking failing which these Terms and Conditions are duly applicable.</p>
              </div>
            </div>
            <div className="content col-12">
              <ol className="main-class pr-3">
                <li className="bold section-heading">
                  <p className="bold section-heading">Definitions:</p>
                </li>
                <p>“Airport Transfer” means the service to/from the city delivery address to the airport of service. Gate/Kiosk/Counter prescribed of the airport available in the departures/arrivals of every airport of service. Address can be classified to local or outstation. </p>
                <p>“Airport Delivery Address” means the Gate/Kiosk/Counter prescribed of the airport available in the departures/arrivals of every airport of service.</p>
                <p>“City Delivery Address” means the address prescribed available in/from the city of service.</p>
                <p>"Booking and/or Order and/or Package" means a Customer’s request for Services through our website/ Mobile App as evidenced by our records.</p>
                <p>“Website/App” means and includes domains, mobile applications (downloadable from Android, windows and Apple devices) owned by CARTERPORTER PRIVATE LIMITED.</p>
                <p>“CarterPorter/CarterX/Porter/PorterX” means, CARTERPORTER PRIVATE LIMITED, Company registered under Companies Act, 2013, its branches, their respective employees and agents and independent contractors.</p>
                <p>"Charges" means the fees, charges and amounts assessed or levied for movement of any Package from and to Airport/city in accordance with these conditions or any conditions or fees subsequently imposed as per the rate card provided in the Website/ Mobile App as per the type and weigh of Package.</p>
                <p>"Conditions" shall mean these Conditions of Carriage which term shall also include those agreements, laws and conventions expressly referred to herein and as updated by us from time to time.</p>
                <p>"Consignment Number and /or Order Number" means the number issued by us to a customer ensuring account activity is summarized by the CarterPorter tracking system to find out the status of the Package of the customer who has signed for it.</p>
                <p>"Customer" and/or “User” and/or “You” means the person who makes a Booking (whether directly or indirectly for other person/alliance partners) through our Website/ Mobile App.</p>
                <p>“Delivery Address” means the Airport/city Delivery Address and/or the Domestic/Outstation Delivery Address;</p>
                <p>“Domestic/Outstation Delivery Address” means the delivery address in India in particular in the place of service notified to us and validated by us as willing to accept delivery of the Package on terms acceptable to us;</p>
                <p>“Distress days” means such days which create distress to general public including the user and or us.</p>
                <p>“Order Modification” means or includes or takes place during the following situation:</p>
                <ul>
                  <li className="li-style">
                    <p>The customer's Package is not classified correctly;</p>
                  </li>
                  <li className="li-style">
                    <p>The address of delivery is beyond 3kms from pin code specified at the time of booking</p>
                  </li>
                  <li className="li-style">
                    <p>Weight of the Package measured by our personnel at the time of pick up does not match with the online order placed by the customer;</p>
                  </li>
                  <li className="li-style">
                    <p>If there is delay from the customer to reach the location at the time of pick up or drop of the Package.</p>
                  </li>
                  <li className="li-style">
                    <p>There is an addition or subtraction of luggage count to the order placed by customer.</p>
                  </li>
                  <li className="li-style">
                    <p>Insurance Payable towards increased luggage count in the order by customer. Applicable only if customer has opted to buy additional insurance in the order placed.</p>
                  </li>
                  <li className="li-style">
                    <p>There is variation in rate of order due to current ‘offer/coupon applied’ to the order because of order modification.</p>
                  </li>
                </ul>
                <p>"Package" means any parcel/baggage/luggage/goods or piece which is owned or booked by customer transported by us pursuant to these terms and is accepted and does not include any explosives/harmful goods or such other goods which are prohibited by the International Air Transport Association (IATA) Rules that are perishable and dangerous/prohibited to carry as detailed in the following link modified from time to time: <a className="bold"
                  href="http://www.iata.org/publications/dgr/Pages/index.aspx"
                  target="_blank" rel="noopener noreferrer">http://www.iata.org/publications/dgr/Pages/index.aspx</a></p>
                <p>“Prohibited Items” means any items any items which are of a potentially dangerous in nature, items the possession of which is illegal in the India, livestock, foodstuffs and perishable items or such other goods which are prohibited by the International Air Transport Association (IATA) Rules that are perishable and dangerous/prohibited to carry as detailed in the following link modified from time to time: <a className="bold"
                  href="http://www.iata.org/publications/dgr/Pages/index.aspx"
                  target="_blank" rel="noopener noreferrer">http://www.iata.org/publications/dgr/Pages/index.aspx</a></p>
                <p>“Reschedule Delivery” means a request by the user to deliver the Package to a different location or time after ‘picked up’ by us with a reasonable notice period. Company can also make the request on behalf of the customer if there is no response from customer after pick up at the time of delivery. Evidence of contact and communication sent at the time of delivery will be posted by company to customer on app/web interface.</p>
                <p>“Scheduled Customer Drop-off Time” means the scheduled time for the drop-off of the Package by the Customer to us as set out in the Booking; This can be a slot based timeline.</p>
                <p>
                  “Scheduled Delivery Time” means the scheduled time for delivery of the Package to either
                  the Domestic/Outstation Delivery Address or the Airport/City Delivery Address as set out in the Booking;
                  This
                  can be a slot based timeline.
                </p>
                <p>
                  “Sealed Package” means a Package which has been approved as locked by the Customer and sealed using
                  a Tamper
                  evident bag by our representative and registered by us or one of our agents at the time of collection from
                  the
                  Customer in accordance with our
                  security procedures from time to time;
                </p>
                <p>
                  <b>“Corporate houses and other strategic alliance partners”</b> are corporate house/companies or airlines
                  that
                  have entered into contracts with Carterporter Pvt ltd to make bookings. These partners will make bookings
                  for
                  customers/passengers/employees
                  and those bookings made will be bound by the contracts signed mutually between them. The rates, booking
                  features, modification and cancellation are based on the mutually agreed terms. All other terms of
                  carriage
                  are as per the same
                  general terms and conditions listed by Carterporter Private limited. The terms and conditions can change
                  from
                  time to time on updates without prior intimation.
                </p>
                <p>
                  For Bookings made by corporate houses and other strategic alliance partners the booking customer will be
                  auto
                  registered with the primary mobile number given during booking to enable tracking of the order. If more
                  than
                  1
                  mobile numbers are given during
                  the booking, the 1st number will be taken as primary number.
                </p>
                <p>“IndiGo” -means the brand name under which flights are operated by the airline i.e. InterGlobe Aviation Limited.</p>
                <p>“We”, “our” and “us” means CARTER PORTER PRIVATE LIMITED.</p>


                <li className="bold section-heading">
                  <p className="bold section-heading">Use of Website/App:</p>
                </li>
                <p>
                  Website/App is provided solely for the use of current and potential customers to interact with us and may
                  not
                  be used by any other person or entity, or for any other purpose. Specifically, all tracking, rating,
                  receiving
                  invoices and remitting payment
                  using electronic funds transfer, drop-off location, identifying and preparing packages, estimating duties
                  and
                  taxes, and other information and services may only be used by current and potential Website/App customers
                  for
                  their own orders.
                  Unless indicated otherwise in the terms of use of a specific interactive feature on our Website/App, the
                  use
                  of Website/App to provide information to or prepare orders by or for the benefit of third party
                  competitors
                  is
                  expressly prohibited.
                  The use of automated dial-in or inquiry devices to obtain information through any of our communication
                  support
                  units is strictly prohibited.
                </p>
                <p>
                  CarterPorter or its entities does not accept ideas, concepts, or techniques for new services or products
                  through its Website/App. If such information is received, it will not be considered confidential and we
                  will
                  be deemed free to use, communicate and
                  exploit such information in any manner it chooses. <br></br>
                  Customer acknowledges that Services are provided by Carter to the Customers, and IndiGo will not have any obligation or liability whatsoever in respect of the Services. IndiGo does not make any warranties or representations about the quality, merchantability, suitability or availability of the Services offered by Carter and does not control or guarantee the quality or fitness of any such Services. Any dispute in relation to delivery, suitability, merchantability, availability, fitness or quality of the Services availed shall be handled/dealt directly with Carter.
                </p>

                <li className="bold section-heading">
                  <p className="bold section-heading">Becoming a registered user:</p>
                </li>
                <ul type="disc">
                  <li>
                    <p>To avail our services, one should be a registered user.</p>
                  </li>
                  <li>
                    <p>If you choose to become a registered user you will have to create your user id by providing all the
                      necessary information and valid proofs. This does not apply for auto-registered users.</p>
                    <p>
                      Id proof is not mandatory for registration of user, it can be uploaded later or uploaded now. An order
                      can
                      be picked up/delivered only after inspecting the valid id proof.
                    </p>
                    <p>
                      The Id proof, if uploaded should be a clear image/file. Email notification will be sent to registered
                      email address if the user details are not of a match to the details provided in registration form.
                      Make
                      sure the Id proof is clear on the fields of name,
                      date of birth and address. The user address entered during registration does not have to match the
                      address
                      in Id proof.
                    </p>

                  </li>
                  <li>
                    <p>One time password (OTP) will be sent to registered mobile number as password to login. You must treat
                      such password as confidential, and you must not disclose it to any other person or entity. You also
                      acknowledge that your account
                      is personal to you and agree not to provide any other person with access to this Website or portions
                      of
                      it
                      using your password or other security information. You agree to notify us immediately of any
                      unauthorized
                      access to
                      or use of your password or any other breach of security.</p>
                  </li>
                  <li>
                    <p>We have the right to disable any user name and password or other identifier, whether chosen by you or
                      provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion,
                      you
                      have breached any
                      provision of these Terms.
                    </p>
                  </li>
                  <li>
                    <p>For Bookings made by corporate houses and other strategic alliance partners the booking customer will
                      be
                      auto registered with the primary mobile number given during booking to enable tracking of the order.
                      If
                      more than 1 mobile
                      numbers are given during the booking, the 1st number will be taken as primary number.
                    </p>
                  </li>
                  <li>
                    <p>
                      In such event, previously registered users will not be required to re-register.
                    </p>
                  </li>
                  <li>
                    <p>
                      Each email address and contact mobile phone number can only be associated with one carterporter.in account.
                    </p>
                  </li>
                  <li>
                    <p>
                      Auto registrations are also available for booking made directly with carterporter via phone or
                      kiosk/counter at the airport</p>
                  </li>
                  <li>
                    <p>
                      There is no fee for registration.
                    </p>
                  </li>
                  <li>
                    <p>
                      By registering on Website/App, you agree to provide accurate and current information about yourself as
                      prompted by the Website/App Login Registration pages and maintain and promptly update your online
                      profile
                      information to keep it accurate and current.
                    </p>
                  </li>
                  <li>
                    <p>
                      Our services are being made available to customers from all countries coming to the airport/city of
                      service. You agree to register only for our services offered on the Website/App for the city/airport
                      in
                      which your service order is based. Service is restricted
                      to select airports and city of service. Service is also restricted to select pincodes within the city
                      of
                      city of service as local and outstation.
                    </p>
                  </li>
                  <li>
                    <p>
                      When you register using Website/App Login Registration, your mobile number will be the user ID. The
                      password will be sent via OTP on every login for extended security purpose. You are responsible for
                      maintaining the confidentiality of the password and
                      user ID, and you are responsible for all activities that occur under your account and user ID. You
                      agree
                      to (a) immediately notify us of any unauthorized use of your user ID, and (b) ensure that you exit
                      from
                      your website session
                      at the end of each visit.
                    </p>
                  </li>
                </ul>
                <li className="bold section-heading">
                  <p className="bold section-heading"> Changes to CARTERPORTER PRIVATE LIMITED</p>
                </li>
                <ul type="disc">
                  <li>
                    <p>Our Website/App and its Content, may be changed, deleted or updated at any time without notice.</p>
                  </li>
                </ul>
                <li className="bold section-heading">
                  <p className="bold section-heading">Termination of Use</p>
                </li>
                <ul type="disc">
                  <li>
                    <p>We may discontinue, suspend or modify the Website/App at any time without notice, and we may block,
                      terminate or suspend your and any user's access to Website/App at any time for any reason in its sole
                      discretion, even if access
                      continues to be allowed to others.</p>
                  </li>

                </ul>
                <li className="bold section-heading">
                  <p className="bold section-heading"> Ownership</p>
                </li>
                <ul type="disc">
                  <li>
                    <p> The Website/App and their Content absolutely and completely belongs to CARTERPORTER PRIVATE LIMITED.
                      And
                      therefore, CARTERPORTER PRIVATE LIMITED reserves absolute and complete rights on the Website/App and
                      their
                      Content.</p>
                  </li>
                  <li>
                    <p>The Website/App does not convey to anyone, through allowing access to our Website/App, any ownership
                      rights in our Website/App or in any Content appearing on or made available through Website/App.
                      Customer
                      may not copy, modify,
                      translate, transmit, distribute, adapt, reproduce, decompile, reverse engineer or disassemble any part
                      of
                      Website/App or its Content.</p>
                  </li>
                  <li>
                    <p> All text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds,
                      music,
                      artwork and computer code (collectively, "Content"), including but not limited to the design,
                      structure,
                      selection, coordination,
                      expression, "look and feel" and arrangement of such Content, contained on the website, Platform and
                      Application is owned, controlled or licensed by or to Us, and is protected by trade dress, copyright,
                      patent and trademark
                      laws, and various other intellectual property rights and unfair competition laws, and may not be
                      copied,
                      imitated or used, in whole or in part, without the prior written permission of CARTERPORTER PRIVATE
                      LIMITED.</p>
                  </li>
                  <li>
                    <p>Except as expressly provided in these T&Cs, no part of the Website/ Application and no Content may be
                      copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated,
                      transmitted
                      or
                      distributed in any
                      way (including "mirroring") to any other computer, server, website or other medium for publication or
                      distribution or for any commercial enterprise, without Our express prior written consent.</p>
                  </li>
                  <li>
                    <p>You may use information on Our Services that has been made available by Us for downloading from the
                      Platform/Application, provided you:</p>
                  </li>
                  <ul>
                    <li>
                      <p>do not remove any proprietary notice language in all copies of such documents/ information; or</p>
                    </li>
                    <li>
                      <p>use such information only for your personal, non-commercial informational purpose and do not copy
                        or
                        post such information on any networked computer or broadcast it in any media; or</p>
                    </li>
                    <li>
                      <p>make no modifications to any such information; or</p>
                    </li>
                    <li>
                      <p>do not make any additional representations or warranties relating to such documents/ information.
                      </p>
                    </li>

                  </ul>
                  <li>
                    <p> All trademarks, brands and service marks used in connection with the Platform, Application or
                      Services
                      offered are owned by CarterPorter and is CarterPorter’s property. CarterPorter owns all copyrights and
                      intellectual property
                      rights and database rights in connection with the Services, website, Platform and the Application. For
                      use
                      of any third party's intellectual property, you need to get permission directly from the owner of the
                      intellectual property
                      for any use. Third party trademarks may appear on this Application and all rights therein are reserved
                      to
                      the registered owners of those trademarks.</p>
                  </li>
                  <li>
                    <p> You agree to abide by laws pertaining to copyright, trademark, patent, and trade secret ownership
                      and
                      use of intellectual property, and you shall be solely responsible for any violations of any Applicable
                      Laws and for any infringements
                      of any intellectual property rights caused by use of the Platform/Application.</p>
                  </li>
                  <li>
                    <p>You shall be responsible for any notes, messages, e-mails, photos, drawings, profiles, opinions,
                      ideas,
                      images, videos, audio files or other materials or information posted or transmitted by you on the
                      Platform/Application (“Posted
                      Content”). Such Posted Content will become our property and you grant us the royalty-free,
                      sub-licensable,
                      transferable, perpetual, irrevocable, non-exclusive, worldwide license to use, reproduce, modify,
                      publish,
                      list information
                      regarding, edit, translate, distribute, syndicate, publicly perform, publicly display, and make
                      derivative
                      works of all such Posted Content, in whole or in part, and in any form, media or technology, whether
                      now
                      known or hereafter
                      developed, for use in connection with the Platform, Application and CarterPorter’s (and its
                      successors’
                      and affiliates’) business. We shall be entitled to use the Posted Content or any of its elements for
                      any
                      type of use forever,
                      including but not limited to promotional and advertising purposes and in any media, whether now known
                      or
                      hereafter devised, including the creation of derivative works that may include Posted Content. You
                      agree
                      that any Posted
                      Content may be used by us in the manner that we deem fit, consistent with Applicable Laws and you are
                      not
                      entitled to any payment or other compensation for such use of Posted Content by us. CarterPorter takes
                      no
                      responsibility
                      and assumes no liability for the Posted Content that you or any other user or third party creates,
                      stores,
                      shares, posts or sends through the Application/Platform. You shall be solely responsible for your
                      Posted
                      Content and
                      the consequences of posting, publishing it, or sharing it and you agree that we are only acting as a
                      passive conduit for your online distribution and publication of your Posted Content. Notwithstanding
                      anything contained in
                      these T&Cs, if your Posted Content violates these T&Cs, You shall bear all legal responsibility and
                      obligation for that content.</p>
                  </li>

                </ul>
                <li className="bold section-heading">
                  <p className="bold section-heading">Booking, Pick up and Delivery:</p>
                </li>
                <ul type="disc">
                  <li>
                    <p>Booking only becomes effective and binding on your acceptance of these Terms and dispatch of an
                      e-mail/Sms confirmation of the Booking to the Customer’s notified email address or customer’s
                      registered
                      mobile number. English will
                      be the only medium of communication used for all Emails/SMS.</p>
                  </li>
                  <li>
                    <p>We reserve the right to decline to accept any Booking but if we do so we will send you an email
                      notice
                      to
                      that effect.</p>
                  </li>
                  <li>
                    <p>We do not intend to cancel your order once we have accepted your Booking. However, from time to time
                      it
                      may be necessary and we reserve the right to do so.</p>
                  </li>
                  <li>
                    <p>For corporate or alliance partner related cancellations the corporate or alliance partners need to be
                      contacted for further information.</p>
                  </li>
                  <li>
                    <p>The pickup will be completed only after the Package is inspected by our company personnel after
                      uploading
                      the pictures (taken of the same before and after it is packed in tamper evident bag) of Package along
                      with
                      the signature
                      of the location contact customer in the respective account of the customer which will be available in
                      our
                      website/mobile app. Binding security documents needs to be signed if the package is bound to the
                      airport.
                    </p>
                  </li>
                  <li>
                    <p> We do not accept Package beyond dimension on our 'luggage scale and type guide' available in the
                      website/mobile app. The maximum available units are clearly indicated in the process pages of making
                      the
                      booking.</p>
                  </li>
                  <li>
                    <p>	We do not accept more than 02 Packages per Order. Multiple orders can be made with 2 packages each from/to the same location/airport.
                    </p>
                  </li>
                  <li>
                    <p>Slot time, location type and domestic address cannot be changed once order is confirmed.</p>
                  </li>
                </ul>
                <p><b>For Orders to the Airport:</b></p>
                <p>
                  Package will be picked up in the slot time selected by the user at the time of booking confirmation by team. We will be at the location given within the slot time selected by the user.  Package will be available at the airport 120 minutes prior to gate close time.
                </p>
                <p>
                  Outstation Pick up for orders to the airport/city of service, package will be available from up to 3 days
                  onwards at airport delivery/city delivery location. We use surface transportation mostly and may take
                  additional days based on distance and connectivity.
                </p>
                <p>
                  Currently we offer <b>5 slots </b>bschedules for Local pick up:<br /> Local Pick Up Slot times:<br /> 7am – 11
                  am
                  | Package available after 2pm <br /> 11 am – 3 pm | Package available after 6pm <br /> 3 pm – 7 pm | Package
                  available after
                  10pm <br /> 7 pm – 12 am | Package available after 3am <br /> 4 am – 7 am | Package available after 10am <br />
                </p>
                <p>For Orders from the Airport/City of Service:</p>
                <p>
                  Package shall be delivered in the slot time selected by the user. The slot time is based on pickup time
                  from
                  the airport.
                </p>
                <p>Communication via SMS/voice calls will be made to ensure the tentative time of pickup is communicated in regular intervals. All SMS/text/emails messages will be sent only in English to the primary number/location contact/passenger contact number. Any communication on barring text/messages with independent service providers will impact communication of text/Sms from CarterX</p>
                <p>
                  Currently we offer 2 Local slot schedules for Drop off: These slots are only applicable for local pickup
                  and
                  deliveries.
                </p>
                <p>
                  Arrival from 12am - 3pm | Same day delivery between 5pm – 11.55pm <br /> Arrival from 3pm – 12 am|Next day
                  delivery between 7am– 2pm
                </p>
                <p>
                  Communication via SMS/voice calls will be made to ensure the tentative time of pickup is communicated in regular intervals. All SMS/text/emails messages will be sent only in English to the primary number/location contact/passenger contact number. Any communication on barring text/messages with independent service providers will impact communication of text/Sms from CarterX
                  <br></br>User shall have five minutes to complete the order to payment after selection of the slot. After five minutes the slot will be released for other users on the Website/Application.

                </p>

                <ul type="disc">

                  <li>
                    <p>Order Modification: </p>
                  </li>

                </ul>
                <p><b>Corporate houses and other strategic alliance partners: </b>Order Modification  may be extra or
                  included
                  in
                  corporate rate based on the agreement/contract between carterx and partners. Payment will not be collected
                  unless notified by
                  carterx and the receipt will be given if payment is collected as proof of payment. For bookings made
                  through
                  Home Baggage check in Service, the airline guidelines will apply for luggage and extra weight
                  modification.
                </p>
                <ul type="disc">

                  <li>
                    <p>Package categorized under the wrong category. The changed category cost will apply after subtracting
                      the
                      previously paid value. Fragile items need to be inspected before they are packed. Package count may
                      change
                      due to category
                      change.
                    </p>
                  </li>
                  <li>
                    <p>Package weight selection slab is incorrect. The weight of each Package shall be checked by us at the
                      time
                      of pickup. The changed slab value will apply after subtracting the previously paid value.</p>
                  </li>
                  <li>
                    <p>Time waited by us is beyond 15 minutes after reaching your doorstep for pickup or drop. Every order
                      shall
                      be locked to be picked up or dropped off within 15 minutes of waiting at your premises. We will move
                      on
                      to
                      the next location
                      after 15 minutes of waiting at the premises. An extra Rs. 100/- (plus tax) shall be charged for
                      additional
                      waiting time beyond the allocated 15 minutes. Maximum waiting time per order once reaching the
                      premises
                      to
                      include inspection,
                      photo uploads and pickup/drop off procedure is 25 minutes. The levy of waiting charge is subject to
                      management discretion and can may or may not apply during some period of offers or promotions.</p>
                  </li>

                  <li>
                    <p>
                      As per our cancellation policy cancellation when applicable, the amount to be refunded shall be
                      refunded
                      only to your source account within 7 working days. GST value cannot be refunded unless the order is
                      cancelled on or before the duration of full refund.
                    </p>
                  </li>
                  <li>
                    <p>
                      In any case, CarterPorter shall not hold any Package beyond 20 Hours from the time the Packages are
                      picked
                      up after which it shall be handed over to the relevant authorities or rescheduled with additional
                      charges
                      levied to the location of pickup.
                    </p>
                  </li>
                  <li>
                    <p>
                      Package shall be delivered at the place and time as per the Booking. Delays are warranted based on the
                      current conditions for surface transportation of package in city/airport of service or delivery.
                    </p>
                  </li>
                  <li>
                    <p>
                      At the time of delivery, you have to check every Package as per the order and report any missing
                      item(s)
                      immediately. If the order has been made for someone else, we shall verify and validate delivery with
                      the
                      id proof uploaded.Delivery can be declined
                      by CarterX if someone else is collecting the package without validation.
                    </p>
                  </li>
                  <li>
                    <p>
                      Reschedule delivery would be activated after the Package is picked up. The order can be rescheduled to
                      a
                      different location or time. The request of reschedule would amount to a new order. Accordingly, we can
                      hold/transit the Package for a maximum of 20
                      hours from the time of reschedule.
                    </p>
                  </li>
                  <li>
                    <p>
                      In case of delivery to airport, if the user does not honor Airport meet time and fails to reschedule
                      delivery, then the unclaimed Package shall be handed over to the relevant authorities beyond 6 hours
                      of
                      inability of the user to meet at Airport or re-schedule
                      delivery is not activated.
                    </p>
                  </li>
                  <li>
                    <p>
                      In case delivery to city location/outstation, if the user is not available and fails to reschedule
                      delivery, then the unclaimed Package shall be handed over to the relevant authorities beyond 6 hours
                      of
                      inability of the user to make contact to CarterX
                      or re-schedule delivery is not activated.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>For order towards the Airport:</b> The luggage will be removed from the airport premises immediately if time to meet of 120 minutes prior is not honored.  Re-schedule luggage is active when order is picked up and up to 1 hour prior to Airport meet time and we need a valid location to drop the Luggage. Unclaimed order if Airport meet time is not validated, order status will move to ‘undelivered’. The order then will be forced reschedule to the address of pickup automatically. The forced reschedule is done by management and amount will be collected before the time of delivery. If forced reschedule/reschedule delivery is unsuccessful then luggage will be handed over to the closest police station or relevant authorities beyond 6 hours of inability to meet the customer or make delivery.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>For order from the Airport/City Location and to another city location:</b>Unavailability of customer at delivery location may incur a second delivery attempt to the same address on customer’s confirmation or otherwise at an extra charge. Customer/passenger can Re-schedule luggage after it is activated up until the time it is dispatched for schedule slot of delivery. Reschedule is an extra charge and can be utilized to change address to an alternative location for delivery.  The second attempt delivery of Forced Reschedule and re-schedule delivery will be charged as a complete new order with recalculated order value. Forced reschedule is done by the management and payment will be collected before delivery. The location of Forced Reschedule can be changed to another alternative address by contacting management via the customer care or by writing to <a href="mailto:6EBagPort@carterporter.in">6EBagPort@carterporter.in</a> All unclaimed luggage post an unsuccessful forced reschedule will be handed over to the closest police station/relevant authorities beyond 6 hours of inability to make contact with the customer.
                    </p>
                  </li>
                  <li>
                    <p>
                      In case of delivery from airport/city location, if the customer is not available at the location, we
                      shall
                      make a second delivery attempt to the same address with or without customer’s confirmation. The
                      Customer
                      shall also have the option of reschedule
                      delivery. The second attempt delivery and re-schedule delivery shall be charged as a complete new
                      order
                      with recalculated primary order value (local/outstation as applicable). All unclaimed Package be
                      handed
                      over to the nearest
                      relevant authorities beyond 6 hours of inability of the customer to take delivery or re-schedule
                      delivery
                      is not activated.
                    </p>
                  </li>
                  <li>
                    <p>
                      Distress days are discounted for the purpose of handing over the unclaimed Package to the relevant
                      authorities as said above.
                    </p>
                  </li>
                  <li>
                    <p>
                      Packages will be handed over to any government agency/police/government jurisdiction authorities if
                      the
                      package is inspected on the route of delivery (local/outstation). All relevant papers with regards to
                      the
                      order/package will be notified to said authorities
                      and CarterX will provide complete cooperation. If the package is seized by said authorities the
                      customer
                      will be notified. CarterX will be released from all liability from contents and all other aspects of
                      the
                      package/order.
                    </p>
                  </li>
                  <li>
                    <p>
                      Customer agrees that our service made through the Website/App of any package tendered to us using
                      modes
                      of
                      service shall be in accordance with the terms, conditions and limitations of liability set out on the
                      NONNEGOTIABLE Order number, order details,
                      Label/barcodes, Manifest, or Pick-Up Record (collectively Order Documentation) and as appropriate any
                      order to transport/transfer order between Customer and us covering such service and in any applicable
                      tariff, service guidelines
                      or standard conditions of transport, copies of which are included in our terms and conditions, and
                      which
                      are incorporated in this document by reference. If there is a conflict between the terms given in the
                      Order Documentation
                      and terms on the Website/App then in effect the terms on the Website/App shall prevail.
                    </p>
                  </li>
                  <li>
                    <p>
                      Printed Signature: Customer acknowledges that if our employees or representatives are used to process
                      orders to locations airport or otherwise, Customer must enter, fill and upload all the name of the
                      person
                      completing the order. All documentation filled
                      and uploaded are in lieu of its manual signature on the order, as applicable, for all orders tendered
                      by
                      Customer to us using our Website/App. Customer further acknowledges that such digital name and
                      signature
                      shall be sufficient
                      to constitute the Customer's signature, and Customer's acceptance of our terms and conditions
                      contained
                      in
                      the applicable order.
                    </p>
                  </li>
                  <li>
                    <p>
                      Unless otherwise indicated, the customer’s address indicated as pick up or drop in our Order
                      Documentation
                      is the place of execution and the place of departure and, the receiver’s pick up or drop address
                      listed
                      on
                      the Order Documentation is the place
                      of destination.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Delayed Baggage</b><br /></p>
                    <p>
                      Delay in baggage may be due to reasons that are beyond operational or due to distress or human error
                      or
                      otherwise from CarterPorter private limited or other related entities. The status bar indicates the
                      position of the order and is a mere indication.
                      Minor delays or delays with regards to the above mentioned reasons should not be held against
                      CarterPorter
                      or its entities. For luggage or order which is not received, please contact customer care personnel
                      and
                      report the same
                      immediately. A Report will be filed and a copy will be given for follow up action and insurance will
                      be
                      initiated independently to insurance. Non availability of any information on the order or delay of
                      order
                      reporting or delay
                      of drop/delivery or pickup of order due to technical malfunction of the application or interface or
                      technology based hackware or virus, will be taken in as distress and CarterPorter or its
                      representatives
                      are not liable. All
                      reimbursements are subject to insurance claim submission and process.
                    </p>


                  </li>
                  <li>
                    <p><b>For International Air Travelers. </b><br /> On international
                      journeys,
                      if delivery of only entire Order baggage is delayed beyond 24 hours when a passenger is on a visit to
                      a
                      foreign country and
                      has no access to their own wardrobe, then one time interim expense of GBP 50.00 or equivalent in local
                      currency is reimbursed. In India, the amount is INR 3,000. All claims are to be made directly to the
                      insurance provider
                      and CarterPorter has provided all details about the insurance under ‘Manage Orders/My Trips’. All
                      claims
                      to be substantiated with itemized list of contents, description, age together with proof of purchase /
                      ownership of all
                      such items. The maximum compensation is listed under Domestic and International travelers mentioned
                      above
                      respectively. All reimbursements are subject to insurance claim terms, inspection, submission and
                      process.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b> For Domestic Local transfer (city/airport)</b><br /> On domestic flights, if only entire Order is
                      delivered the following day / subsequently, 50 % of the amount, towards purchase of casual /formal
                      clothes
                      like shirts, pants,
                      night suits etc. may be reimbursed, subject to a maximum of INR 2000/-. All claims are to be made
                      directly
                      to the insurance provider and CarterPorter has provided all details about the insurance under ‘Manage
                      Orders/My Trips’.
                      All claims to be substantiated with itemized list of contents, description, age together with proof of
                      purchase / ownership of all such items. The maximum compensation is listed under Domestic and
                      International travelers mentioned
                      above respectively. This compensation is not payable when the passenger is returning to his base
                      station
                      /
                      home city. All reimbursements are subject to insurance claim terms, inspection, submission and
                      process.
                    </p>
                  </li>
                  <li>
                    <p><b>
                      Lost Baggage</b><br /> If a delayed luggage/baggage could not be traced for 21 days from the date of
                      Report acknowledged, the same may be treated as lost. The compensation claim will be processed as per
                      laid
                      down procedures of
                      the insurance taken by carterporter for the order. All claims are to be made directly to the insurance
                      provider and CarterPorter has provided all details about the insurance under ‘Manage Orders/My Trips’.
                      All
                      claims to be
                      substantiated with itemized list of contents, description, age together with proof of purchase /
                      ownership
                      of all such items. The maximum compensation is listed under Domestic and International travelers
                      mentioned
                      above respectively.
                      Non availability of any information on the order or delay of order reporting or delay of drop/delivery
                      or
                      pickup of order due to technical malfunction of the application or interface or technology based
                      hackware
                      or virus, will
                      be taken in as distress and CarterPorter or its representatives are not liable. All reimbursements are
                      subject to insurance claim terms, inspection, submission and process.

                    </p>
                  </li>
                  <li>
                    <p>
                      <b> Damaged Baggage</b></p>
                    <p>
                      For baggage which is received in damaged or pilfered condition, please contact customer care
                      immediately
                      personnel and report the same to insurance on the details given under ‘Manage Orders/My Trips’
                      section.
                      CarterPorter has taken all required precaution
                      and does not know the contents of the luggage or order packed and hence our liability is zero towards
                      damages. Any claims made will enable insurance to examine the affected baggage and assess the nature,
                      extent and reparability
                      of the damage independently. Contact with insurance should be made directly with the numbers listed
                      under
                      insurance & follow the claim procedure within 48 hours. All conversations with the insurance provider
                      are
                      independent
                      of CarterPorter. No further involvement of CarterPorter or its representatives will be on behalf of
                      either
                      party for the claim made by the booking customer. All pictures and images pertaining the order can be
                      found under ‘Manage
                      Orders/My Trips/trips’. Non availability of any information on the order due to technical malfunction
                      of
                      the application or interface or technology based hackware or virus, will be taken in as distress and
                      CarterPorter or its
                      representatives are not liable. All reimbursements are subject to insurance claim terms, inspection,
                      submission and process.
                    </p>
                    <p>
                      Luggage is designed to protect its content and it is the customer or booking user’s responsibility to
                      pack
                      the contents appropriately. In the course of normal handling during transportation or transit or
                      handling
                      of moving luggage, your luggage will acquire
                      evidence of use /wear and tear such as, minor cuts, scratches, dents or soil. We trust your luggage
                      will
                      be able to handle the movement in handling and during transit, any spillage of contents during the
                      same
                      will not be our
                      responsibility or liability. We trust you understand that we shall have no liability for this type of
                      damage including but not limited to
                    </p>
                    <ul type="disc">
                      <li>
                        <p>
                          Broken feet/wheels or handles /straps
                        </p>
                      </li>
                      <li>
                        <p>
                          Damage to over packed/oversized bags
                        </p>
                      </li>
                      <li>
                        <p>
                          Damage due to unsuitable packing
                        </p>
                      </li>
                      <li>
                        <p>
                          Damage to pull handles / lost pull handles
                        </p>
                      </li>
                      <li>
                        <p>
                          Items of a fragile or perishable nature
                        </p>
                      </li>
                      <li>
                        <p>
                          Manufacturer/s defects
                        </p>
                      </li>

                    </ul>
                  </li>
                </ul>
                <p>
                  You may purchase additional insurance available on the CarterPorter to obtain adequate insurance coverage
                  through your Insurance Company prior to travel.
                </p>
                <p>
                  <b>Note applicable for all points on delivery/drop and pick up:</b><br />
                </p>
                <p>
                  Passengers are advised not to carry valuables such as jewellery, encashable articles, negotiable papers
                  and
                  currency in their baggage and not transfer the same using carterporter’s services. Carterporter is not
                  liable
                  for loss/damage to or delay in the
                  delivery of fragile or perishable articles or items of value or importance such as money, jewellery,
                  silverware, cameras, electronics/ video/computer or photographic equipment, negotiable papers, securities,
                  heirlooms, antiques, artifacts,
                  work of art, irreplaceable books or publications, manuscripts, business documents, precious metals and
                  other
                  similar valuables or commercial effects.
                </p>
                <ul>

                  <li>
                    <p>
                      Maximum direct Liability for cartertporter pvt ltd will be limited to value of the order paid directly
                      by
                      customer/passenger/employee or by corporate house or strategic alliance partner for the service
                      availed
                      minus the taxes paid for any case of damage,
                      loss or delayed delivery.
                    </p>
                  </li>

                </ul>
                <li className="bold section-heading">
                  <p className="bold section-heading">Price and Payment:</p>
                </li>
                <ul type="disc">
                  <li>
                    <p>The price of our services is set out to be in force at the time we confirm your booking. Our prices may change at any time but price changes will not affect bookings that we have confirmed with you. </p>
                  </li>
                  <li>
                    <p>
                      The payment made includes all Taxes. Taxes will be refunded only in the case of full refund value, else partial refunds will be levied with Cancellation charges.
                    </p>
                  </li>

                  <li>
                    <p>
                      We require payment in advance for the Services at the time of the booking. We accept payment by credit
                      card or debit card or Internet Banking or Wallets and UPI. Availability of payment options may change
                      from
                      time to time.
                    </p>
                  </li>
                  <li>
                    <p>
                      You pay only for the order upfront except order modification charges or reschedule (forced or
                      voluntary)
                      or orders made through phone or kiosk, if they apply. Order will be delivered only once payment is
                      fully
                      made.
                    </p>
                  </li>
                  <li>
                    <p>
                      All charges at location can be paid before delivery by debit or credit cards by the either booking
                      customer or representative taking delivery by payment links sent before delivery. Delivery will be
                      made
                      only if complete payment has been made. Only Booking
                      Customer can pay the same charges by credit card or debit card or Internet Banking or Wallets and UPI
                      through the Website/App if activated through trip/orders section.
                    </p>
                  </li>
                  <li>
                    <p>
                      In case of cancellation of booking & partial refund - email will be sent with receipt and Invoice.
                    </p>
                  </li>
                  <li>
                    <p>
                      The rate reflected on the Website/App, may be different than the actual charges for your order at
                      different times based on promotional offers subjected during that period. Differences may occur based
                      on
                      category, actual weight, dimensions, and other factors.
                      Refer the applicable ‘rate card’ or the rates for Order modification details on how order charges are
                      calculated.
                    </p>
                  </li>
                  <li>
                    <p>
                      Tips shall not be paid to our representatives.
                    </p>
                  </li>
                </ul>
                <li className="bold section-heading">
                  <p className="bold section-heading">Your responsibilities:</p>
                </li>
                <ul type="disc">
                  <li>
                    <p>You agree to provide photographic ID at collection of your Package from us at the Drop Location.</p>
                  </li>

                  <li>
                    <p>You warrant that:</p>
                    <ul>
                      <li>
                        <p>You have authority to deal with the Package and its contents; and</p>
                      </li>
                      <li>
                        <p>The Package does not contain any prohibited items (as defined).</p>
                      </li>
                      <li>
                        <p>You release the carrier i.e.: carterporter pvt ltd and its representative from any knowledge of
                          all
                          contents of the bag/package and indemnify the carrier towards all charges other than that of
                          carriage
                          to and from the prescribed
                          location to airport.</p>
                      </li>
                      <li>
                        <p>
                          You acknowledge and agree that you will not pack, export or import any content to any country to
                          which
                          export or import is restricted under Indian law
                        </p>
                      </li>
                      <li>
                        <p>
                          You are not a national of any such country that Indian authorities have deemed as non friendly or
                          denied access
                        </p>
                      </li>
                      <li>
                        <p>
                          You are not a Denied Party listed
                        </p>
                      </li>
                      <li>
                        <p>
                          You are not a Special Designated National or Designated Nationals, and
                        </p>
                      </li>
                      <li>
                        <p>
                          The Content will not be used in the design, development or production of nuclear, chemical or
                          biological weapons.
                        </p>
                      </li>

                    </ul>
                    <p>
                      Luggage is designed to protect its content and it is the customer or booking user’s responsibility to
                      pack
                      the contents appropriately. In the course of normal handling during transportation or transit or
                      handling
                      of moving luggage, your luggage will acquire
                      evidence of use /wear and tear such as, minor cuts, scratches, dents or soil. We trust your luggage
                      will
                      be able to handle the movement in handling and during transit, any spillage of contents during the
                      same
                      will not be our
                      responsibility or liability. We trust you understand that we shall have no liability for this type of
                      damage including but not limited to
                    </p>
                    <ul type="disc">
                      <li>
                        <p>Broken feet/wheels or handles /straps</p>
                      </li>
                      <li>
                        <p>Damage to over packed/oversized bags</p>
                      </li>
                      <li>
                        <p>Damage due to unsuitable packing</p>
                      </li>
                      <li>
                        <p>Damage to pull handles / lost pull handles</p>
                      </li>
                      <li>
                        <p>Items of a fragile or perishable nature</p>
                      </li>
                      <li>
                        <p>Manufacturer/s defects</p>
                      </li>
                    </ul>
                    <p>
                      You may purchase additional insurance available on the CarterPorter to obtain adequate insurance
                      coverage
                      through your Insurance Company prior to travel.
                    </p>
                  </li>
                  <p>
                    <b> Note applicable for all points on delivery/drop and pick up:</b><br />
                  </p>
                  <p>
                    Customers are advised not to carry valuables such as jewellery, encashable articles, immediate medicine,
                    papers of importance such as passports, negotiable papers and currency in their baggage and not transfer
                    the
                    same using carterporter’s services. Carterporter
                    is not liable for loss/damage to or delay in the delivery of fragile or perishable articles or items of
                    value or importance such as money, jewellery, silverware, cameras, electronics/ video/computer or
                    photographic equipment, negotiable
                    papers, passports, securities, heirlooms, antiques, artifacts, work of art, irreplaceable books or
                    publications, manuscripts, business documents, precious metals and other similar valuables or commercial
                    effects.
                  </p>
                </ul>
                <li className="bold section-heading">
                  <p className="bold section-heading">
                    Security arrangements:
                  </p>
                </li>
                <ul type="disc">
                  <li>
                    <p> As part of our security procedures, your Package will be fully packed in tamper evident bag wrapped
                      after inspecting the same.</p>
                    <p>
                      Your Package shall be weighed at location and recorded by the images loaded prior to pickup. The
                      images
                      will be available under Manage Orders/My Trips.
                    </p>
                    <p>
                      Each Package shall be secured in separate tamper evident bags. The bags are marked and bar coded for
                      easy
                      identification. Each bag will be sealed so as to prevent any loss of contents. We understand the
                      sensitivity and priority; we ensure the required
                      special care and handling. The bags are sealed therefore the handles, trolleys and pockets will not be
                      accessible for us. The tamper resistant sealable bag will be opened only on delivery on request. The
                      tamper resistant sealable
                      bags may incur wear and tear during transit due to wheels or other sharp items protruding or present
                      in
                      the luggage/baggage. Any package that cannot be packed in tamper evident bags due to dimension or
                      weight
                      will be carried
                      on consent with the customer.
                    </p>
                    <p>
                      Items that come under the Fragile category are carried subject to personalized inspection. In case
                      booking
                      customer is absent at the time of pick up for the inspection of Fragile luggage item, then the
                      inspection
                      will be done in the presence of location
                      contact person. Inspection before carriage is compulsory and the Fragile item will not picked up if
                      the
                      case is locked or unavailable for checking. Additional packing of bubble wrap will be completed once
                      the
                      item is inspected
                      before it is secured in separate tamper resistant sealable bag. The item shall be further stored in a
                      separate case to ensure sensitivity during transit. The tamper resistant sealable bags may incur wear
                      and
                      tear during transit
                      due to wheels or other sharp items protruding or present in the luggage/baggage.</p>
                    <p>Maximum weight per Package is restricted as per the airline mandate for all categories other than Fragile. The maximum height is restricted to airline’s mandate. More information available in Arrival booking information and Departure booking information.
                    </p>
                  </li>

                  <li>
                    <p> The tamper proof evident sealable bag will not be opened and/or searched by our personnel without
                      your
                      consent except where we are required to do so by any regulatory or governmental authority or the
                      airport
                      personnel.</p>
                  </li>

                  <li>
                    <p>
                      You are strongly advised against packing valuable and fragile items in your Checked Luggage, Cabin
                      Luggage, Coats and jackets and Oversized Luggage. Items that are sealed and leak proof will be taken
                      to
                      be
                      carted. Some items of value are listed below
                      to strongly indicate the contents that are not covered under Insurance or liability:</p>
                    <ul>
                      <li>
                        <p>Laptops</p>
                      </li>
                      <li>
                        <p>Portable electronics</p>
                      </li>
                      <li>
                        <p>Any item that could be damaged or broken during transit</p>
                      </li>
                      <li>
                        <p>Medication</p>
                      </li>
                      <li>
                        <p>Securities / negotiable papers</p>
                      </li>
                      <li>
                        <p>Passports and other important documents</p>
                      </li>
                      <li>
                        <p>Silverware / precious metals / precious stones</p>
                      </li>
                      <li>
                        <p>Money / jewelry / other valuable items</p>
                      </li>
                      <li>
                        <p>Business / travel / identification documents</p>
                      </li>
                      <li>
                        <p>Any illegal substances or items of explosive nature</p>
                      </li>
                      <li>
                        <p>Items which are not leak proof</p>
                      </li>
                      <li>
                        <p>Inflammable items</p>
                      </li>
                      <li>
                        <p>Liquids</p>
                      </li>
                      <li>
                        <p>Plastic attachments of Baby Prams and Strollers</p>
                      </li>
                      <li>
                        <p>Seats of wheel chairs/prams/strollers</p>
                      </li>
                      <li>
                        <p>Perishable items etc…</p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      The customer will be able to track their order/booking using the consignment number or order number
                      provided by us in the website/mobile app which enables the customer to check the status of his Package
                      until it reaches the delivery address. Contact information
                      will also be made available to the customer of our personnel who will be delivering the order. All
                      other
                      parties involved in the booking like location contact and passenger if they are not the same as the
                      booking customer will
                      also be able to access and view (read only) the booking and its changing status. We will from time to
                      time
                      send them other notifications through app/Sms/email to keep them informed of the booking status and
                      other
                      information
                      deemed required to complete the order seamlessly.</p>
                  </li>
                  <li>
                    <p>
                      Email confirmation and Invoice will be sent via email once delivery is made. SMS will be sent at
                      intervals
                      during the service from pick up to delivery. English will be the only medium of communication used for
                      all
                      Emails/SMS. CarterPorter's representatives
                      information will be sent to registered mobile number & alternative mobile number as provided by
                      customer. Location contact for pickup/drop will also be contacted with SMS.
                    </p>
                  </li>
                  <li>
                    <p>
                      In addition to the Website/App Terms and conditions of Use, the following additional terms and
                      conditions
                      govern the access and use of this tracking updates received from time to time on the date of service
                      and
                      otherwise. We authorize you to track the
                      updates for an order for which you are the customer, recipient or third-party payor and you agree to
                      only
                      track updates for a order for which you are the customer, recipient, or third-party payor subject to
                      these
                      Terms and
                      Conditions. You acknowledge and agree that tracking updates are the private property of CARTERPORTER
                      PRIVATE LIMITED and the same are provided to you free of charge and that any use of tracking updates
                      information is at your
                      sole risk. Tracking updates are provided "AS IS" and CARTERPORTER PRIVATE LIMITED disclaims all
                      warranties
                      or conditions, express or implied.
                    </p>
                  </li>
                  <li>
                    <p>
                      All unclaimed Package shall be handed over to the relevant authorities beyond 6 hours of inability of
                      the
                      user to meet at airport/city address or unavailability of the Customer or re-schedule Package is not
                      activated.
                    </p>
                  </li>
                  <li>
                    <p>
                      In addition to our Terms of Use, the following terms and conditions govern your access and use of
                      Signature Proof of receipt/Delivery. By accessing and using Signature Proof of receipt/Delivery, you
                      acknowledge and agree that • you are the customer, the
                      recipient, or third party payor, or are authorized to act on behalf of the customer, recipient, or
                      third
                      party payor to retrieve the signature image for the order you are attempting to track. You warrant and
                      agree, on behalf
                      of yourself and all persons on whose behalf you are acting in accessing and using Signature Proof of
                      receipt/Delivery, that you will not use the signature image for any purpose other than to confirm the
                      delivery of such order.
                      You further acknowledge and agree, on behalf of yourself and all persons on whose behalf you are
                      acting
                      in
                      accessing and using Signature Proof of receipt/Delivery, (i) to defend (at our company option),
                      indemnify,
                      and hold
                      harmless us or our representatives, its parent and its parent company's subsidiary companies
                      (collectively
                      "CARTERPORTER PRIVATE LIMITED" for purposes of this paragraph) from and against any and all claims of
                      whatever nature
                      arising from your access and use of Signature Proof of receipt/Delivery, and the receipt, use and
                      emailing
                      of any signature retrieved; (ii) that Signature Proof of receipt/Delivery is provided "AS IS" and you
                      assume all risk
                      of accessing and using Signature Proof of receipt/Delivery; (iii) that CARTERPORTER PRIVATE LIMITED
                      DISCLAIMS ANY AND ALL WARRANTIES OR CONDITIONS OF WHATEVER NATURE, INCLUDING THE IMPLIED WARRANTIES OR
                      CONDITIONS OF MERCHANTABILITY
                      AND FITNESS FOR A PARTICULAR PURPOSE IN RESPECT TO SIGNATURE PROOF OF RECEIPT/DELIVERY; (iv) you will
                      only
                      give your Signature Proof of receipt/Delivery to the recipient or third party payor of the order you
                      are
                      attempting
                      to service through us; (v) that under no circumstances shall we be liable for any money or other
                      damages
                      resulting from the access and use of Signature Proof of receipt/Delivery and that the company or its
                      representatives hereby
                      disclaims liability for any such damage; and, (vi) that your actions and orders are further governed
                      by,
                      and you will comply with, the terms and conditions in the applicable and privacy agreement.
                    </p>
                  </li>
                  <li>
                    <p> Insurance:</p>
                    <p> Each Package in the order is insured upto Rs.5000/-. The sum insured is inclusive of the Package
                      exterior case. We are merely facilitating insurance purchase for the Package in the order on our
                      website/Mobile App. Insurance policy
                      number is available under Manage Orders/My Trips. The order number is the consignment number. All
                      claims
                      need to be made within 48 hours of delivery of the order to the insurance provider directly. We are
                      not
                      responsible for
                      any insurance related matters.</p>

                    <p>
                      Insurance claim procedure is listed out for online and offline services. Please follow the steps and
                      complete formalities. All available documentation can be found under ‘Manage Orders/My Trips’ unless
                      it
                      is
                      a distress day for technology or communication
                      for which CarterPorter Private limited or its employees or affiliates cannot be held liable.
                    </p>
                    <p>
                      Insurance is subject matter of solicitation. Please refer to policy wordings for terms and conditions, coverage and exclusions.
                      Policy issued by CHOLA MS General Insurance Co. Ltd 9th Floor, Golden Heights Complex, 59th C Cross
                      Rajajinagar Bangalore-560010
                      website: <a className="bold" href="https://www.cholainsurance.com"
                        target="_blank" rel="noopener noreferrer">www.cholainsurance.com</a>
                    </p>

                  </li>

                </ul>
                <li className="bold section-heading">
                  <p className="bold section-heading">
                    Fair usage policy and refusal or rejection of booking:
                  </p>

                </li>
                <ul type="disc">
                  <li>
                    <p> We reserve the right to reject or subsequently cancel Bookings where we believe there to be mis-use
                      of
                      our Services by you or a third party for commercial gain.
                    </p>
                  </li>
                  <li>
                    <p>
                      We reserves the right to refuse, hold, cancel, postpone or return any booking/order at any time if
                      such
                      order would in our opinion be likely to cause damage or delay to other orders, goods or persons, or
                      the
                      carriage of which is prohibited by law or is
                      in violation of any of these Conditions. The fact that we accept a booking/order does not mean that
                      such
                      booking/order conforms to applicable laws and regulations or to the present Conditions.
                    </p>
                  </li>

                </ul>
                <li className="bold section-heading">
                  <p className="bold section-heading">
                    Events outside our control:</p>

                </li>
                <ul type="disc">
                  <li>
                    We shall not be liable for any failure to provide the services arising from any event outside our
                    control
                    or
                    action by a/any third party including:
                  </li>
                  <li>
                    Failure to meet airport security requirements; The luggage/package will be immediately removed from the
                    airport premises and if additionally screening are required we shall hand the same over to relevant
                    authorities. The contact numbers of passenger/customer/employees
                    will be shared with relevant authorities.
                  </li>
                  <li>
                    Failure to meet surface transportation requirements. The luggage will be delivered back to the pick up
                    address with/without prior communication.
                  </li>
                  <li>
                    Failure to leave the order at the pickup address for pick up for delivery to the Airport Delivery
                    Address on
                    time; The luggage/package will be immediately removed from the airport premises if failure to pickup at
                    the
                    Airport meet time.
                  </li>
                  <li>
                    Failure to leave the Package at the Airport/city for delivery to the Domestic Delivery Address on time;
                  </li>
                  <li>
                    Rejected delivery (incorrect details or non-acceptance) at the Domestic Delivery Address;
                  </li>
                  <li>
                    National or local disruption in ground/air/water and natural calamities;
                  </li>
                  <li>
                    the actions of any regulatory or governmental authority.
                  </li>
                  <li>
                    Due to technical malfunction of the application or interface or technology based hack ware or virus,
                  </li>
                </ul>
                <li className="bold section-heading">
                  <p className="bold section-heading">Data Privacy:</p>

                </li>
                <ul type="disc">
                  <li>
                    <p>
                      By accepting these Terms, you are also agreeing that we may process information relating to you in
                      accordance with, and otherwise accepting the terms of, our privacy policy, which can be found here.
                      Our
                      privacy policy sets out details of how we use the
                      personal information you provide to us or we otherwise obtain about you. If you make a Booking on
                      behalf
                      of any other person you must draw their attention to our privacy policy and ensure that they also
                      agree
                      that we may process
                      information relating to them in accordance with it and otherwise accept its terms.
                    </p>
                  </li>
                  <li>
                    <p>
                      In the event of mobile app being deleted, there may be loss of data or address. The same content of
                      past
                      orders may be refreshed once again on download. The address under saved addresses will be lost.
                      However,
                      we will not delete your account or information
                      about your account. If you have any concerns, please email us at <span
                        className="link"> <a href="mailto:customercare@carterporter.in">customercare@carterporter.in</a></span>.
                    </p>
                  </li>
                  <li>
                    <p>
                      The Website/App provides you with the opportunity, to grab/archive/ download/share the order
                      confirmation
                      or details to send a message to the recipient or others informing him/her of your order. This feature
                      is
                      provided free of charge. We may modify or
                      terminate the use of such Alert at any time. All SMS/emails communication will be in English.
                    </p>
                  </li>
                  <li>
                    <p>
                      The Website/App does not commit to keeping your message private or confidential. By using sharing
                      options
                      you acknowledge that the Website/App is providing the technical functionality only, and that you are
                      solely responsible for the content of your messages.
                      The Website/App undertakes no duty to monitor any messages sent by you. However, the Website/App, in
                      its
                      sole discretion, may elect, but is not obligated, to look at your messages to protect itself.
                    </p>
                  </li>
                  <li>
                    <p>
                      Do not use sharing options and SMS Alert for anything other than to communicate information about your
                      order. You may not use the CarterPorter’s messages/shares/messages/emails or any other communication
                      received to disseminate inflammatory, infringing,
                      obscene, or other unlawful information, or to threaten, harass, abuse or otherwise violate the legal
                      rights of others or perform any act contrary to law. If we or our representatives sees or hears about
                      messages sent via Website/App
                      Alert that violate these provisions, or that may damage the company or its services, it may take all
                      actions necessary to protect itself, including disclosing any messages to the authorities.
                    </p>
                  </li>
                  <li>
                    <p>
                      It is not mandatory to for us to send alerts for orders for our services made via the Website/App. We
                      will
                      not be liable for any failure or delay, for any reason, in the transmission, receipt, or
                      acknowledgment
                      of
                      any messages sent by or to you. All SMS/Email
                      communication will be in English.
                    </p>
                  </li>
                  <li>
                    <p>
                      Address Book: Subject to the terms listed here, addresses will remain in your Address Book as long as
                      you
                      use the Website/App. If you do not use Website/App for a period of 6 months, your last addresses may
                      be
                      deleted. If the application is deleted, the
                      address book will lose its data completely.
                    </p>
                  </li>
                  <li>
                    <p>
                      The Address Book is a feature provided free of charge by us. You should maintain a back-up copy of
                      your
                      addresses as we will not be responsible for the loss of addresses contained in the Address Book. We
                      may
                      modify or terminate this feature at any time
                      for any reason.
                    </p>
                  </li>
                </ul>
                <li className="bold section-heading">
                  <p className="bold section-heading">
                    Our liability:
                  </p>
                </li>
                <ul type="disc">
                  <li>
                    <p>Subject to the following provisions of this paragraph, except in respect of death or personal injury
                      caused by our negligence, or that of our agents, our liability to the Customer for loss and/or damage
                      caused by our negligence
                      or breach of contract or otherwise which arises out of or in connection with the provision of or
                      failure
                      to provide the Services or their use by the Customer shall be limited to the service charges paid by
                      the
                      customer/corporate
                      house/strategic partner/alliance partners excluding the applicable taxes as follows:</p>
                  </li>
                  <ul>
                    <li>
                      <p>
                        we shall have no liability for loss of or damage to Prohibited Items;
                      </p>
                    </li>
                    <li>
                      <p>
                        We shall not be liable to the Customer, for negligence, breach of contract or otherwise, for any
                        indirect, special or consequential loss, nor for any losses arising from business interruption,
                        wasted
                        management time, loss of goodwill or loss of data,
                        whether or not arising in the normal course of business;
                      </p>
                    </li>
                    <li>
                      <p>
                        We shall not be liable to the Customer or deemed to be in breach of the Terms by reason of any delay
                        in
                        performing or any failure to perform any of our obligations under the Terms if the delay or failure
                        was
                        due to any circumstances beyond our reasonable
                        control in accordance with point no 8 of these Terms;
                      </p>
                    </li>
                    <li>
                      <p>
                        The Customer acknowledges that the limitations of liability as set out in this paragraph are fair
                        and
                        reasonable in the circumstances and have been taken into account and reflected in the level of the
                        prices charged.
                      </p>
                    </li>
                    <li>
                      <p>
                        USE OF OUR WEBSITE/APPLICATION AND ITS CONTENT IS AT YOUR SOLE RISK. WE WILL IN NO EVENT BE LIABLE
                        TO
                        YOU OR ANY PERSON OR ENTITY CLAIMING THROUGH YOU FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, INCIDENTAL
                        OR
                        OTHER DAMAGES UNDER ANY THEORY OF LAW FOR ANY
                        ERRORS IN OR THE USE OF OR INABILITY TO USE OUR SERVICE AND ITS CONTENT INCLUDING WITHOUT
                        LIMITATION,
                        DAMAGES FOR LOST PROFITS, BUSINESS, DATA, PACKAGES OR DAMAGE TO ANY COMPUTER SYSTEMS, EVEN IF YOU
                        HAVE
                        ADVISED US OR
                        OUR REPRESENTATIVES OF THE POSSIBILITY OF SUCH DAMAGES.
                      </p>
                    </li>
                  </ul>
                </ul>
                <li className="bold section-heading">
                  <p className="bold section-heading">
                    Indemnity
                  </p>
                </li>
                <ul type="disc">
                  <li>
                    <p>You agree to defend, indemnify and hold harmless us and our representative and employees, its parent
                      and
                      affiliate companies and their respective officers, directors, employees, agents and representatives
                      from
                      any and all claims
                      (i) arising out of your breach of any of these terms and conditions, and any of your activities
                      conducted
                      in connection with this site and application that provides the service.</p>
                  </li>

                </ul>
                <li className="bold section-heading">
                  <p className="bold section-heading">
                    Link to other websites
                  </p>
                </li>
                <ul type="disc">
                  <li>
                    <p>There are links on the Website/App that allow you to visit the sites of third parties. Neither these
                      sites nor the companies to whom they belong are controlled by us. We make no representations
                      concerning
                      the information provided
                      or made available on such sites nor the quality or acceptability of the products or services offered
                      by
                      any persons or entities referenced in any such sites. The Website/App linked to our Website/App is not
                      tested and we make
                      no representations regarding the correctness, performance or quality of any software found at any such
                      sites. You should research and assess the risks which may be involved in accessing and using any
                      software
                      on the Internet
                      before using it.</p>
                  </li>

                </ul>
                <li className="bold section-heading">
                  <p className="bold section-heading">
                    Your right to cancel:
                  </p>
                </li>
                <ul type="disc">
                  <li>
                    <p>You may cancel your bookings as follows:</p>
                  </li>
                  <li>
                    <p>
                      Cancellation of orders can be made under Manage Orders/My Trips of your account.
                    </p>
                  </li>
                  <li>
                    <p><b>Cancellation & Refund Policy:</b>

                    </p>
                  </li>
                </ul>
                <p><b>6EBagPort - For orders towards the Airport: </b></p>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p><b>Ticket cancelled by Passenger:</b> <br />
                          Post booking confirmation before 24 Hours
                        </p>
                      </td>
                      <td>
                        <p>
                          100% of order value will be refunded back to source account
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><b>Ticket cancelled by Passenger:</b> <br />
                          Post booking confirmation  after 24 Hours
                        </p>
                      </td>
                      <td>
                        <p>The order can be rescheduled for service on any IndiGo flight for the next 7 days. </p>
                        <p>Please use the confirmation email and write to <a href="mailto:6EBagport@carterporter.in">6EBagport@carterporter.in</a> to redeem the service at least 36 hours before the date of service.</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><b>Ticket cancelled by Passenger post pick up of order#: </b><br />
                          Post booking confirmation before 12 – 4 hours to departure
                        </p>
                      </td>
                      <td>
                        <p>
                          No Refund. Baggage will be redirected back to the pick up address at extra cost. Passenger will bear the cost of redirection to the city on the same order value paid for the 1st service.. <br />
                          The order to the city will be delivered within 6 – 8 hours of next CarterX dispatch from the airport. <br />
                          If the passenger delays in redirection email for more than 12 hours after the order# is picked then additional daily storage charge at the airport will also be levied to be paid by the passenger in addition to the reschedule delivery charges. <br />
                          The redirection/reschedule should be communicated by passenger to CarterX via email with Order# to <a href="mailto:6EBagport@carterporter.in">6EBagport@carterporter.in.</a>
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p><b>No Response at Pickup Location:
                          No Response at Pickup Location or Not received Pickup Location update from passenger before 12 hours of departure:
                        </b><br />No response from passenger to get the pick up location for up to 12 hours of departure time. <br />
                          No response from the location contact or customer at pick up location, after 15 minutes of waiting order will be considered as cancelled. </p>
                      </td>
                      <td>
                        <p>The cancellation charges are 100% of order amount citing no response at location.
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p><b>Failure to meet CarterX before entering  Airport 120 – 60 minutes prior to departure time </b><br />
                          Meet with CarterX at the terminal is compulsory and luggage cannot be brought into the terminal or handed over to IndiGo without entering the terminal with passenger. </p>
                      </td>
                      <td>
                        <p>The cancellation charges are 100% of order amount citing no response at delivery location.  </p>
                        <p>Redirecting of the order to be done by passenger directly with CarterX at an extra charge. Extra charge to be borne completely by passenger.</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><b>Flight cancelled by IndiGo</b> Bags are not. Picked up by CarterX:</p>
                        <p>Post booking confirmation before 48 – 12  hours to departure </p>
                      </td>
                      <td>
                        <p>The order can be rescheduled for service on any IndiGo flight for the next 7 days. </p>
                        <p>Please use the confirmation email and write to <a href="mailto:6EBagport@carterporter.in">6EBagport@carterporter.in</a> to redeem the service at least 24 hours before the time of flight departure.</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><b>Flight cancelled by IndiGo</b> Bags are Picked up by CarterX:</p>
                        <p>Post booking confirmation before 12 – 4 hours to departure and passenger rescheduled on another IndiGo within 24 hours   </p>
                      </td>
                      <td>
                        <p>The baggage will be stored with CarterX if the passenger is rescheduled on a IndiGo flight within 24 hours. </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><b>Flight cancelled by IndiGo</b> Bags are Picked up by CarterX:</p>
                        <p>Post booking confirmation before 12 – 4 hours to departure and not rescheduled on an IndiGo flight </p>
                      </td>
                      <td>
                        <p>Baggage will be redirected back to the pick up address at no extra charge
                          The order to the city will be delivered within 6 – 8 hours of next CarterX dispatch from the airport.
                        </p>
                        <p>If redirection is not an option: Luggage will be stored in the airport at no extra cost for another 24 hours till the next rescheduled IndiGo flight and will have to be communicated by passenger to CarterX via email with Order# to <a href="mailto:6EBagport@carterporter.in">6EBagport@carterporter.in</a> Luggage has to be collected at the airport thereafter by passenger. </p>
                        <p>If passenger is flying a non IndiGo airline then luggage order will be delivered to the passenger outside the terminal. </p>

                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><b>Post Pick up: </b> No show by passenger at the airport after 6 hours Gate meet time. Delivery at Local address back at the city.</p>
                      </td>
                      <td>
                        <p>Passenger will have to redirect and Reschedule order will be made with same order value and rescheduled back for delivery at the location of pick up. Local delivery to another location or delivery at the airport will apply for rescheduled order. Order amount will be extra and the same will have to paid by passenger as per charges as per <a href="www.carterporter.in">www.carterporter.in</a> </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <b>Post Pick up: </b> Delayed show of up to 06 hours after gate meet time. Delivery at Airport.</p>
                      </td>
                      <td>
                        <p>Passenger will have to redirect and Reschedule order will be made with local delivery charges will apply for rescheduled order for luggage storage services. Order amount will be extra and the same will have to paid by passenger as per charges as per <a href="www.carterporter.in">www.carterporter.in</a></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><b>Cancellation and passenger Rescheduled on IndiGo Flight</b></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><b>Reschedule of IndiGo Flight on the same day departure or up to 24 hours</b></p>

                      </td>
                      <td>
                        <p>No Refund. Luggage will be stored till delivery on same or rescheduled order number without any charge. </p>

                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><b>Reschedule of IndiGo Flight on alternative date of travel before date of service</b></p>
                      </td>
                      <td>
                        <p>No Refund. The order date can be redeemed for another date of service for up to 7 days if CarterX is notified 24 hours prior with an email to <a href="mailto:6EBagPort@carterporter.in">6EBagPort@carterporter.in</a> by passenger directly with Order# </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><b>Reschedule of IndiGo Flight on alternative date of travel  or after date of service</b></p>
                      </td>
                      <td>
                        <p>Baggage will be redirected back to the pick up address at no extra charge <br />
                          The order to the city will be delivered within 6 – 8 hours of next CarterX dispatch from the airport.
                        </p>
                        <p>If redirection is not an option: Luggage will be stored in the airport at no extra cost for another 24 hours till the next rescheduled IndiGo flight and will have to be communicated by passenger to CarterX via email with Order# to <a href="mailto:6EBagPort@carterporter.in">6EBagPort@carterporter.in</a> Luggage has to be collected at the airport thereafter by passenger. </p>
                        <p>If passenger is flying a non IndiGo airline then luggage order will be delivered to the passenger outside the terminal. </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <br />

                <p class="bold">6EBagPort - For orders from the Airport:  </p>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p><b>Ticket cancelled by Passenger: </b> <br />
                          Post booking confirmation before 24 hours of service

                        </p>
                      </td>
                      <td>
                        <p>100% of order value will be refunded back to source account
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><b>Ticket cancelled by Passenger:
                        </b><br />
                          Post booking confirmation after 24 Hours
                        </p>
                      </td>
                      <td>
                        <p>The order can be rescheduled for service on any IndiGo flight for the next 7 days. </p>
                        <p>Please use the confirmation email and write to <a href="mailto:6EBagport@carterporter.in">6EBagport@carterporter.in</a> to redeem the service at least 24 hours before the date of service.</p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p><b>No Response at Baggage Belt to Meet CarterX</b>
                        </p>
                      </td>
                      <td>
                        <p>The cancellation charges are 100% of order amount citing no response at location. </p>
                        <p>CarterX or IndiGo will not  be responsible for the baggage or order if CarterX personnel is not met by the passenger at the baggage belts. </p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p><b>No Response at Delivery Location or lack of Delivery location update from passenger for up to 12 hours after arrival</b></p>
                        <p>No response from the location contact or customer at Delivery location, after 15 minutes of waiting, order will be considered as cancelled. </p>
                      </td>
                      <td>
                        <p>The cancellation charges are 100% of order amount citing no response at location. </p>
                        <p>Redirecting of the order to be done by passenger directly with CarterX at an extra charge borne by the passenger. </p>
                        <p>Redirection charges will be same as the primary order value.</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><b>Flight cancelled by IndiGo: </b></p>
                        <p>Post booking confirmation before 48 - 2 hours before Arrival </p>
                      </td>
                      <td>
                        <p>The order can be rescheduled for service on any IndiGo flight for the next 7 days. </p>
                        <p>Please use the confirmation email and write to <a href="mailto:6EBagport@carterporter.in">6EBagport@carterporter.in</a> to redeem the service at least 24 hours before the date of service.</p>

                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><b>Cancellation and Reschedule on IndiGo Flight</b></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><b>Reschedule of IndiGo Flight on the same day of arrival</b></p>
                      </td>
                      <td>
                        <p>No Refund. New time of service, IndiGo Flight Number and flight time to be updated to <a href="mailto:6EBagport@carterporter.in">6EBagport@carterporter.in</a> by passenger directly notifying the change in arrival and the same service will be completed for the new flight arrival time.  </p>

                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><b>Reschedule of IndiGo Flight on alternative date of travel (before or after) date of service</b></p>
                      </td>
                      <td>
                        <p>Order can be Redeemed for the new date of arrival. New date of service and flight time to be updated to <a href="mailto:6EBagport@carterporter.in">6EBagport@carterporter.in</a> with Order# by passenger directly notifying the change in arrival and the order will be honoured if the date of service is up to 48 hours as alternative/rescheduled date of arrival.</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><b>If passenger arrives on the same date of service or on another date with a Non IndiGo Airline</b></p>
                      </td>
                      <td>
                        <p>Order can be Redeemed for any IndiGo flight. New date of service and flight time to be updated to <a href="mailto:6EBagport@carterporter.in">6EBagport@carterporter.in</a> with Order# by passenger to opt for the same service anytime for 7 days on any other IndiGo flight for the same passenger or nominate another passenger.</p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <ul type="disc">
                  <li><p>	Refunds can be tracked under Manage Orders of your account. </p>	</li>
                  <li><p>	All refunds will be processed within 7 working days. </p>	</li>
                  <li><p>	All refund payments will be processed back to the source account. Refund amount will reflect under the name ‘Carterporter Private Limited’ in the source statement of accounts.</p>	</li>
                  <li><p>	Terms & Conditions apply.</p>	</li>
                  <li><p>	Forward all queries to <a href="mailto:6EBagPort@carterporter.in">6EBagPort@carterporter.in</a></p>	</li>


                </ul>
                <li className="bold section-heading">
                  <p className="bold section-heading">Disclaimer of Warranty</p>
                </li>

                <ul>
                  <li>
                    <p>THE CONTENTS OF WEBSITE/APP ARE PROVIDED "AS IS" AND ITS REPRESENTATIVES DISCLAIM ANY AND ALL
                      WARRANTIES,
                      EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
                      FOR
                      A
                      PARTICULAR PURPOSE,
                      TITLE AND NON-INFRINGEMENT, REGARDING ANY SUCH CONTENT AND YOUR ABILIITY OR INABILITY TO USE THE
                      WEBSITE/APP AND ITS CONTENT.</p>
                    <p>
                      The Website/App and its representatives EXPRESSLY DISCLAIM AND EXCLUDES ALL WARRANTIES REGARDING the
                      Website/App, employees AND THE FUNCTIONING OF THE INTERNET WHETHER EXPRESS OR IMPLIED, INCLUDING BUT
                      NOT
                      LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY
                      AND FITNESS FOR A PARTICULAR PURPOSE. WE DO NOT WARRANT THAT our employees and representatives WILL
                      MEET
                      ALL OF CUSTOMER'S REQUIREMENTS OR THAT ITS OPERATIONS WILL BE UNINTERRUPTED OR ERROR FREE, OR THAT ANY
                      DEFECT WITHIN order
                      process WILL BE CORRECTED. FURTHERMORE, we DO NOT WARRANT NOR MAKE ANY REPRESENTATION REGARDING THE
                      RESULTS OF CUSTOMER'S USE OF our services IN TERMS OF CAPABILITY, CORRECTNESS, ACCURACY, RELIABILITY
                      OR
                      OTHERWISE. NO ORAL
                      OR WRITTEN INFORMATION, REPRESENTATION OR ADVICE GIVEN BY our employees or representatives OR AN
                      AUTHORIZED REPRESENTATIVE OF ours SHALL CREATE A WARRANTY.

                    </p>
                  </li>

                </ul>
                <li className="bold section-heading">
                  <p className="bold section-heading">
                    Governing Law and Jurisdiction</p>
                </li>
                <ul type="disc">
                  <li>
                    <p>Any cause of action with respect to our Website/App or this Terms and Conditions must be instituted
                      within 30 days after the claim or cause of action has arisen failing which it shall be time barred and
                      must be brought before a
                      court of competent jurisdiction.</p>
                  </li>
                  <li>
                    <p>Subject to the arbitration clause hereinabove provided, only the courts in Bangalore will have the
                      exclusive jurisdiction. This Terms & Conditions shall be governed and construed in accordance with
                      laws
                      in
                      India.</p>
                  </li>
                </ul>
                <li className="bold section-heading">
                  <p className="bold section-heading">
                    Our right to cancel:
                  </p>
                </li>
                <ul>
                  <li>
                    <p>
                      We reserve the right to cancel your Booking/order, if the Domestic Delivery Address provided at the
                      time
                      of booking is not an existing delivery address validated by us and we are unable to validate the
                      address
                      as a Domestic Delivery Address following
                      your booking. If we are unable to validate the address provided as a Domestic Delivery Address, we
                      will
                      notify you by email as soon as possible and provide you with the opportunity to provide us with an
                      alternative Domestic
                      Delivery Address under reschedule order.
                    </p>
                  </li>
                  <li>
                    <p>
                      We also reserve the right to cancel your Booking in accordance with our Booking Policy set out in
                      point
                      no
                      7 of these Terms.
                    </p>
                  </li>
                </ul>
              </ol>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}