import React, { useEffect, useState } from "react";
import { CarterX_API } from 'api/api';
import axios from 'utils/axios';
import Download from 'assets/images/Download.png';
import Next from '../../assets/images/Scroll_Next.png';
import Prev from '../../assets/images/Scroll_Prev.png';
import Passenger from '../../assets/images/passenger_security.pdf';
import Palace from 'assets/images/bangalore-palace.jpg';
import hyderabad from 'assets/images/Hyderabad RGIA.png';
import DelhiT1 from 'assets/images/Delhi-T1.png';
import DelhiT2 from 'assets/images/DelhiT2.png';
import DelhiT3 from 'assets/images/Delhi-T3.png';
// import Mumbai from 'assets/images/Mumbai_Navi_Mumbai.svg';
import MumbaiT1 from 'assets/images/Mumbai T1.png';
import MumbaiT2 from 'assets/images/Mumbai-T2.png';
import BlrStamps from 'assets/images/Bangalore_Stamps.svg';
import HyderabadStamps from 'assets/images/Hyderabad_Stamps.svg';
import DelhiStamps from 'assets/images/Delhi_Stamps.svg';
import MumbaiStamps from 'assets/images/Mumbai_Stamp.svg';

export default function LocateUs() {
	const [locateUsData, setLocateUsData] = useState(null);
	const [locationArray, setLocationArray] = useState(null);
	const [currentIndex, setCurrentIndex] = useState(null);

	useEffect(() => {
		getCityLocationHandler()
	}, [])

	const getCityLocationHandler = () => {
		let locData = [
			{
				"location": "24/7 CarterX kiosk at Arrivals. Opp. Relay store",
				"Departures": "Order Delivery up to Airline Check-in counters",
				"Arrivals": "Order Pick up at Luggage Carousel/baggage belts (Domestic & International)",
				"Luggage": "Available",
				"Inter_Terminal": "NA",
				"city": "Bangalore KIAL",
				"airport": "Kempegowda International Airport",
				"image": "../../../../../../assets/images/bangalore-palace.jpg",
				"stamp": "../../../../../../assets/images/Bangalore_Stamps.svg",
				"city_name": "Bangalore"
			},
			// {
			// 	"location": "24/7 CarterX kiosk",
			// 	"Departures": "Delivery till terminal entry gates",
			// 	"Arrivals": "Bags to be dropped at CarterX kiosk at Arrivals village. Opp. Café Coffee Day",
			// 	"Luggage": "Available",
			// 	"Inter_Terminal": "Available",
			// 	"city": "Hyderabad RGIA",
			// 	"airport": "Rajiv Gandhi International Airport",
			// 	"image": "../../../../../../assets/images/Hyderabad RGIA.png",
			// 	"stamp": "../../../../../../assets/images/Hyderabad_Stamps.svg",
			// 	"city_name": "Hyderabad"
			// },
			{
				"location": "CarterX  Executive presence with a Signage board",
				"Departures": "Delivery till terminal entry gates",
				"Arrivals": "Bags to be handed over to CarterX Executive with Signage board",
				"Luggage": "NA",
				"Inter_Terminal": "Available",
				"city": "Delhi/NCR T1 IGI",
				"airport": "Indira Gandhi International Airport",
				"image": "../../../../../../assets/images/Delhi-T1.png",
				"stamp": "../../../../../../assets/images/Delhi_Stamps.svg",
				"city_name": "Delhi"
			},
			{
				"location": "CarterX  Executive presence with a Signage board",
				"Departures": "Delivery till terminal entry gates",
				"Arrivals": "Bags to be handed over to CarterX Executive with Signage board",
				"Luggage": "NA",
				"Inter_Terminal": "Available",
				"city": "Delhi/NCR T2 IGI",
				"airport": "Indira Gandhi International Airport",
				"image": "../../../../../../assets/images/DelhiT2.png",
				"stamp": "../../../../../../assets/images/Delhi_Stamps.svg",
				"city_name": "Delhi"
			},
			{
				"location": "24/7 CarterX kiosk at Arrival. Opp. Plaza Premium Lounge & next to CarzOnRent COunter 24/7 CarterX kiosk at Departure Opp. Gate 8 on Departure level",
				"Departures": "Delivery till airline check-in counter",
				"Arrivals": "Pick-up from Baggagebelts",
				"Luggage": "NA",
				"Inter_Terminal": "Available",
				"city": "Delhi/NCR T3 IGI",
				"airport": "Indira Gandhi International Airport",
				"image": "../../../../../../assets/images/Delhi-T3.png",
				"stamp": "../../../../../../assets/images/Delhi_Stamps.svg",
				"city_name": "Delhi"
			},
			// {
			// 	"location": "24/7 CarterX kiosk. Opp. Arrival gate & next to Meru cabs counter",
			// 	"Departures": "Delivery till terminal entry gates",
			// 	"Arrivals": "Bages to be dropped at CarterX kiosk at Arrival gate & next to Meru cabs counter",
			// 	"Luggage": "NA",
			// 	"Inter_Terminal": "Available",
			// 	"city": "Mumbai/Navi Mumbai T1 CSMIA",
			// 	"airport": "Chhatrapati Shivaji Maharaj International Airport",
			// 	"image": "../../../../../../assets/images/Mumbai T1.png",
			// 	"stamp": "../../../../../../assets/images/Mumbai_Stamp.svg",
			// 	"city_name": "Mumbai"
			// },
			// {
			// 	"location": "24/7 CarterX kiosk at Arrival. Opp. Sivsagar & KFC outlets. Between Departure gate 5 & 6 next to Secure Wap",
			// 	"Departures": "Delivery till terminal entry gates",
			// 	"Arrivals": "Bages to be dropped at CarterX kiosk at Arrival gate & next to Meru cabs counter",
			// 	"Luggage": "NA",
			// 	"Inter_Terminal": "Available",
			// 	"city": "Mumbai/Navi Mumbai T2 CSMIA",
			// 	"airport": "Chhatrapati Shivaji Maharaj International Airport",
			// 	"image": "../../../../../../assets/images/Mumbai-T2.png",
			// 	"stamp": "../../../../../../assets/images/Mumbai_Stamp.svg",
			// 	"city_name": "Mumbai"
			// }
		]

		let show_array = []
		for (let i = 0; i < 3; i++) {
			setCurrentIndex(i)
			show_array.push(locData[i]);
		}
		setLocationArray(locData)
		setLocateUsData(show_array)
	}

	const add = () => {
		let nextData = locateUsData
		setCurrentIndex(currentIndex < locationArray.length - 1 ? currentIndex + 1 : currentIndex)
		nextData.shift()
		nextData.push(locationArray[currentIndex + 1])
		setLocateUsData([...nextData])
	}

	const sub = () => {
		let preData = locateUsData
		preData.pop()
		preData.splice(0, 0, locationArray[currentIndex - 3])
		setLocateUsData([...preData])
		setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : currentIndex)
	}

	return (
		<div className="locateUs d-none d-md-block">
			<div className="row justify-content-center">
				<div className="col-10">
					<div className="border-bottom">
						<div className="row mt-3 mt-md-5">
							<div className="col-8 d-flex align-items-center">
								<p className="title mb-0 pb-0">Locate us at the Airport</p>
							</div>
							<div className="col-4 text-center">
								<a className=" medium declaration d-flex align-items-center" href={Passenger} target="_blank">
									<p className="mb-0 ml-auto">
										<img className="btn ml-auto" src={Download} />
									</p>
									<p className="download mb-0 text-left">Download Airport Departure <br />Security Declaration</p>
								</a>
							</div>
						</div>
					</div>
					<div className="row pt-4 pb-5">
						<div className="col-12 col-md-12 col-lg-12 col-sm-12 d-flex">
							{
								currentIndex > 2 &&
								<img src={Prev} onClick={() => sub()} className="plusBtn" />
							}
							<div className="row">
								{
									locateUsData && locateUsData.length > 0 && locateUsData.map((item, i) => (
										<div className="col-4 px-2">
											<div className="card">
												<div className="card-body p-0">
													<div className="d-flex">
														<div className="w-50">
															<img className={`BlrImg ${item.city && item.city.toLocaleLowerCase().replaceAll(' ', '').replaceAll('/', '')}`}
																src={item.airport === 'Kempegowda International Airport' ? Palace :
																	item.airport === 'Rajiv Gandhi International Airport' ? hyderabad :
																		item.airport === 'Indira Gandhi International Airport' && item.city === "Delhi/NCR T1 IGI" ? DelhiT1 :
																			item.airport === 'Indira Gandhi International Airport' && item.city === "Delhi/NCR T2 IGI" ? DelhiT2 :
																				item.airport === 'Indira Gandhi International Airport' && item.city === "Delhi/NCR T3 IGI" ? DelhiT3 :

																					item.airport === 'Chhatrapati Shivaji Maharaj International Airport' && item.city === "Mumbai/Navi Mumbai T1 CSMIA" ? MumbaiT1 :
																						item.airport === 'Chhatrapati Shivaji Maharaj International Airport' && item.city === 'Mumbai/Navi Mumbai T2 CSMIA' ? MumbaiT2 : ''}

															/>
															<div className="innerDiv pl-3 pt-4 stampWidth" >
																<img className="mngImg" src={item.airport === 'Kempegowda International Airport' ? BlrStamps :
																	item.airport === 'Rajiv Gandhi International Airport' ? HyderabadStamps :
																		item.airport === 'Indira Gandhi International Airport' && item.city === "Delhi/NCR T1 IGI" ? DelhiStamps :
																			item.airport === 'Indira Gandhi International Airport' && item.city === "Delhi/NCR T2 IGI" ? DelhiStamps :
																				item.airport === 'Indira Gandhi International Airport' && item.city === "Delhi/NCR T3 IGI" ? DelhiStamps :
																					item.airport === 'Chhatrapati Shivaji Maharaj International Airport' ? MumbaiStamps : ''}
																/>
																<p className="font-weight-bold text-white cityName mt-3 w-100">{item.city}</p>
																<p className="text-white w-100 airportName">{item.airport}</p>
															</div>
														</div>
														<div className="pl-3 pt-2 w-50 pr-2">
															<p className="mb-3">
																<span className="font-weight-bold">Location at Airport: </span>
																<span className="">{item.location}</span>
															</p>
															<p className="mb-2 pr-1">
																<span className="font-weight-bold">Departure: </span>
																<span className="">{item.Departures}</span>
															</p>
															<p className="mb-2 pr-1">
																<span className="font-weight-bold">Arrivals: </span>
																<span className="">{item.Arrivals}</span>
															</p>
															<p className="mb-2 pr-1">
																<span className="font-weight-bold">Luggage Storage: </span>
																<span className="">{item.Luggage}</span>
															</p>
															<p className="mb-1 pr-1">
																<span className="font-weight-bold">Inter Terminal Transfer: </span>
																<span className="">{item.Inter_Terminal}</span>
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									))
								}
							</div>
							{
								currentIndex != (locationArray && locationArray.length - 1) &&
								<img className="rightBtn" src={Next} onClick={() => add()} />
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}