import React, { useState, useEffect } from 'react';
import Form from './form/Form';
import LocateUs from './LocateUs';
import SafetyFirst from './SafetyFirst';
import Mandatory from './Mandatory';
import Benefits from './Benefits';
import Process from './Process';
import MobileLocate from './MobileLocate';


const Loading = () => {
  const [activeTab, setActiveTab] = useState('departure');
  const [cid, setCid] = useState('');
  const [icid, setIcid] = useState('');
  const currentUrl = window.location.href;

  useEffect(() => {
    let params = new URL(currentUrl).searchParams;
    setCid(params.get('cid') && params.get('cid') !== null ? params.get('cid') : '')
    setIcid(params.get('icid') && params.get('icid') !== null ? params.get('icid') : '')
  }, [currentUrl])

  return (
    <>
      <div className="banner">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7 p-0 p-md-4">
              <Form activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
            <div className="col-md-5 d-none d-md-block">
              <div className="bannerText d-flex align-items-center">
                <div className="text-center">
                  <h1 className="px-3">Let us carry your baggage from doorstep to doorstep</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <LocateUs />
        <MobileLocate />
        <SafetyFirst />
        <Process activeTabTop={activeTab} />
      </div>
      <img src={`https://smetrics.goindigo.in/b/ss/indigoaviationprodenvnew/1?AQB=1&r=${document.referrer ? encodeURIComponent(document.referrer) : ''}&g=https%3A%2F%2Fwww.6ebagport.carterporter.in%2F&v65=www.6ebagport.carterporter.in%2F&pageName=CarterX%206E%20Bag%20Port%20Landing%20Page&c55=CarterX%206E%20Bag%20Port%20Landing%20Page&v0=${cid}&v7=${icid}&AQE=1`} height='1' width='1' border='0' style={{ display: 'none' }} />
    </>
  )
}

export default Loading;