import React, { useState, useEffect } from 'react';
import { usePlacesWidget } from "react-google-autocomplete";

const OneWayGoogleAddress = (props) => {
    const { activeTab, errorAddressCity, addressLine, selectedAddress, submitAddress, handleAddress, setAddressLine2, type } = props;

    const { ref } = usePlacesWidget({
        apiKey: "AIzaSyBYZ8y8XMESY-rrKXVXahIbNmOTnvsuIhM",
        onPlaceSelected: (place) => {
            handleAddress(place)
        },
        options: {
            types: []
        }
    });


    return (
        <div className="col-md-8">
            <div className="dropdown customDropdown">
                <div id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" className={`form-control dropdown-toggle ${errorAddressCity && 'cusErrors'}`}>
                    {addressLine != '' ? addressLine : activeTab === 'departure' && addressLine == '' ? 'Pick-up Address*' : 'Delivery Address*'}
                </div>
                <div className="dropdown-menu" id={type == 1 ? 'normalAddressForm' : 'roundAddressForm'} aria-labelledby="dropdownMenuButton1">
                    <p className='addressHeading'>{activeTab === 'departure' ? 'Pick-up Address*' : 'Delivery Address*'}</p>
                    <label className="dropdown-item">
                        Apartment / Road / Area *
                        <input type="text" id={type == 1 ? 'addressLineText' : 'addressLineText1'} ref={ref} placeholder="Enter Apartment / Road / Area" />
                    </label>
                    <label className="dropdown-item">
                        House / Flat / Door no.
                        <input type="text" name="name" id='addressLine2' placeholder="Enter Apartment / Road / Area" value={selectedAddress.addressLine2} onChange={e => setAddressLine2(e.target.value)} />
                    </label>
                    <label className="dropdown-item">
                        City *
                        <input type="text" name="addressCity" placeholder="Enter City"
                            value={selectedAddress.city} />
                        <span className='text-dark'>* denotes mandatory</span>
                    </label>
                    <div className='text-center mb-2'>
                        <span className='submitBtn cursorPointer' onClick={() => submitAddress()}>Submit</span>
                    </div>
                </div>
                <span className="cusError">{errorAddressCity ? 'Enter The Valid' + ' ' + (activeTab === 'departure' ? 'Pick-up Address' : 'Delivery Address') : ''}</span>
            </div>
        </div>
    )
}

export default OneWayGoogleAddress