import React, { useState, useEffect } from 'react';
import RoundTrip from './RoundTrip';
import OneWayGoogleAddress from './OneWayGoogleAddress'
import { NavLink } from "react-router-dom";
import _ from 'lodash';
import { CarterX_API } from 'api/api';
import axios from 'utils/axios';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import history from '../../../history';
import loaderRipple from 'assets/images/loaderRipple.gif';
// import select from 'react-select';
import Select from "react-select"
// import 'react-select/dist/react-select.css';
import { usePlacesWidget } from "react-google-autocomplete";

export default function TripForm(props) {
   let orderDetails = [];
   var paymentKey = '';
   const { activeTab, cityList, airPortMasterList, countryCodeList } = props
   const { register, handleSubmit, errors, setError, reset, setValue, getValues } = useForm();
   const onSubmit = data => onSubmitHandler(data);
   const year = new Date().getFullYear();
   const month = new Date().getMonth();
   const day = new Date().getDate();
   const [today, setToday] = useState('');
   const [serDate, setSerDate] = useState('');
   const [airPortOption, setAirPortOption] = useState([])
   const [trip, setTrip] = useState('oneWay')
   const [multiPin, setMultiPin] = useState(false)
   const [numOfBooking, setNumOfBooking] = useState(1);
   const [pinCodes, setPinCodes] = useState([]);
   const [mainPinCode, setMainPinCode] = useState('');
   const [mainPinCode1, setMainPinCode1] = useState('');
   const [priceDetails, setPriceDetails] = useState(null);
   const [roundPriceDetails, setRoundPriceDetails] = useState(null);
   const [totalPrice, setTotalPrice] = useState(null);
   const [selectedCCOpt, setSelectedCCOpt] = useState('91');
   const [mobNum, setMobNum] = useState('');
   const [allowedNum, setAllowedNum] = useState(10);
   const [cusErrors, setCusErrors] = useState('');
   const [cusDateError, setCusDateError] = useState('');
   const [clickOnBookNow, setClickOnBookNow] = useState([]);
   const [addressLine, setAddressLine] = useState('');
   const [slots, setSlots] = useState([]);
   const [filteredTime, setFilteredTime] = useState([]);

   const [slotOne, setSlotOne] = useState('');
   const [slotTwo, setSlotTwo] = useState('');
   const [selectedAddress, setSelectedAddress] = useState({
      addressLine1: '',
      addressLine2: '',
      pickupArea: '',
      deliveryArea: '',
      city: ''
   });
   const [area, setArea] = useState('');
   const [roundArea, setRoundArea] = useState('');
   const [roundAddress, setRoundAddress] = useState({});
   const [fullAddress, setFullAddress] = useState('');
   const [roundFullAddress, setRoundFullAddress] = useState('');
   const [roundCity, setRoundCity] = useState([]);
   const [errorAddressCity, setErrorAddressCity] = useState(false);
   const [errorRoundCity, setErrorRoundCity] = useState(false);
   const [pickUp, setPickUp] = useState('');
   const [roundPickUp, setRoundPickUp] = useState('');
   const [pickDropDetails, setPickDropDetails] = useState([]);
   const [hours, setHours] = useState(new Date().getHours());
   const [minutes, setMinutes] = useState(new Date().getMinutes());
   const [roundHours, setRoundHours] = useState(new Date().getHours());
   const [roundMinutes, setRoundMinutes] = useState(new Date().getMinutes());
   const [timePickerShow, setTimePickerShow] = useState(false);
   const [roundPickDropDetails, setRoundPickDropDetails] = useState([]);
   // const [paymentKey, setPaymentKey] = useState('');

   useEffect(() => {
      clear_form_values()
      if (pickUp != 'Doorstep Pickup') {
         if (activeTab === 'departure') {
            setHours(new Date().getHours() + 0 > 23 ? (new Date().getHours() + 0 - 24) : new Date().getHours() + 0);
            setRoundHours(new Date().getHours() + 0 > 23 ? (new Date().getHours() + 0 - 24) : new Date().getHours() + 0)
            setToday(new Date(year, month, day + (new Date().getHours() + 0 > 23 ? 1 : 1), 10, 33, 30, 0).toISOString().split("T")[0])
         } else {
            setRoundHours(new Date().getHours() + 0 > 23 ? (new Date().getHours() + 0 - 24) : new Date().getHours() + 0);
            setToday(new Date(year, month, day + (new Date().getHours() + 0 > 23 ? 1 : 1), 10, 33, 30, 0).toISOString().split("T")[0])
         }

         // if(new Date().setHours(0,0,0,0)  === new Date(today).setHours(0,0,0,0) ){
         setSerDate(today)
         setValue('serDate', today)
      }

      // setValue('pickup', activeTab === 'departure' ? 'Airport: Drop off Point' : 'Airport: Pickup Point')

      // setPickUp(activeTab === 'departure' ? 'Airport: Drop off Point' : 'Airport: Pickup Point')
   }, [activeTab, today])

   useEffect(() => {
      setValue('pickup', activeTab === 'departure' ? 'Airport: Drop off Point' : 'Airport: Pickup Point')
      setPickUp(activeTab === 'departure' ? 'Airport: Drop off Point' : 'Airport: Pickup Point')
   }, [activeTab])

   useEffect(() => {
      let filterAirPortData = []
      airPortMasterList && airPortMasterList.map((item) => {
         if (Number(item.airport_name_id) == 3 || 
         // Number(item.airport_name_id) == 7 || 
         // Number(item.airport_name_id) == 8 || Number(item.airport_name_id) == 9 || 
         Number(item.airport_name_id) == 12 || Number(item.airport_name_id) == 13 || Number(item.airport_name_id) == 14 || Number(item.airport_name_id) == 28) {
            filterAirPortData.push(item);
         }
      })
      setAirPortOption(_.compact(filterAirPortData))
      setCusDateError("");
      getSlotListHandler();
      setRoundCity([...cityList])

   }, [activeTab, airPortMasterList])

   const clear_form_values = () => {
      let remove_array = ['city', 'city1', 'airport', 'airport1', 'pnr', 'pnr1', 'slot', 'slots1', 'addressCity', 'name', 'email']
      let idArray = ['addressLineText', 'dropdownMenuButton1', 'addressLine2',]
      // if (trip === 'round') {
      //   let roundArray = ['addressLineText1', 'roundAddress2', 'roundCity', 'dropdownMenuButton2']
      //   roundArray.map((res) => { document.getElementById(res).value = '' })
      // }
      if (pickUp != '' && pickUp != 'Airport: Drop off Point' && pickUp != 'Airport: Pickup Point') {
         idArray.map((res) => { document.getElementById(res).value = '' });
      }
      // idArray.map((res) => { document.getElementById(res).value = '' })
      remove_array.map((res) => { setValue(res, '') })
      setAddressLine('');
      setMobNum('');
      setSelectedAddress({})
      // document.getElementById('dropdownMenuButton1').value ? document.getElementById('dropdownMenuButton1').value = '' : null

   }

   const getSlotListHandler = () => {
      axios.request({
         method: CarterX_API.slotTime.method,
         url: CarterX_API.slotTime.url
      })
         .then((response) => {
            if (response && response.data.slots) {
               setSlots([...response.data.slots])
               const data = response.data.slots
               filterTimeSlots('no', data);
            }
         })
         .catch((error) => {
            console.log(error);
         })
   }


   const filterTimeSlots = (type, dataSlot) => {
      var filtered_tilme_slot_two = [];
      let today = new Date();
      let mm = (today.getMonth() + 1)
      let dd = today.getDate();
      if (mm < 10) { mm = '0' + mm }
      if (dd < 10) { dd = '0' + dd }
      let todayDate = today.getFullYear() + '-' + mm + '-' + dd
      let selectedDate = (type == 'no' ? (serDate ? serDate : todayDate) : type)
      let CurrentTime = new Date().getHours();
      if (todayDate === selectedDate) {
         for (const j of dataSlot) {
            let str = j.slot_end_time.slice(0, 2);
            if (j.id_slots === 5) {
               str = Number(str) + 24;
               str = Number(str);
            }
            if (CurrentTime < str) {
               if (j.slot_type === 1 && (j.id_slots === 4 || j.id_slots === 5)) {
                  filtered_tilme_slot_two.push(j);
                  const arr = [...filteredTime, ...filtered_tilme_slot_two]
                  setFilteredTime(arr);
               }
            }
         }
      } else {
         for (const j of dataSlot) {
            if (j.slot_type === 1 && (j.id_slots === 4 || j.id_slots === 5)) {
               filtered_tilme_slot_two.push(j);
               setFilteredTime(filtered_tilme_slot_two);
            }
         }
      }
   }

   const dateValidationHandle = (value) => {
      setValue("slot", '');
      setValue("slots1", '');
      setSlotOne('');
      setSlotTwo('');
      setSlotOne("")
      var d1 = new Date(today);
      var d2 = new Date(serDate);
      if (d1 > d2) {
         setCusDateError("");
      }
      setSerDate(value)
      const data = slots;
      filterTimeSlots(value, data);
      verify_date_time();
   }

   function isEqualDate(d1, d2) {
      return (
         d1.toISOString().startsWith(d2.toISOString().substring(0, 10))
      );
   }


   function verify_date_time() {
      setMinutes(0);
      setHours(0);
      setRoundMinutes(0);
      setRoundHours(0);
      let current_date, selected_date;
   
      if (activeTab === "departure") {
         current_date = new Date();
         current_date.setHours(0, 0, 0, 0);
         current_date.setDate(current_date.getDate() + 1);
   
         selected_date = new Date(getValues('serDate1') ? getValues('serDate1') : new Date());
         selected_date.setHours(0, 0, 0, 0);
   
         if (current_date.getTime() === selected_date.getTime()) {
            setRoundMinutes(new Date().getMinutes());
            setRoundHours(new Date().getHours());
         } else {
            setRoundMinutes(0);
            setRoundHours(0);
         }
      } else if (activeTab !== "departure") {
         current_date = new Date();
         current_date.setHours(0, 0, 0, 0);
         current_date.setDate(current_date.getDate() + 1); 
   
         selected_date = new Date(getValues('serDate'));
         selected_date.setHours(0, 0, 0, 0);
   
         if (current_date.getTime() === selected_date.getTime()) {
            setMinutes(new Date().getMinutes());
            setHours(new Date().getHours());
   
            if (roundPickUp !== 'Airport: Drop off Point') {
               let newDate = new Date();
               newDate.setDate(newDate.getDate() + 1);
               setValue('serDate1', new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 10, 33, 30, 0));
            }
         } else {
            setMinutes(0);
            setHours(0);
         }
      }
   
      if (activeTab === "departure" && pickUp === 'Airport: Drop off Point') {
         let checkDate = new Date().getHours() + 0 > 23 ? true : false;
         current_date = checkDate ? (new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 2).setHours(0, 0, 0, 0)) : new Date();
         current_date.setHours(0, 0, 0, 0);
         current_date.setDate(current_date.getDate() + 1); // Add one day to the current date
   
         selected_date = new Date(getValues('serDate'));
         selected_date.setHours(0, 0, 0, 0);
   
         if (current_date.getTime() === selected_date.getTime()) {
            setMinutes(new Date().getMinutes());
            setHours(new Date().getHours());
            setRoundMinutes(new Date().getMinutes());
            setRoundHours(new Date().getHours());
         } else {
            setMinutes(0);
            setHours(0);
            setRoundMinutes(0);
            setRoundHours(0);
         }
      } else if (activeTab !== "departure" && roundPickUp === 'Airport: Drop off Point') {
         let checkDate = new Date().getHours() + 0 > 23 ? true : false;
         current_date = checkDate ? (new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 2).setHours(0, 0, 0, 0)) : new Date();
         current_date.setHours(0, 0, 0, 0);
         current_date.setDate(current_date.getDate() + 1); // Add one day to the current date
   
         selected_date = new Date(getValues('serDate1') ? getValues('serDate1') : new Date());
         selected_date.setHours(0, 0, 0, 0);
   
         if (current_date.getTime() === selected_date.getTime()) {
            setRoundMinutes(new Date().getMinutes());
            setRoundHours(new Date().getHours());
         } else {
            setRoundMinutes(0);
            setRoundHours(0);
         }
      }
   }
   
   

   const handleValidation = (mobNum) => {
      var formIsValid = true;
      var d1 = new Date(today);
      var d2 = new Date(serDate);

      setCusErrors("");
      if (!mobNum) {
         setCusErrors("Mobile Cannot be empty");
         formIsValid = false;
      }
      // eslint-disable-next-line
      if (mobNum.length !== allowedNum) {
         setCusErrors("Enter Valid Mobile Number")
         formIsValid = false;
      }
      if (d1 > d2) {
         setCusDateError("Service needs to be booked a day prior");
         formIsValid = false;
      }

      if (!fullAddress && pickUp != 'Airport: Drop off Point' && pickUp != 'Airport: Pickup Point') {
         setErrorAddressCity(true)
         formIsValid = false;
      }

      if (trip === 'round' && !roundFullAddress && pickUp != 'Airport: Drop off Point' && pickUp != 'Airport: Pickup Point') {
         setErrorRoundCity(true)
         formIsValid = false;
      }

      return formIsValid;
   }

   const paymentHandler = (data) => {
      let price = trip === 'oneWay' ? totalPrice * numOfBooking : totalPrice;
      const options = {
         key: process.env.REACT_APP_RAZOR_PAY_KEY,
         name: "CarterPorter",
         description: "Payment towards Carter",
         currency: "INR",
         amount: price * 100,
         handler: function (res) {
            // setPaymentKey(res.razorpay_payment_id);
            paymentKey = res.razorpay_payment_id;
            console.log(paymentKey, '-----', res)
            console.log(res, 'payment key');
            document.getElementById('staticModalHandler').click();
            if (trip === 'oneWay' && trip !== 'round' && !multiPin && numOfBooking > 1) {
               multiBookingSubmitHandler(data)
            } else {
               !multiPin && trip !== 'round' && oneWaySubmitHandler(data, mainPinCode)
            }
            if (trip === 'round') {
               roundTripSubmitHandler(data)
            }
            if (multiPin) {
               oneWaySubmitHandler(data, mainPinCode)
               multiPinSubmitHandler(data)
            }
         },
         prefill: {
            name: data.name,
            email: data.email,
            contact: mobNum
         },
         notes: {
            address: "note value"
         },
         theme: {
            color: "#001b94"
         }
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
      // e.preventDefault();
   };

   const onSubmitTrackHandler = (newPnr, newPnr1) => {
      setClickOnBookNow([])
      if (trip === 'round') {
         clickOnBookNow.push(`https://smetrics.goindigo.in/b/ss/indigoaviationprodenvnew/1?AQB=1&r=${document.referrer ? encodeURIComponent(document.referrer) : ''}&g=https%3A%2F%2Fwww.6ebagport.carterporter.in%2F&v65=www.6ebagport.carterporter.in%2F&pageName=CarterX%206E%20Bag%20Port%20Landing%20Page&c55=CarterX%206E%20Bag%20Port%20Landing%20Page&v32=${newPnr.toUpperCase()}&pe=lnk_o&pev2=Book Now&AQE=1`)
         clickOnBookNow.push(`https://smetrics.goindigo.in/b/ss/indigoaviationprodenvnew/1?AQB=1&r=${document.referrer ? encodeURIComponent(document.referrer) : ''}&g=https%3A%2F%2Fwww.6ebagport.carterporter.in%2F&v65=www.6ebagport.carterporter.in%2F&pageName=CarterX%206E%20Bag%20Port%20Landing%20Page&c55=CarterX%206E%20Bag%20Port%20Landing%20Page&v32=${newPnr1.toUpperCase()}&pe=lnk_o&pev2=Book Now&AQE=1`)
         setClickOnBookNow([...clickOnBookNow])
      } else {
         clickOnBookNow.push(`https://smetrics.goindigo.in/b/ss/indigoaviationprodenvnew/1?AQB=1&r=${document.referrer ? encodeURIComponent(document.referrer) : ''}&g=https%3A%2F%2Fwww.6ebagport.carterporter.in%2F&v65=www.6ebagport.carterporter.in%2F&pageName=CarterX%206E%20Bag%20Port%20Landing%20Page&c55=CarterX%206E%20Bag%20Port%20Landing%20Page&v32=${newPnr.toUpperCase()}&pe=lnk_o&pev2=Book Now&AQE=1`)
         setClickOnBookNow([...clickOnBookNow])
      }
   }

   const onSubmitHandler = (data) => {
      if (handleValidation(mobNum)) {
         paymentHandler(data)
         onSubmitTrackHandler(data.pnr, data.pnr1)
      }
   }

   const oneWaySubmitHandler = (data, pinCode) => {
      let datetime = slotOne.replace('After', '')
      datetime = datetime.replace('Before', '')
      datetime = datetime.replace('hours', '')
      formSubmitHandler({
         "address_line_1": fullAddress,
         "address_line_2": "",
         "second_address_line_1": roundFullAddress,
         "second_address_line_2": "",
         "second_area": roundArea,
         "area": area,
         "delivery_datetime": (data.pickup == "Airport: Drop off Point" || data.pickup == "Airport: Pickup Point" ? (data.serDate + " " + ((hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes)) : (datetime)),
         "airport_name": data.airport,
         "city_name": data.city,
         "email": data.email,
         "pincode": pinCode,

         "pickup_slot": (data.pickup == "Airport: Drop off Point" || data.pickup == "Airport: Pickup Point" ? "" : find_slot(getValues('slot'))),

         // "pickup_slot": find_slot(getValues('slot')) ? find_slot(getValues('slot')) : "",
         "gst_amount": priceDetails.gst_price,
         "luggage_price": Number(priceDetails.total_luggage_price),
         "total_luggage_price": Math.round(Number(priceDetails.total_luggage_price) + Number(priceDetails.gst_price)),
         "service_type": activeTab === 'departure' ? 1 : 2,
         "travell_passenger_name": data.name,
         "travell_passenger_contact": mobNum,
         "order_date": data.serDate,
         "flight_number": '',
         "pnr_number": data.pnr,
         "country_code": selectedCCOpt,
         "pick_drop_point": pickUp != 'Airport: Drop off Point' && pickUp != 'Airport: Pickup Point' ? 2 : 1,
         "pick_drop_address": pickDropDetails.pick_drop_id ? Number(pickDropDetails.pick_drop_id) : '',
         "airport_slot_time": (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes,
         "system_info": 2,
         "razorpay_pay_id": paymentKey,
         "razorpay_status": "success",
      }, true)
   }

   const roundTripSubmitHandler = (data) => {
      let datetime = slotOne.replace('After', '')
      datetime = datetime.replace('Before', '')
      datetime = datetime.replace('hours', '')
      let dateTime2 = slotTwo.replace('After', '')
      dateTime2 = dateTime2.replace('Before', '')
      dateTime2 = dateTime2.replace('hours', '')
      formSubmitHandler({
         "address_line_1": fullAddress,
         "address_line_2": "",
         "second_address_line_1": roundFullAddress,
         "second_address_line_2": "",
         "second_area": roundArea,
         "area": area,
         "airport_name": data.airport,
         "city_name": data.city,
         "email": data.email,
         "pincode": mainPinCode,
         "delivery_datetime": (data.pickup == "Airport: Drop off Point" || data.pickup == "Airport: Pickup Point" ? (data.serDate + " " + ((hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes)) : (datetime)),

         "pickup_slot": (data.pickup == "Airport: Drop off Point" || data.pickup == "Airport: Pickup Point" ? "" : find_slot(getValues('slot'))),

         // "pickup_slot": find_slot(getValues('slot')) ? find_slot(getValues('slot')) : "",
         "gst_amount": priceDetails.gst_price,
         "luggage_price": Number(priceDetails.total_luggage_price),
         "total_luggage_price": Math.round(Number(priceDetails.total_luggage_price) + Number(priceDetails.gst_price)),
         "service_type": activeTab === 'departure' ? 1 : 2,
         "travell_passenger_name": data.name,
         "travell_passenger_contact": mobNum,
         "order_date": data.serDate,
         "flight_number": '',
         "pnr_number": data.pnr,
         "country_code": selectedCCOpt,
         "pick_drop_point": pickUp != 'Airport: Drop off Point' && pickUp != 'Airport: Pickup Point' ? 2 : 1,
         "pick_drop_address": pickDropDetails.pick_drop_id ? Number(pickDropDetails.pick_drop_id) : '',
         "airport_slot_time": (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes,
         "system_info": 2,
         "razorpay_pay_id": paymentKey,
         "razorpay_status": "success",
      }, false, (activeTab === 'departure' ? process.env.REACT_APP_DEPARTURE_TOKEN : process.env.REACT_APP_ARRIVAL_TOKEN))
      formSubmitHandler({
         "address_line_1": roundFullAddress,
         "address_line_2": "",
         "second_address_line_1": fullAddress,
         "second_address_line_2": "",
         "second_area": area,
         "area": roundArea,
         "delivery_datetime": (data.roundPickUp == "Airport: Drop off Point" || data.roundPickUp == "Airport: Pickup Point" ? (data.serDate1 + " " + ((roundHours < 10 ? '0' : '') + roundHours + ':' + (roundMinutes < 10 ? '0' : '') + roundMinutes)) : (dateTime2)),

         "pickup_slot": (data.roundPickUp == "Airport: Drop off Point" || data.roundPickUp == "Airport: Pickup Point" ? "" : find_slot(getValues('slots1'))),

         // "pickup_slot": find_slot(getValues('slots1')) ? find_slot(getValues('slots1')) : "",
         "airport_name": data.airport1,
         "city_name": data.city1,
         "email": data.email,
         "pincode": mainPinCode1,
         "gst_amount": roundPriceDetails.gst_price,
         "luggage_price": Number(roundPriceDetails.total_luggage_price),
         "total_luggage_price": Math.round(Number(roundPriceDetails.total_luggage_price) + Number(roundPriceDetails.gst_price)),
         "service_type": activeTab === 'departure' ? 2 : 1,
         "travell_passenger_name": data.name,
         "travell_passenger_contact": mobNum,
         "order_date": data.serDate1,
         "flight_number": '',
         "pnr_number": data.pnr1,
         "country_code": selectedCCOpt,
         "pick_drop_point": roundPickUp != 'Airport: Drop off Point' && roundPickUp != 'Airport: Pickup Point' ? 2 : 1,
         "pick_drop_address": roundPickDropDetails.pick_drop_id ? Number(roundPickDropDetails.pick_drop_id) : '',
         // "order_type_str": "Airport Transfer",
         "airport_slot_time": (roundHours < 10 ? '0' : '') + roundHours + ':' + (roundMinutes < 10 ? '0' : '') + roundMinutes,
         "system_info": 2,
         "razorpay_pay_id": paymentKey,
         "razorpay_status": "success",
      }, true, (activeTab === 'arrival' ? process.env.REACT_APP_DEPARTURE_TOKEN : process.env.REACT_APP_ARRIVAL_TOKEN))
   }

   const multiBookingSubmitHandler = (data) => {
      let datetime = slotOne.replace('After', '')
      datetime = datetime.replace('Before', '')
      datetime = datetime.replace('hours', '')
      for (let i = 0; i < numOfBooking; i++) {
         formSubmitHandler({
            "address_line_1": fullAddress,
            "address_line_2": "",
            "second_address_line_1": roundFullAddress,
            "second_address_line_2": "",
            "second_area": roundArea,
            "area": area,
            "airport_name": data.airport,
            "city_name": data.city,
            "email": data.email,
            "pincode": mainPinCode,
            "gst_amount": priceDetails.gst_price,
            "luggage_price": Number(priceDetails.total_luggage_price),
            "total_luggage_price": Math.round(Number(priceDetails.total_luggage_price) + Number(priceDetails.gst_price)),
            "service_type": activeTab === 'departure' ? 1 : 2,
            "travell_passenger_name": data.name,
            "travell_passenger_contact": mobNum,
            "order_date": data.serDate,
            "flight_number": '',
            "pnr_number": data.pnr,
            "airport_slot_time": (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes,
            "pickup_slot": (data.pickup == "Airport: Drop off Point" || data.pickup == "Airport: Pickup Point" ? "" : find_slot(getValues('slot'))),
            "delivery_datetime": (data.pickup == "Airport: Drop off Point" || data.pickup == "Airport: Pickup Point" ? (data.serDate + " " + ((hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes)) : (datetime)),
            "country_code": selectedCCOpt,
            "pick_drop_point": pickUp != 'Airport: Drop off Point' && pickUp != 'Airport: Pickup Point' ? 2 : 1,
            "pick_drop_address": pickDropDetails.pick_drop_id ? Number(pickDropDetails.pick_drop_id) : '',
            "system_info": 2,
            "razorpay_pay_id": paymentKey,
            "razorpay_status": "success",
         }, i === (numOfBooking - 1))
      }
   }

   const multiPinSubmitHandler = (data) => {
      for (let i = 0; i < pinCodes.length; i++) {
         formSubmitHandler({
            "airport_name": data.airport,
            "city_name": data.city,
            "email": data.email,
            "pincode": pinCodes[i],
            "gst_amount": priceDetails.gst_price,
            "luggage_price": Number(priceDetails.total_luggage_price),
            "total_luggage_price": Math.round(Number(priceDetails.total_luggage_price) + Number(priceDetails.gst_price)),
            "service_type": activeTab === 'departure' ? 1 : 2,
            "travell_passenger_name": data.name,
            "travell_passenger_contact": mobNum,
            "order_date": data.serDate,
            "flight_number": '',
            "pnr_number": data.pnr,
            "country_code": selectedCCOpt,
            "pick_drop_point": pickUp != 'Airport: Drop off Point' && pickUp != 'Airport: Pickup Point' ? 2 : 1,
            "pick_drop_address": pickDropDetails.pick_drop_id ? Number(pickDropDetails.pick_drop_id) : '',
         }, i === (pinCodes.length))
      }
   }

   const find_slot = (slot) => {
      let value = slots.filter((res) => { return slot == res.time_description })
      return value[0].id_slots
   }

   function addPinCodesHandler() {
      if (numOfBooking < 4) {
         setNumOfBooking(numOfBooking + 1)
         let newRef = {
            'pinCode': '',
         }
         pinCodes.push(newRef)
         setPinCodes([...pinCodes]);
      }
   }

   function removePinCodesHandler() {
      if (numOfBooking > 1) {
         setNumOfBooking(numOfBooking - 1)
         pinCodes.splice(-1, 1)
         setPinCodes([...pinCodes])
      }
   }


   const setSelectedCityHandler = (cityId) => {
      setPickDropDetails([]);
      setValue('airport', '')
      setValue('pinCode', '')
      setTotalPrice(null)
      // let filterAirPort = airPortMasterList && airPortMasterList.map(data => data.fk_tbl_city_of_operation_region_id === cityId && data)

      // setAirPortOption(_.compact(filterAirPort))
      setMainPinCode(_.find(cityList, ['region_id', cityId]).pin_code)
      let ctyOne = document.getElementById('cityOne').value ? document.getElementById('cityOne').value : ''
      let ctyTwo = trip === 'round' && document.getElementById('roundTripCity').value ? document.getElementById('roundTripCity').value : ''
      if (ctyOne == ctyTwo) {
         toast.info('Pickup City and Delivery City sholuld not same')
         setValue('city', '')
         setValue('airport', '')
      }
      removeAddress()
   }

   const removeAddress = () => {
      setAddressLine('')
      if (pickUp != '' && pickUp != 'Airport: Drop off Point' && pickUp != 'Airport: Pickup Point') {
         document.getElementById('addressLineText').value = ''
      }
      setSelectedAddress({ ...selectedAddress, addressLine2: '', city: '' });
      setFullAddress('')
      setValue('pinCode', '')
   }

   const setSelectedAirportHandler = (pincode) => {

      removeAddress();
      setPickDropDetails([]);
      setValue('pinCode', '')
      setTotalPrice(null)

      let airport_detail = airPortOption.find((res) => { return pincode == res.airport_name_id })
      setMainPinCode(_.find(cityList, ['region_id', airport_detail.fk_tbl_city_of_operation_region_id]).pin_code)

      let cityId = airport_detail.fk_tbl_city_of_operation_region_id
      setValue('city', cityId);

      console.log(getValues('city'))

      pinCodeValidationAndPricing(airport_detail.airport_pincode, 'main')

      if (pickUp == 'Airport: Drop off Point' || pickUp == 'Airport: Pickup Point') {
         document.getElementById('staticModalHandler').click();
         axios.request({
            method: CarterX_API.PICKUP_DROP_ADDRESS.method,
            url: CarterX_API.PICKUP_DROP_ADDRESS.url,
            data: {
               "pickup_type": 1,
               "departure_type": activeTab === 'departure' ? 1 : 2,
               "airport_id": Number(getValues("airport")),
               "delivery_type": 2
            }
         }).then((res) => {
            if (res.data.status && res.data.subscriber_detail.length != 0) {
               setFullAddress(res.data.subscriber_detail[0].pick_drop_address)
               setPickDropDetails(...res.data.subscriber_detail)
            } else {
               setPickDropDetails([]);
            }
            document.getElementById('staticModalHandler').click();
         })
            .catch((error) => {
               console.log(error);
            })
      }


   }

   // const setPinHandler = (value, type, index) => {
   //   if (value) {
   //     pinCodeValidationAndPricing(value, type, index);
   //   }
   // }

   useEffect(() => {
      setTotalPrice('')
      mainPinCode && getPriceHandler(mainPinCode)
   }, [trip, mainPinCode1])

   // useEffect(() => {
   //   mainPinCode && getPriceHandler(mainPinCode)
   // }, [mainPinCode1])




   const pinCodeValidationAndPricing = (value, type, index) => {
      axios.request({
         method: CarterX_API.checkPinCode.method,
         url: CarterX_API.checkPinCode.url,
         data: [{
            "pincode": value,
            "service_type": activeTab === 'departure' ? 2 : 1,        // 1 for arrival, 2 for departure
            "airport_name_id": getValues("airport"),
            "city_id": getValues("city")
         }]
      })
         .then((res) => {
            if (res.data.status) {
               if (type === 'main') {
                  getPriceHandler(value)
               }
            } else {
               toast.info(res.data.message[0])
               if (type === 'main') {
                  setValue('pinCode', '')
               } else {
                  setValue('pinCodes' + index, '')
                  pinCodes[index] = '';
                  setPinCodes([...pinCodes])
               }
            }
         })
         .catch((error) => {
            console.log(error);
         })
   }

   const getPriceHandler = (value) => {
      axios.request({
         method: CarterX_API.getPrice.method,
         url: CarterX_API.getPrice.url,
         headers: { 'token': activeTab === 'departure' ? process.env.REACT_APP_DEPARTURE_TOKEN : process.env.REACT_APP_ARRIVAL_TOKEN },
         data: {
            "transfer_type": 2,
            "airport_name": getValues("airport"),
            "city_name": getValues("city"),
            "state_name": 0,
            "excess_weight_purchased": "no",
            "excess_weight": 0,
            "bag_weight": 0,
            "no_of_units": 1,
            "pincode": value,
            "service_type": activeTab === 'departure' ? 2 : 1,        // 1 for arrival, 2 for departure
         }
      })
         .then((res) => {
            if (res.data.status) {
               let oneWayPrice;
               setPriceDetails(res.data.price_details)
               trip === 'round' ? (oneWayPrice = res.data.price_details) : setTotalPrice(Math.round(Number(res.data.price_details.price_with_gst)))
               if (trip === 'round' && mainPinCode1) {
                  axios.request({
                     method: CarterX_API.getPrice.method,
                     url: CarterX_API.getPrice.url,
                     headers: { 'token': activeTab === 'departure' ? process.env.REACT_APP_DEPARTURE_TOKEN : process.env.REACT_APP_ARRIVAL_TOKEN },
                     data: {
                        "transfer_type": 2,
                        "airport_name": getValues("airport1"),
                        "city_name": getValues("city1"),
                        "state_name": 0,
                        "excess_weight_purchased": "no",
                        "excess_weight": 0,
                        "bag_weight": 0,
                        "no_of_units": 1,
                        "pincode": mainPinCode1,
                        "service_type": activeTab != 'departure' ? 2 : 1,        // 1 for arrival, 2 for departure
                     }
                  }).then((response) => {
                     if (response.data.status) {
                        setRoundPriceDetails(response.data.price_details);
                        let price
                        price = Math.round(Number(response.data.price_details.price_with_gst) + Number(oneWayPrice.price_with_gst))
                        setTotalPrice(price)
                     }
                  }).catch((error) => {
                     console.log(error);
                  })
               }
               // setTotalPrice(Math.round(res.data.price_details && trip === 'oneWay' ? res.data.price_details.price_with_gst : (res.data.price_details.price_with_gst * 2)))
            } else {
               toast.info(res.data.message)
            }
         })
         .catch((error) => {
            console.log(error);
         })
   }

   const formSubmitHandler = (data, redirect, roundToken) => {
      axios.request({
         method: CarterX_API.formSubmit.method,
         url: CarterX_API.formSubmit.url,
         headers: { 'token': trip === 'round' ? roundToken : (activeTab === 'departure' ? process.env.REACT_APP_DEPARTURE_TOKEN : process.env.REACT_APP_ARRIVAL_TOKEN) },
         data: {
            // "order_type": 3,
            "address_line_1": data.address_line_1,
            "address_line_2": "",
            "second_address_line_1": data.second_address_line_1,
            "second_address_line_2": "",
            "second_area": data.second_area,
            "area": data.area,
            'delivery_datetime': data.delivery_datetime,
            "pickup_slot": data.pickup_slot,
            "transfer_type": 2,
            "airport_name": data.airport_name,
            "corporate_type": 4,
            "city_name": data.city_name,
            "state_name": 0,
            "excess_weight_purchased": "no",
            "excess_weight": 0,
            "bag_weight": 0,
            "no_of_units": 1,
            "email": data.email,
            "pincode": data.pincode,
            "items_order": [
               {
                  "bag_type": "bag1",
                  "price": data.luggage_price
               }
            ],
            "gst_amount": data.gst_amount,
            "luggage_price": data.luggage_price,
            "total_luggage_price": data.total_luggage_price,
            "outstation_charge": 0,
            "excess_bag_amount": 0,
            "service_type": data.service_type,          // 1 for arrival, 2 for departure
            // "pickup_slot": 1,
            "travell_passenger_name": data.travell_passenger_name,
            "travell_passenger_contact": data.travell_passenger_contact,
            "pick_drop_spots_type": 1,
            "building_restriction": {
               "0": 5
            },
            "order_date": data.order_date,
            "country_code": "",
            "flight_number": data.flight_number,
            "pnr_number": data.pnr_number,
            "order_type_str": "Airport Transfer",
            "pick_drop_point": data.pick_drop_point,
            "airport_slot_time": data.airport_slot_time,
            "pick_drop_address": data.pick_drop_address,
            "system_info": 2,
            "razorpay_pay_id": paymentKey,
            "razorpay_status": "success",
         }
      })
         .then((res) => {
            async function detailsSetHandler() {
               return orderDetails.push({ 'orderId': res.data.order_number, 'service_type': data.service_type, airportDetails: _.find(airPortMasterList, function (o) { return o.airport_name_id == data.airport_name; }) });
            }

            detailsSetHandler().then(() => {
               localStorage.setItem("orderDetails", JSON.stringify(orderDetails))
               reset();
               setPinCodes([]);
               setTotalPrice(null);
               setNumOfBooking(1)
               setMobNum('')
               if (redirect) {
                  document.getElementById('staticModalHandler').click();
                  history.push('/booking-confirmation');
               }
            });
         })
         .catch((error) => {
            console.log(error);
         })
   }

   const selectedCCOptHandler = (value) => {
      setAllowedNum(value.allowed)
      const newVal = { ...value, label: `+${value.value}` }
      setSelectedCCOpt(newVal)
   }
   console.log(clickOnBookNow);

   //get address by google api

   const { ref } = usePlacesWidget({
      apiKey: "AIzaSyBYZ8y8XMESY-rrKXVXahIbNmOTnvsuIhM",
      onPlaceSelected: (place) => {
         handleAddress(place)
      },
      options: {
         types: []
      }
   });

   const submitAddress = () => {
      let str = document.getElementById('addressLineText').value
      let name = str.split(',')[0]
      if (name && fullAddress != '') {
         setAddressLine((selectedAddress.addressLine2 ? selectedAddress.addressLine2 : '') + ((selectedAddress.addressLine2 ? ' ' : '')) + name + fullAddress)
         setErrorAddressCity(false)
      } else {
         setErrorAddressCity(true)
      }
      document.getElementById('normalAddressForm').classList.remove('show')

   }

   const findSlotValue = (event) => {
      let value = event.target.value
      slots.filter((response) => {
         if (response.time_description == value) {
            activeTab === 'departure' ? selectTimeSlot(response) : selectTimeSlotTwo(response)
         }
      })
   }

   const selectTimeSlot = (value) => {
      var delivery_date
      var a = value.time_description
      var times = value.slot_end_time;
      var timenumber = times.slice(0, 2);
      var serDate1 = serDate
      var timeOnwards
      var afterBefore
      var show_delivery_time
      var show_select_date_one
      var show_select_date_two
      if (value.id_slots === 5) {
         timeOnwards = 14
         timeOnwards = Number(timeOnwards)
         delivery_date = new Date(serDate)
         afterBefore = 'Before'
         show_delivery_time = timeOnwards + ':00 hours'
         setSlotOne(delivery_date.toString().split(" ")[2] + ' ' + delivery_date.toString().split(" ")[1] + ' ' + delivery_date.toString().split(" ")[3] + ' ' + afterBefore + ' ' + show_delivery_time)
      } else if (value.id_slots === 7) {
         timeOnwards = 2
         timeOnwards = Number(timeOnwards)
         show_select_date_one = new Date(serDate)
         show_select_date_one = show_select_date_one.setDate(new Date(serDate).getDate() + 1)
         show_select_date_one = new Date(show_select_date_one)
         show_select_date_two = new Date(serDate)
         show_select_date_two = show_select_date_two.setDate(new Date(serDate).getDate() + 2)
         show_select_date_two = new Date(show_select_date_two)
         delivery_date = new Date(serDate)
         delivery_date = delivery_date.setDate(new Date(serDate).getDate() + 1)
         delivery_date = new Date(delivery_date)
         show_delivery_time = timeOnwards + ':00 hours'
         afterBefore = 'After'
         setSlotOne(delivery_date.toString().split(" ")[2] + ' ' + delivery_date.toString().split(" ")[1] + ' ' + delivery_date.toString().split(" ")[3] + ' ' + afterBefore + ' ' + show_delivery_time)
      } else if (value.id_slots === 9) {
         timeOnwards = 10
         timeOnwards = Number(timeOnwards)
         delivery_date = new Date(serDate)
         delivery_date = delivery_date.setDate(new Date(serDate1).getDate())
         delivery_date = new Date(delivery_date)
         show_delivery_time = timeOnwards + ':00 hours'
         afterBefore = 'After'
         setSlotOne(delivery_date.toString().split(" ")[2] + ' ' + delivery_date.toString().split(" ")[1] + ' ' + delivery_date.toString().split(" ")[3] + ' ' + afterBefore + ' ' + show_delivery_time)
      } else {
         timeOnwards = Number(timenumber)
         timeOnwards = timeOnwards + 2
         timeOnwards = Number(timeOnwards)
         delivery_date = new Date(serDate)
         afterBefore = 'After'
         show_delivery_time = timeOnwards + ':00 hours'
         setSlotOne(delivery_date.toString().split(" ")[2] + ' ' + delivery_date.toString().split(" ")[1] + ' ' + delivery_date.toString().split(" ")[3] + ' ' + afterBefore + ' ' + show_delivery_time)
      }
   }

   const selectTimeSlotTwo = (value) => {
      let delivery_date_Two = ' ';
      let times = value.slot_end_time;
      let timenumber = times.slice(0, 2);
      let timeOnwards;
      let selected_date_for_date_picker1 = serDate;
      let show_delivery_time_two
      let afterBeforeTwo;
      if (value.id_slots === 5) {
         timeOnwards = 15;
         timeOnwards = Number(timeOnwards);
         delivery_date_Two = new Date(selected_date_for_date_picker1);
         delivery_date_Two = delivery_date_Two.setDate(new Date(selected_date_for_date_picker1).getDate() + 1);
         delivery_date_Two = new Date(delivery_date_Two);
         show_delivery_time_two = 14 + ':00 hours';
         afterBeforeTwo = 'Before ';
         setSlotOne(delivery_date_Two.toString().split(" ")[2] + ' ' + delivery_date_Two.toString().split(" ")[1] + ' ' + delivery_date_Two.toString().split(" ")[3] + ' ' + afterBeforeTwo + show_delivery_time_two + ' ')
      } else {
         timeOnwards = Number(timenumber);
         timeOnwards = 15;
         timeOnwards = Number(timeOnwards);
         delivery_date_Two = new Date(selected_date_for_date_picker1);
         delivery_date_Two = new Date(delivery_date_Two).setDate(new Date(selected_date_for_date_picker1).getDate());
         delivery_date_Two = new Date(delivery_date_Two);
         show_delivery_time_two = 23 + ':55 hours';
         afterBeforeTwo = 'Before ';
         setSlotOne(delivery_date_Two.toString().split(" ")[2] + ' ' + delivery_date_Two.toString().split(" ")[1] + ' ' + delivery_date_Two.toString().split(" ")[3] + ' ' + afterBeforeTwo + show_delivery_time_two + ' ')
      }
   }

   const handleAddress = (value) => {
      const city_detail = value.address_components.filter(res => { return res.types[0] == 'locality' })
      const pinCode = value.address_components.filter(res => { return res.types[0] == 'postal_code' })
      const area = value.address_components.filter(res => { return res.types[0] == 'sublocality_level_1' })
      if (area.length != 0 && area[0].long_name) {
         setArea(area[0].long_name)
      }
      if (pinCode.length != 0 && pinCode[0].long_name) { setMainPinCode(pinCode[0].long_name) }
      const selected_city = getValues("city") ? cityList.filter(res => { return res.region_id == getValues("city") }) : undefined
      let city_name = city_detail[0].long_name
      let selected_city_name = getValues("city") ? selected_city[0].region_name : undefined
      // setAddressLine(value.name ? value.name : '' + ', ' + value.formatted_address)
      setFullAddress(value.name ? value.name : '' + ', ' + value.formatted_address)
      if ((selected_city_name == 'Mumbai & Navi Mumbai') && (city_name == 'Navi Mumbai' || city_name == 'Thane' || city_name == 'Mumbai' || city_name == 'Mumbai & Navi Mumbai')) {
         setSelectedAddress({ ...selectedAddress, city: 'Mumbai & Navi Mumbai' });
      }
      else if ((selected_city_name == 'New Delhi & NCR') && (city_name == 'New Delhi' || city_name == 'Noida' || city_name == 'Faridabad' || city_name == 'Ghaziabad' || city_name == 'Gurugram' || city_name == 'New Delhi & NCR')) {
         setSelectedAddress({ ...selectedAddress, city: 'New Delhi' });
      }
      else if ((selected_city_name == 'Hyderabad') && (city_name == 'Telangana' || city_name == 'Hyderabad')) {
         setSelectedAddress({ ...selectedAddress, city: 'Hydrabad' });
      }
      else if ((selected_city_name == 'Bengaluru' || selected_city_name == 'Bangalore') && (city_name == 'Bengaluru' || city_name == 'Bangalore')) {
         setSelectedAddress({ ...selectedAddress, city: 'Bengaluru' });
      }
      else if (!selected_city_name || !city_name) {
         toast.info('city should be same as ' + activeTab + ' city')
         removeAddress()
      }
      else {
         toast.info('city should be same as ' + activeTab + ' city');
         removeAddress()
      }

      // if (area[0].long_name) {
      //   const area2 = (activeTab === 'departure' ? { pickupArea: area[0].long_name } : { deliveryArea: area[0].long_name })
      //   const newVal = { ...selectedAddress, ...area2 }
      //   setSelectedAddress(newVal);
      //   console.log(selectedAddress, 'nfjbsgjdg........', newVal)
      // }
   }


   const setAddressLine2 = (value) => {
      setSelectedAddress({
         addressLine2: value
      });
   }

   const onHandleChange = (event) => {
      const result = event.target.value.replace(/[\"\'~`!@#$%^&()_={}[\]:;,.<>+\/?-]+|\d+|^\s+$/g, '').
         replace(/\s+/ig, ' ');
      setValue('name', result);
   };

   const setSelectedPickupHandler = (e) => {
      setTotalPrice('')
      setValue('city', '')
      setValue('airport', '')
      setFullAddress('')
      setPickUp(e)
      setValue('pickup', e)
      let date
      if (activeTab === 'departure') {
         setHours(new Date().getHours() + 3 > 23 ? (new Date().getHours() + 3 - 24) : new Date().getHours() + 3);
         setRoundHours(new Date().getHours() + 3 > 23 ? (new Date().getHours() + 3 - 24) : new Date().getHours() + 3);
         date = (new Date(year, month, day + ((new Date().getHours() + 3 > 23 || e === 'Doorstep Pickup') ? 1 : 0), 10, 33, 30, 0).toISOString().split("T")[0])
      } else {
         setRoundHours(new Date().getHours() + 3 > 23 ? (new Date().getHours() + 3 - 24) : new Date().getHours() + 3);
         date = (new Date(year, month, day + ((new Date().getHours() + 3 > 23 || e === 'Doorstep Pickup') ? 1 : 0), 10, 33, 30, 0).toISOString().split("T")[0])
      }
      setToday(date);
      setSerDate(date)
      setValue('serDate', date)

   }

   // function increaseDepartureHour() {
   //   if (hours < 23) {
   //     setHours(hours + 1)
   //   }

   // }

   // function decreaseDepartureHour() {
   //   if (hours > 0) {
   //     setHours(hours - 1)
   //   }
   // }

   // function increaseDepartureMin() {
   //   if (minutes < 59) {
   //     setMinutes(minutes + 1)
   //   }

   // }

   // function decreaseDepartureMin() {
   //   if (minutes > 0) {
   //     setMinutes(minutes - 1)
   //   }
   // }

   function openDepartureTimePicker() {
      setTimePickerShow(!timePickerShow)
   }


   function increaseDepartureHour() {
      let dDate = new Date();
      let currentDate = new Date();
         currentDate.setHours(0, 0, 0, 0);
      let tomorrow = new Date(currentDate);
          tomorrow.setDate(tomorrow.getDate() + 1);
      let hour

      if (activeTab === "departure" && pickUp === 'Airport: Drop off Point') {
         if (tomorrow.setHours(0, 0, 0, 0) == new Date(getValues('serDate')).setHours(0, 0, 0, 0)) {
            hour = dDate.getHours() + 0
         }
         // hour = dDate.getHours() + 3
      } else if (activeTab != "departure") {
         if (tomorrow.setHours(0, 0, 0, 0) == new Date(getValues('serDate')).setHours(0, 0, 0, 0)) {
            hour = dDate.getHours()
         } else {
            hour = 0;
         }
      } else {
         hour = 0;
      }

      if (hour > 23) {
         hour = Number(hour) - 24;
      }

      if (hours >= 23) {
         setHours(hour ? hour : 0)
         setMin(hour, hour)
      } else {
         setHours(Number(hours) + 1)
         setMin(Number(hours) + 1, hour)
      }
   }

   function decreaseDepartureHour() {
      let dDate = new Date();
      let currentDate = new Date();
         currentDate.setHours(0, 0, 0, 0);
      let tomorrow = new Date(currentDate);
          tomorrow.setDate(tomorrow.getDate() + 1);
      let hour

      if (activeTab === "departure" && pickUp === 'Airport: Drop off Point') {
         if (tomorrow.setHours(0, 0, 0, 0) == new Date(getValues('serDate')).setHours(0, 0, 0, 0)) {
            hour = dDate.getHours() + 0
         }
      } else if (activeTab != "departure") {
         if (tomorrow.setHours(0, 0, 0, 0) == new Date(getValues('serDate')).setHours(0, 0, 0, 0)) {
            hour = dDate.getHours()
         } else {
            hour = 0;
         }
      } else {
         hour = 0;
      }

      if (hour > 23) {
         hour = Number(hour) - 24;
      }

      if (hours === hour || hour === 0) {
         setHours(23)
         setMin(23, hour)
      } else {
         setHours(Number(hours) - 1)
         setMin(Number(hours) - 1, hour)
      }
   }

   function setMin(hours, hour) {
      if (hours === hour) {
         setMinutes(new Date().getMinutes());
      } else {
         setMinutes(0);
      }
   }

   function increaseDepartureMin() {
      let dDate = new Date();
      let currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      let tomorrow = new Date(currentDate);
      tomorrow.setDate(tomorrow.getDate() + 1);
      let min, hour

      hour = new Date().getHours() + (activeTab === "departure" && pickUp === 'Airport: Drop off Point' ? 0 : 0);
      if (hour > 23) {
         hour = Number(hour) - 24
      }

      if (activeTab === "departure" && pickUp === 'Airport: Drop off Point') {
         if (tomorrow.setHours(0, 0, 0, 0) == new Date(getValues('serDate')).setHours(0, 0, 0, 0) && hours === hour) {
            min = dDate.getMinutes()
         }
      } else if (activeTab != "departure") {
         if (tomorrow.setHours(0, 0, 0, 0) === new Date(getValues('serDate')).setHours(0, 0, 0, 0) && hours === hour) {
            min = dDate.getMinutes()
         } else {
            min = 0;
         }
      } else {
         min = 0;
      }

      if (minutes >= 59) {
         setMinutes(min ? min : 0);
      } else {
         setMinutes(minutes + 1);
      }

   }

   function decreaseDepartureMin() {
      let dDate = new Date();
      let currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      let tomorrow = new Date(currentDate);
      tomorrow.setDate(tomorrow.getDate() + 1);
      let min, hour

      hour = new Date().getHours() + (activeTab === "departure" && pickUp === 'Airport: Drop off Point' ? 0 : 0);
      if (hour > 23) {
         hour = Number(hour) - 24
      }

      if (activeTab === "departure" && pickUp === 'Airport: Drop off Point') {
         if (tomorrow.setHours(0, 0, 0, 0) === new Date(getValues('serDate')).setHours(0, 0, 0, 0) && hours === hour) {
            min = dDate.getMinutes()
         }
      } else if (activeTab != "departure") {
         if (tomorrow.setHours(0, 0, 0, 0) === new Date(getValues('serDate')).setHours(0, 0, 0, 0) && hours === hour) {
            min = dDate.getMinutes()
         } else {
            min = 0;
         }
      } else {
         min = 0;
      }

      if (minutes == min || minutes == 0) {
         setMinutes(59);
      } else {
         setMinutes(minutes - 1);
      }

      // if ( this.minOneway == min || (type == 1 ? this.minOneway : this.minRoundTrip) == 0) {
      //    type == 1 ? (this.minOneway = 59) : (this.minRoundTrip = 59);
      // } else {
      //    type == 1 ? (this.minOneway -= 1) : (this.minRoundTrip -= 1);
      // }
      // type == 1
      //    ? (this.minOneway = Number(this.minOneway))
      //    : (this.minRoundTrip = Number(this.minRoundTrip));
   }





   return (
      <div className="p-3 p-md-0 border borderNone border-top-0">
         <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-md-3">
               <div className="row">
                  <div className="col-12 d-flex mb-4 oneWayRound">
                     <div className="form-check cursorPointer mr-3" onClick={() => setTrip('oneWay')}>
                        <input className={`form-check-input cursorPointer mt6px`} type="radio" checked={trip === 'oneWay'} />
                        <label className={`form-check-label cursorPointer ${trip === 'oneWay' && 'activeRadio'}`}>
                           One Way
                        </label>
                     </div>
                     <div className="form-check cursorPointer" onClick={() => setTrip('round')}>
                        <input className={`form-check-input cursorPointer mt6px ${trip === 'round' && 'activeRadio'}`} type="radio" checked={trip === 'round'} />
                        <label className={`form-check-label cursorPointer ${trip === 'round' && 'activeRadio'}`}>
                           Round Trip
                        </label>
                     </div>
                  </div>
               </div>

               <div className="row gap">

                  <div className="col-md-4">
                     <select id='pickUp' className="form-control mb-0" name="pickup" ref={register({ required: true })} onChange={(e) => setSelectedPickupHandler(e.target.value)}>
                        <option value="" disabled selected>Select Pickup*</option>
                        {
                           (activeTab === 'departure' ? ['Airport: Drop off Point', 'Doorstep Pickup'] :
                              ['Airport: Pickup Point', 'Doorstep Delivery']).map(data => (
                                 <option value={data}>{data}</option>
                              ))
                        }
                     </select>
                  </div>

                  <div className="col-md-4 d-none">
                     <select id='cityOne' className={`form-control mb-0 ${errors.city && 'cusErrors'}`} name="city" ref={register({ required: true })} onChange={(e) => setSelectedCityHandler(e.target.value)}>
                        <option value="" disabled selected>City*</option>
                        {
                           cityList && cityList.length > 0 &&
                           cityList.map(data => (
                              <option value={data.region_id}>{data.region_name}</option>
                           ))
                        }
                     </select>
                     <span className="cusError">{errors.city && 'Please Select City'}</span>
                  </div>

                  <div className="col-md-4">
                     <select className={`form-control mb-0 ${errors.airport && 'cusErrors'}`} name="airport" ref={register({ required: true })} onChange={(e) => setSelectedAirportHandler(e.target.value)}>
                        <option value="" disabled selected>Airport*</option>
                        {
                           airPortOption && airPortOption.length > 0 &&
                           airPortOption.map(data => (
                              <option className={getValues('city1') == data.fk_tbl_city_of_operation_region_id ? 'd-none' : null} value={data.airport_name_id}>{data.airport_name}</option>
                           ))
                        }
                     </select>
                     <span className="cusError">{errors.airport && 'Please Select Airport'}</span>
                  </div>

                  <div className="col-md-4">
                     <div>
                        <input
                           type="date"
                           min={today}
                           placeholder="Select Service Date*"
                           name="serDate"
                           className={`form-control txt-left mb-0 ${errors.serDate && 'cusErrors'}`}
                           ref={register({ required: true })}
                           onChange={(e) => dateValidationHandle(e.target.value)}
                        />
                        <span className="cusError">{cusDateError}</span>
                     </div>
                  </div>

                  <div className="col-md-4">
                     <div className="w-100">
                        <input
                           type="text"
                           placeholder="PNR*"
                           name="pnr"
                           maxLength="6"
                           className={`form-control mb-0 text-uppercase ${errors.pnr && 'cusErrors'}`}
                           ref={register({ required: true, pattern: /^[a-zA-Z0-9]{6,}$/i })}
                        />
                        <span className="cusError">{errors.pnr && 'Enter The Valid PNR'}</span>
                     </div>
                  </div>

                  {
                     pickUp === 'Doorstep Pickup' || pickUp === 'Doorstep Delivery' ?
                        <OneWayGoogleAddress activeTab={activeTab} errorAddressCity={errorAddressCity} addressLine={addressLine} selectedAddress={selectedAddress} submitAddress={submitAddress} handleAddress={handleAddress} setAddressLine2={setAddressLine2} type={1}></OneWayGoogleAddress>
                        :
                        <div className={pickDropDetails.length == 0 ? 'd-none ' : 'col-md-8 d-block'}>
                           <div className='form-control'>
                              {pickDropDetails.length != 0 ? pickDropDetails.pick_drop_address : ''}
                           </div>
                        </div>
                  }


                  {
                     pickUp === 'Doorstep Pickup' || pickUp === 'Doorstep Delivery' ?
                        <div className="col-md-6" >
                           <select name="slot" className={`form-control ${errors.slot && 'cusErrors'}`} id="pickUpSlot"
                              ref={register({ required: true })}
                              onChange={(e) => findSlotValue(e)}>
                              <option value="" disabled selected>{activeTab === 'departure' ? 'Pick up Slot*' : 'Flight Arrival Slot*'}</option>
                              {activeTab === 'departure' ?
                                 slots?.map(data => (
                                    data.slot_type == 0 && data.time_description != null && data.time_description != "" ?
                                       <option value={data.time_description} >
                                          {data.time_description}
                                       </option>
                                       :
                                       null
                                 ))
                                 : filteredTime?.map(data => (
                                    data.slot_type != 0 && data.time_description != null && data.time_description != "" ?
                                       <option value={data.time_description} >
                                          {data.time_description}
                                       </option>
                                       :
                                       null
                                 ))
                              }
                           </select>
                           <span className="cusError">{errors.slot ? activeTab === 'departure' ? 'Please Select Pick up Slot' : 'Please Select Flight Arrival Slot' : ''}</span>
                        </div>
                        :
                        <div className="col-md-4">
                           <div className="dropdown customDropdown">
                              <div className='form-control dropdown-toggle' onClick={() => setTimePickerShow(true)}>
                                 <div>
                                    {hours < 10 ? <span>0</span> : null}{hours} : {minutes < 10 ? <span>0</span> : null}{minutes}
                                 </div>
                              </div>
                              <div className={`dropdown-menu timeToMeet pt-4 ${timePickerShow ? "show" : ""}`}>
                                 <div className="">
                                    <div className='d-flex justify-content-around'>
                                       <div className='text-center'>
                                          <h5 className='timeHeading'>Hour</h5>
                                          <div className='pb-3' onClick={() => increaseDepartureHour()}>
                                             <i className="fa-sharp fa-solid fa-chevron-up"></i>
                                          </div>
                                          <div className='pb-3'>
                                             {hours < 10 ? <span>0</span> : null}{hours}
                                          </div>
                                          <div onClick={() => decreaseDepartureHour()}>
                                             <i className="fa-sharp fa-solid fa-chevron-down"></i>
                                          </div>
                                       </div>
                                       <div className='text-center'>
                                          <h5 className='timeHeading'>Min</h5>
                                          <div className='pb-3' onClick={() => increaseDepartureMin()}>
                                             <i className="fa-sharp fa-solid fa-chevron-up"></i>
                                          </div>
                                          <div className='pb-3'>
                                             {minutes < 10 ? <span>0</span> : null}{minutes}
                                          </div>
                                          <div onClick={() => decreaseDepartureMin()}>
                                             <i className="fa-sharp fa-solid fa-chevron-down"></i>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className='text-center mb-2 mt-4'>
                                    <span className='confirm cursorPointer' onClick={() => openDepartureTimePicker()}>CONFIRM</span>
                                 </div>
                              </div>

                           </div>
                           <div class="message">
                              <span class="mIcon">i</span>
                              <p>
                                 Maximum wait time for Porter allocated is 15 minutes (+/-) after
                                 time entered.
                              </p>
                           </div>
                        </div>


                  }


                  {
                     trip === 'oneWay' &&
                     <>
                        <div className="col-md-4">
                           <div>
                              <p className="text-md-center mb-1">Number of Booking:</p>
                              <div className="numOfBooking d-flex justify-content-start justify-content-md-center align-items-center">
                                 <p className="btn" onClick={() => removePinCodesHandler()}>
                                    <i className="fa fa-minus" aria-hidden="true"></i>
                                 </p>
                                 <h6 className="mb-0 mx-2">{numOfBooking}</h6>
                                 <p className="btn" onClick={() => addPinCodesHandler()}>
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                 </p>
                              </div>
                           </div>
                        </div>
                     </>
                  }
               </div>



               {/* {
            trip === 'oneWay' && multiPin && pinCodes && pinCodes.length > 0 &&
            pinCodes.map((data, i) => (
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <input
                    type="number"
                    placeholder={activeTab === 'departure' ? `Pickup Pin Code #${i + 2}*` : `Delivery Pin Code #${i + 2}*`}
                    name={`pinCodes${i}`}
                    ref={register({ required: true, pattern: /^[1-9][0-9]{5}$/i })}
                    className={`form-control mb-0 ${errors['pinCodes' + i] && 'cusErrors'}`}
                    value={data.pinCode}
                    onChange={(e) => onChangeHandlerForRef(i, e.target.value)}
                  />
                </div>
              </div>
            ))
          } */}
               {
                  trip === 'round' &&
                  <div>
                     <h6 className='pt-4'>{activeTab === 'departure' ? 'Arrival' : 'Departure'} Detail</h6>
                     <RoundTrip activeTab={activeTab} airPortMasterList={airPortMasterList} cityList={cityList} setMainPinCode1={setMainPinCode1} minDate={serDate} setValue={setValue} getValues={getValues} register={register} errors={errors} slots={slots} setSlotTwo={setSlotTwo} selectedAddress={roundAddress} setRoundFullAddress={setRoundFullAddress}
                        setRoundPickDropDetails={setRoundPickDropDetails} roundPickDropDetails={roundPickDropDetails} setRoundPickUp={setRoundPickUp} roundPickUp={roundPickUp} setRoundArea={setRoundArea} setErrorRoundCity={setErrorRoundCity} errorRoundCity={errorRoundCity} setRoundHours={setRoundHours} setRoundMinutes={setRoundMinutes} roundMinutes={roundMinutes} roundHours={roundHours} trip={trip} />
                  </div>
               }
            </div>
            <h6 className='pt-4'>Passenger Detail</h6>
            <div className="row">
               <div className="col-md-7">
                  <div className="row">
                     <div className="col-md-6">
                        <div className="form-group mb-4">
                           <input
                              type="text"
                              placeholder="Name*"
                              name="name"
                              onChange={(event) => onHandleChange(event)}
                              className={`form-control mb-0 ${errors.name && 'cusErrors'}`}
                              ref={register({ required: true })}
                           />
                           <span className="cusError">{errors.name && 'Enter The Valid Name'}</span>
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="form-group mb-4">
                           <input
                              type="text"
                              placeholder="Email*"
                              name="email"
                              className={`form-control mb-0 ${errors.email && 'cusErrors'}`}
                              ref={register({ required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i })}
                           />
                           <span className="cusError">{errors.email && 'Enter The Valid Email'}</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-md-5">
                  <div className="d-flex">
                     <div style={{ width: "35%" }}>
                        <Select placeholder="+91" className="dropdown-style" options={countryCodeList} value={selectedCCOpt} onChange={(e) => selectedCCOptHandler(e)} />
                     </div>
                     <div className="" style={{ width: "65%" }}>
                        <div className="form-group mb-4">
                           <input
                              type="number"
                              placeholder="Mobile Number*"
                              className={`form-control mb-0 ${cusErrors && 'cusErrors'}`}
                              value={mobNum}
                              onChange={(e) => { setMobNum(e.target.value); handleValidation(e.target.value) }}
                           />
                           <span className="cusError">{cusErrors && 'Enter Valid Mobile Number'}</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-12">
                  <p className="mb-1 text-dark">Complete address with pincode will be taken at least 24 hours before date of service. Booking address can be different for each booking as long as they are in the same city of departure/arrival. Address should be in the urban city of departure/arrival.</p>
                  <p className="mb-1">Each PNR can have multiple orders. Add number of bookings to place multiple orders for the same PNR. Maximum number of booking per order is 4 under a single payment window.</p>
                  <p className="mb-1">Up to 2 bags allowed per booking for CarterX Service. For departure bookings, excess weight and additional bag charges are as per airline mandate.</p>
                  <div className="d-flex align-items-center flex-wrap">
                     {/* {
                trip === 'oneWay' ?
                  activeTab === 'departure' ?
                    <NavLink target="_blank" to='/departure-details' className="fs12" activeClassName='activeMenu'>Airport Transfer Booking Information</NavLink>
                    :
                    <NavLink target="_blank" to='/arrival-details' className="fs12" activeClassName='activeMenu'>Airport Transfer Booking Information</NavLink>
                  :
                  <>
                    <NavLink target="_blank" to='/departure-details' className="fs12" activeClassName='activeMenu'>Airport Transfer Booking Information</NavLink>
                    <span className="mx-2 text-secondary">•</span>
                    <NavLink target="_blank" to='/arrival-details' className="fs12" activeClassName='activeMenu'>Arrival Booking Information</NavLink>
                  </>
              } */}
                     <NavLink target="_blank" to='/booking-information' className="fs12" activeClassName='activeMenu'>Airport Transfer Booking Information</NavLink>
                     <span className="mx-2 text-secondary">•</span>
                     <NavLink target="_blank" to='/cancellation-and-refund-policy' className="fs12" activeClassName='activeMenu'>Cancellation and Refund Policy</NavLink>
                  </div>
                  <div className="form-group form-check mt-4">
                     <input type="checkbox" className="form-check-input" name="agree" ref={register({ required: true })} id="checkBox" />
                     <label className="form-check-label" for="checkBox">I am above 18 years and Agree to<NavLink target="_blank" to='/terms-and-conditions' activeClassName='activeMenu'> Terms & Conditions</NavLink> of IndiGo 6EBagPort CarterPorter</label>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-4">
                     {/* {
                totalPrice &&
                <span className="amountText mr-3">₹<span className="ml-1">{trip === 'oneWay' ? totalPrice * numOfBooking : (totalPrice * 2) - (8 / 100) * (totalPrice * 2)}</span></span>
              } */}
                     {/* {
                totalPrice &&
                <span className="amountText mr-3">₹<span className="ml-1">{trip === 'oneWay' ? totalPrice * numOfBooking : totalPrice * 2}</span></span>
              } */}
                     <div>
                        <div>
                           <p className='timeLine'>{slotOne != '' ? activeTab === 'departure' ? 'Departure Delivery Timeline: ' : 'Arrival Delivery Timeline: ' : ''}</p>
                           <p className='timeLineTime'>{slotOne}</p>
                        </div>
                        <div>
                           <p className='timeLine'>{slotTwo != '' ? activeTab != 'departure' ? 'Departure Delivery Timeline: ' : 'Arrival Delivery Timeline: ' : ''}</p>
                           <p className='timeLineTime'>{slotTwo}</p>
                        </div>
                     </div>
                     <div>
                        {
                           totalPrice &&
                           <span className="amountText mr-3">₹<span className="ml-1">{trip === 'oneWay' ? totalPrice * numOfBooking : totalPrice}</span></span>
                        }
                        {/* <h1>{Object.keys(errors).length}{totalPrice}</h1> */}
                        {/* {roundFullAddress}1
                {fullAddress}2
                {errorRoundCity}3
                {errorAddressCity}4 */}
                        <button type="submit" className={`submitBtn`} disabled={Object.keys(errors).length > 0 || !totalPrice} onClick={() => handleValidation(mobNum)}>Book Now</button>
                     </div>
                  </div>
               </div>
            </div>
         </form>
         <button type="button" className="d-none" id="staticModalHandler" data-toggle="modal" data-target="#staticBackdrop" />
         <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
               <div className="w-100 text-center">
                  <img src={loaderRipple} alt="loaderRipple" style={{ width: '6rem', borderRadius: '50%' }} />
               </div>
            </div>
         </div>
         {
            clickOnBookNow?.map(data => (
               <img src={data} height='1' width='1' border='0' style={{ display: 'none' }} />
            ))
         }
      </div>
   )
}