import React from 'react';
import Indigo from 'assets/images/indigo-logo-copy-9.png';
import Carterx from 'assets/images/carterx-logo.png';
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <div className="mainHeader p-3 d-flex align-items-center justify-content-between border-bottom">
      <div>
        <a href='/'>
          <img className="logo" src={Indigo} alt="Indigo" />
        </a>
      </div>
      <div className="d-flex align-items-center">
        <a href='https://carterporter.in' target="_blank" rel="noopener noreferrer" className="mr-3">
          <img className="logo1" src={Carterx} alt="Carterx" />
        </a>
        <a href="https://carterporter.in/" target="_blank" rel="noopener noreferrer" className="d-none d-md-block">
          <button className="cusBtn">Track My Order</button>
        </a>
        <div className="d-flex d-md-none">
          <i className="fa fa-ellipsis-v ellipsisStyle" aria-hidden="true" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a className="dropdown-item" target="_blank" href="https://carterporter.in/">Track My Order</a>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Header;
