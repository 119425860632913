import React, { useEffect, useState } from 'react';
import TripForm from './TripForm';
import { CarterX_API } from 'api/api';
import axios from 'utils/axios';

export default function Form(props) {
  const { activeTab, setActiveTab } = props;
  const cityList = [{ "id": 1, "region_name": "Bangalore", "region_id": '1', "pin_code": 560300 }, { "id": 3, "region_name": "Mumbai & Navi Mumbai", "region_id": '3', "pin_code": 400099 }, { "id": 2, "region_name": "Hyderabad", "region_id": '2', "pin_code": 501218 }, { "id": 5, "region_name": "New Delhi & NCR", "region_id": '5', "pin_code": 110037 }]
  const [countryCodeList, setCountryCodeList] = useState(null);
  const [airPortMasterList, setAirPortMasterList] = useState(null);

  useEffect(() => {
    getCityListHandler()
    getCountryCodeListHandler()
  }, [])

  const getCityListHandler = () => {
    axios.request({
      method: CarterX_API.getCityList.method,
      url: CarterX_API.getCityList.url
    })
      .then((response) => {
        if (response && response.data.response) {
          // setCityList(response.data.response.region)
          setAirPortMasterList(response.data.response.airport)

        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const getCountryCodeListHandler = () => {
    axios.request({
      method: CarterX_API.getCountryCodeList.method,
      url: CarterX_API.getCountryCodeList.url
    })
      .then((response) => {

        let options = response.data.codes.map(function (code) {
          return { value: code.country_code, label: '+' + code.country_code + ' - ' + code.country_name, allowed: code.allowed_numbers, };
        })

        setCountryCodeList([...options])
      })
      .catch((error) => {
        console.log(error);
      })
  }

  return (
    <div className="formSection p-3 p-md-4">
      <div className="d-none d-md-flex">
        <p className={`mb-0 px-3 tabs ${activeTab === 'departure' && 'active'}`} onClick={() => setActiveTab('departure')}>Departure</p>
        <p className={`mb-0 px-3 tabs ${activeTab === 'arrival' && 'active'}`} onClick={() => setActiveTab('arrival')}>Arrival</p>
      </div>
      <ul className="nav nav-tabs d-flex d-md-none justify-content-around">
        <li className="nav-item w-50 text-center" onClick={() => setActiveTab('departure')}>
          <p className={`nav-link ${activeTab === 'departure' && 'active'}`}>Departure</p>
        </li>
        <li className="nav-item w-50 text-center" onClick={() => setActiveTab('arrival')}>
          <p className={`nav-link ${activeTab === 'arrival' && 'active'}`}>Arrival</p>
        </li>
      </ul>
      {/* <hr className="m-0" /> */}
      <div>
        {
          activeTab == 'departure' &&
          <TripForm activeTab={'departure'} cityList={cityList} countryCodeList={countryCodeList} airPortMasterList={airPortMasterList} />
        }
        {activeTab == 'arrival' && <TripForm activeTab={'arrival'} cityList={cityList} countryCodeList={countryCodeList} airPortMasterList={airPortMasterList} />
        }
      </div>
    </div>
  )
}
