import React, { useEffect, useState } from 'react';
import orderConfirmation from 'assets/images/order-confirmation.png';
import baggageBelt from 'assets/images/baggage-belt-after-arrival.png';
import contactlessPickup from 'assets/images/contactless-pickup.png';
import baggageSanitization from 'assets/images/baggage-sanitization.png';
import vehicleDispatch from 'assets/images/vehicle-dispatch.png';
import contactlessDelivery from 'assets/images/contactless-delivery.png';
import images1 from 'assets/images/1.png';
import images2 from 'assets/images/2.png';
import images3 from 'assets/images/3.png';
import images5 from 'assets/images/5.png';
import images6 from 'assets/images/6.png';


function forArrival() {
  return (
    <div className="row justify-content-center processSection">
      <div className="col-12">
        <div className="row">
          <div className="col-md-4">
            <div className="cusCard mb-4">
              <img src={orderConfirmation} alt="cusCardImg" className="img-fluid" />
              <h6 className="mt-2">Order Conﬁrmation</h6>
              <p className="mb-0">Complete address will be taken pre dispatch. Pin code cannot be changed after conﬁrmation.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="cusCard mb-4">
              <img src={baggageBelt} alt="cusCardImg" className="img-fluid" />
              <h6 className="mt-2">Baggage Belt after Arrival</h6>
              <p className="mb-0">Meet of CarterX personnel is MANDATORY. Documents transferred contactless at allocated baggage belt.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="cusCard mb-4">
              <img src={contactlessPickup} alt="cusCardImg" className="img-fluid" />
              <h6 className="mt-2">Contactless Pickup</h6>
              <p className="mb-0">All luggage confirmation visuals will be sent via communication for confirmation.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="cusCard mb-4">
              <img src={baggageSanitization} alt="cusCardImg" className="img-fluid" />
              <h6 className="mt-2">Baggage Sanitization</h6>
              <p className="mb-0">Each bag is packed 2 times (2 shield packing) to ensure lesser exposure after sanitization.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="cusCard mb-4">
              <img src={vehicleDispatch} alt="cusCardImg" className="img-fluid" />
              <h6 className="mt-2">Vehicle Disptach</h6>
              <p className="mb-0">Slot based delivery service. All bags are loaded into sanitized vehicles.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="cusCard mb-4">
              <img src={contactlessDelivery} alt="cusCardImg" className="img-fluid" />
              <h6 className="mt-2">Contactless Delivery</h6>
              <p className="mb-0">Luggage is delivered contactless with documents exchanged digitally.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function forDeparture() {
  return (
    <div className="row justify-content-center processSection">
      <div className="col-12">
        <div className="row">
          <div className="col-md-4">
            <div className="cusCard mb-4">
              <img src={images1} alt="cusCardImg" className="img-fluid" />
              <h6 className="mt-2">Order Conﬁrmation</h6>
              <p className="mb-0">Select Departure city, Airport terminal, pin code and proceed with price to make payment. Slot Selection is compulsory to proceed to payment. Complete address will be taken pre pickup. Pin code cannot be changed after confirmation. SMS and Email will be sent on Order Confirmatio</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="cusCard mb-4">
              <img src={images2} alt="cusCardImg" className="img-fluid" />
              <h6 className="mt-2">Security Declaration</h6>
              <p className="mb-0">Mandatory paperwork and confirmations will be taken before the date of pickup and service. Security declaration is MANDATORY. Pick up Safety first protocols will be briefed and advised. Complete address is updated on order#. CarterX support is 24/7 on email/call</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="cusCard mb-4">
              <img src={images3} alt="cusCardImg" className="img-fluid" />
              <h6 className="mt-2">Contactless Pickup</h6>
              <p className="mb-0">Order# open SMS is sent with CarterX personnel’s name and phone number with vehicle tracking link before the slot start time. CarterX personnel will sanitize, pack to reduce infection exposure and pick up baggage(s) outside your doorstep. Security and Mandatory documents are transferred contactless</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="cusCard mb-4">
              <img src={baggageSanitization} alt="cusCardImg" className="img-fluid" />
              <h6 className="mt-2">Vehicular Safety</h6>
              <p className="mb-0">Each bag is then packed 2 times over (2 shield packing). All bags are brought to the sanitized vehicle and loaded. SMS is sent post Pick up. Bags will arrive the airport based on the slot selected for pickup</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="cusCard mb-4">
              <img src={images5} alt="cusCardImg" className="img-fluid" />
              <h6 className="mt-2">At-terminal Enterance</h6>
              <p className="mb-0">Meet of CarterX Delivery personnel is MANDATORY before entering the terminal. Entry without passenger for CarterX personnel with bags is NOT PERMISSIBLE. CarterX personnel's name and number SMS will be sent prior for easy identification</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="cusCard mb-4">
              <img src={images6} alt="cusCardImg" className="img-fluid" />
              <h6 className="mt-2">Contactless Delivery</h6>
              <p className="mb-0">Baggage is delivered by CarterX personnel to passenger at baggage drop off Queue of the Check-in Counter. Baggage will be delivered with the 1st shield packing to aide reduction and exposure of infection. Order delivered SMS and Email will be sent</p>
            </div>
          </div>



        </div>
      </div>
    </div>
  )
}

export default function Process(props) {
  const [activeTab, setActiveTab] = useState(props.activeTabTop);

  useEffect(() => {
    setActiveTab(props.activeTabTop)
  }, [props.activeTabTop])

  return (
    <div className="row justify-content-center">
      <div className="col-md-10">
      <h1 className="title border-bottom mb-4 pt-3 pb-2 text-center text-md-left">Process for Airport Transfers</h1>
      <div className="d-flex cusTabs">
        <p className={`px - 4 pb-2 mb-4 ${activeTab === 'departure' && 'active'}`} onClick={() => setActiveTab('departure')}>For Departure</p>
        <p className={`px - 4 pb-2 mb-4 ${activeTab === 'arrival' && 'active'}`} onClick={() => setActiveTab('arrival')}>For Arrival</p>
      </div>
      {
        activeTab === 'arrival' ?
          forArrival()
          :
          forDeparture()
      }
      </div>
      </div>
  )
}