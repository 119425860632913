import React from 'react'

export default function DepartureBookingInfo() {
  return (
    <div className='container'>
      <div className='row'>
        <div class="col-12 mt-5">
          <h4 class="text-center fw6">Booking Information</h4>
          {/* <div class="container video departure-card">
        <p class="mt-5">
          <span className="font-weight-bold">Mandatory:</span>
        </p>
        <div className="cuspP">
          <div className="cuspP">
            <div><span>Passenger must certify status of
            his/her health through the Aarogya Setu App. Green/Safe is mandatory for pick up &nbsp;of order&nbsp;</span>
            </div>
            <div><span>Departing passenger needs to meet
            CarterX personnel before entering the terminal</span></div>
          </div>
          <div className="cuspP">
            <span>Passenger must have completed his/her Web Check-in prior to the pick up slot
          time</span></div>
          <div className="cuspP">
            <span>Passenger ID proof is mandatory at the time of pick up and will be validated on
          delivery before entering the terminal</span></div>
          <div className="cuspP">
            <span>Security Declaration must be duly filled and signed</span></div>
          <div className="cuspP">
            <span>The Passenger must arrive at the airport at least<span>&nbsp;</span><strong>120</strong><span>&nbsp;</span>minutes prior
          to departure</span></div>
          <div className="cuspP">
            <span>Multiple bookings can be made under a single PNR</span></div>
          <div className="cuspP">
            <span>Charges for excess number of bags will be collected.</span></div>
          <div className="cuspP">
            <span>Maximum dimension of each bag cannot exceed 319 linear cms ( 81cm (H) x 119cm (W) x
          119 cm (D) ). <span>Maximum weight can be up to 32/kgs per
            piece. Excess weight will be have to be purchased for any order beyond 20kgs.</span></span></div>
          <div className="cuspP">
            <span>Excess weight should be purchased prior or during the time of pickup. <span>Visit&nbsp;</span><a href="https://www.goindigo.in/" rel="noopener noreferrer"
              target="_blank">https://www.goindigo.in</a></span></div>
          <div className="cuspP">
            Bags cannot be added/subtracted once booking is confirmed. Up to 2 bags/order only allowed</div>
          <div className="cuspP">
            <span>Booking pick up address can be modified upto 24 hours prior&nbsp;</span></div>
          <div className="cuspP">
            <span>Departure bookings are accepted upto 24 hours prior to departure</span></div>
          <div className="cuspP">
            <span>Upto 2 bags are accepted with each booking. Both bags together should be under 20Kgs
          else excess weight will have to be purchased</span></div>
          <div className="cuspP">
            <span>Customer Care Bookings are confirmed post payment</span></div>
          <div className="cuspP">
            <span><br /></span></div>
          <div className="cuspP">
            <span>CarterX personnel will call prior to allocate Suitable Pick up Slot Time and complete the address from the given pin code</span></div>
          <div className="cuspP">
            -7am &ndash; 11 am | Order available at Airport after 2pm</div>
          <div className="cuspP">
            -11 am &ndash; 3 pm |Order available at Airport after 6pm</div>
          <div className="cuspP">
            -3 pm &ndash; 7 pm | Order available at Airport after 10pm</div>
          <div className="cuspP">
            -7 pm &ndash; 12 am | Order available at Airport after 3am</div>
          <div className="cuspP">
            -4 am &ndash; 7 am | Order available at Airport after 10am</div>
          <div className="cuspP">
            <br /></div>
          <div className="cuspP">
            Orders from containment zones will not be collected as per government regulations&nbsp;</div>
          <div className="cuspP">
            No pick up during curfew times imposed by the government</div>
          <div className="cuspP">
            <span>All bags of each order will be picked at the doorstep to minimise exposure.
            Passenger/household personnel should continue to wear mask while interacting with CarterX personnel at pickup
          location and the airport&nbsp;</span></div>
        </div>
      </div> */}

          <div class="infoParagraph mt-5">
            <p class="heading">Departure:<span> Bags will be delivered till the check-in counters</span></p>
            <p class="heading mb-3">Arrival:<span> Bags will be collected at Baggage Belts</span></p>
            <p>Doorstep and Curbside pick up and delivery both are available.</p>
            <p>Curbside includes to or from airport parking
              or cab drop off points and pick up lanes of the airport terminal only.</p>
            <p>Departing passenger needs to meet CarterX personnel before entering the terminal</p>
            <p>Arriving Customer needs to meet CarterX personal before exiting the terminal</p>
            <p>Passenger must have completed his/her Web Check-in prior to the pick up slot time</p>
            <p>Passenger ID proof is mandatory at the time of pick up and will be validated on delivery before entering the
              terminal</p>
            <p>Security Declaration must be duly filled and signed for doorstep pick up. </p>
            <p>The Passenger must arrive at the airport at least 120 minutes prior to departure for both doorstep and
              curbisde pickup.</p>
            <p>Multiple bookings can be made under a single PNR</p>
            <p>Charges for excess number of bags will be collected by the airline as per the airline directive.</p>
            <p>We advice that excess weight be purchased on airline website for shorter wait times at counters.</p>
            <p>Maximum dimension of each bag cannot exceed 319 linear cms ( 81cm (H) x 119cm (W) x 119 cm (D) ).</p>
            <p>Maximum weight can be up to 32/kgs per piece as per CarterX carriage and is not indicative of airline weight
              limitations or excess weight.</p>
            <p>Excess weight should be purchased prior or during the time of pickup from the airline website at doorstep for
              smoother pick up and delivery.</p>
            <p>Bags cannot be added/subtracted once booking is confirmed. </p>
            <p>Booking pick up address can be modified upto 24 hours prior </p>
            <p>Doorstep Departure bookings are accepted upto 24 hours prior to departure.</p>
            <p>Curbside departure can be booked and availed up to 3 hours prior to meeting carterX at airport.</p>
            <p>Customer Care Bookings are confirmed post payment</p>
            <p>All electronic items/oversized will be charged as Fragile with INR 399 per Kg as order modification piece.
            </p>
            <p>All electronics should be in their original packing only</p>
            <p>Booking pick up address will be updated post the booking and can be modified upto 24 hours prior for local
              orders only</p>
            <p>Keep bags packed before porter arrives.</p>
            <p>CarterX personnel will call prior to allocate Suitable Pick up Slot Time and complete the
              address from the given pin code</p>
            <p>All orders will be delivered only on receipt of full payment of order and order modification</p>
            <p class="mt-3 heading">Departure Slots for local orders Doorstep Pick Up:</p>
            <p>-07 am – 11 am | Order available at Airport after 02 pm</p>
            <p>-11 am – 03 pm | Order available at Airport after 06 pm</p>
            <p>-03 pm – 07 pm | Order available at Airport after 10 pm</p>
            <p>-07 pm – 12 am | Order available at Airport after 03 am</p>
            <p>-04 am – 07 am | Order available at Airport after 10 am</p>
            <p class="mt-3 heading">Arrival Slots for Local Orders Doorstep Delivery:</p>
            <p>Arrival Before 3 pm : Order will be delivered on the same day before 11:55 pm</p>
            <p>Arrival After 3 pm : Order will be delivered on the next day before 2 pm</p>
            <p class="mt-3 heading">Arrival Cancellation and Refund: </p>
            <p>Doorstep Delivery: Cancellation any time </p>
            <p>100% Refund 24 hours before time to meet CarterX at Airport for doorstep bookings</p>
            <p>100% Refund 3 hours before time to meet CarterX at Airport for curbside</p>
            <p class="mt-3 heading">Departure Cancellation and Refund: </p>
            <p>Doorstep Delivery: Cancellation any time </p>
            <p>100% Refund 24 hours before time to meet CarterX at Airport for doorstep bookings</p>
            <p>100% Refund 3 hours before time to meet CarterX at Airport for curbside</p>
          </div>
        </div>
      </div>
    </div>
  )
}