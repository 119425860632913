import React from 'react';
import PlayStore from 'assets/images/Play-Store.svg';
import AppStore from 'assets/images/App-Store.png';
import phone from 'assets/icons/Mobile No.png';
import email from 'assets/icons/email_Dark.png';
import indigoFlight from 'assets/images/indigo-logo@2x.svg';

const Footer = () => {
  return (
    <div className="container-fluid">
      <div className="row justify-content-center footerSection">
        <div className="col-11 col-md-10">
          <hr className="my-5" />
          <div className="row">
            <div className="col-12">
              <p className="mb-1 mt-3 mb-4 footerDisclaimer"><span className="text-dark">Disclaimer:</span> IndiGo is facilitating the provision of baggage services for passengers at certain eligible airports through Carter Porter Private Limited (“CarterX”). Passengers may avail of such services through CarterX’s platform www.6EBagPort.carterporter.in. IndiGo is not endorsing or making any representation in relation to the services provided by CarterX. All customer grievances in relation to CarterX’s services will be addressed directly by CarterX.”</p>
            </div>
            <div className="col-md-4 col-lg-3 col-sm-12">
              <ul className="mb-0">
                <li><a href="/faqs" target="_blank" className="footerText">Frequently Asked Question</a></li>
                <li><a href="/terms-and-conditions" target="_blank" className="footerText">Terms and Conditions</a></li>
              </ul>
            </div>
            <div className="col-md-4 col-lg-3 col-sm-12">
              <ul>
                {/* <li><a href="/arrival-details" target="_blank" className="footerText">Arrival Booking Information</a></li>
                <li><a href="/departure-details" target="_blank" className="footerText">Departure Booking Information</a></li> */}
                <li><a href="/booking-information" target="_blank" className="footerText">Airport Transfer Booking Information</a></li>
                <li><a href="/cancellation-and-refund-policy" target="_blank" className="footerText">Cancellation and Refund Policy</a></li>
              </ul>
            </div>
            <div className="col-md-4 col-lg-3 col-sm-12">
              <ul>
                <li className='d-none'><img src={phone} className="pr-2 " /><a href="tel:+91 6366501115" className="footerText">+91 6366501115</a></li>
                <li><img src={email} className="pr-2 " /><a href="mailto:6ebagport@carterporter.in" className="footerText">6ebagport@carterporter.in</a></li>
              </ul>
            </div>
            <div className="col-md-12 col-lg-3 col-sm-12 text-md-center">
              <p className="mb-0">Contact with Us</p>
              <div className="socialIcons">
                <a className="pr-2 mr-2" href="https://www.facebook.com/goindigo.in?linkNav=goindigo.in" target="_blank" rel="noopener noreferrer">
                  <i className="fa fa-facebook" aria-hidden="true"></i>
                </a>
                <a className="pr-2 mr-2" href="https://twitter.com/IndiGo6E" target="_blank" rel="noopener noreferrer">
                  <i className="fa fa-twitter" aria-hidden="true"></i>
                </a>
                <a className="pr-2 mr-2" href="https://www.instagram.com/indigo.6e" target="_blank" rel="noopener noreferrer">
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                </a>
              </div>
              {/* <div className="download">
                <p className="mt-4">Download</p>
                <img src={PlayStore} alt="PlayStore" className="img-fluid" />
                <img src={AppStore} alt="PlayStore" className="img-fluid mt-2" />
              </div> */}
              {/* <div className="ourAwards">
                <p className="mt-4">Our Awards</p> */}
              {/* <img src={PlayStore} alt="PlayStore" className="img-fluid" />
                <img src={AppStore} alt="PlayStore" className="img-fluid mt-2" /> */}
              {/* </div> */}
            </div>
          </div>
          <div className="py-4">
            <h6 className="copyright"><span className="-Copyright-2021-Ind">Ⓒ </span>Copyright 2023 Carterporter. All rights reserved.</h6>
          </div>
        </div>
      </div>
    </div>
  )
}
// test
export default Footer;