import React from 'react';
import webCheckIn from 'assets/images/web-check-in.png';
import faceMask from 'assets/images/face-mask.png';
import Contactless from 'assets/icons/Contactless-delivery.png';
import aarogyaSetu from 'assets/images/ic-aarogya-setu-copy-3.jpg';
import Shield from 'assets/images/ic-shileld-copy-3.svg';
import Screened from 'assets/images/ic-screened-copy-3.svg';
import pickup from 'assets/images/ic-picup-time-copy-3.svg';
import Cancellations from 'assets/images/ic-cancellations-copy-3.svg';
import Sanitized from 'assets/images/ic-sanitized-van-copy-3.svg';
import Support from 'assets/images/ic-24-hrs-copy-3.svg';

export default function SafetyFirst() {
  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-10">
          <div className="row mt-5 mb-4 mt-md-0 mb-md-0">
            <div className="col-md-12" >
              <h3 className="title border-bottom pb-2 text-center text-md-left">Safety First</h3>
              <p className="desc text-center text-md-left">Ease fear of your Exposure, Your bags are protected </p>
            </div>
            <div className="col-md-3 mt-2 mt-md-0">
              <div className="row">
                <div className="col-6 feature-heading">
                  <img src={Contactless} />
                  <p>Contactless Process</p>
                </div>
                <div className="col-6 feature-heading">
                  <img className="imgSiz" src={aarogyaSetu} />
                  <p>Aarogya Setu Safe</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-2 mt-md-0">
              <div className="row">
                <div className="col-6 feature-heading">
                  <img src={Shield} className="imgSiz" />
                  <p>2 Shield Packing</p>
                </div>
                <div className="col-6 feature-heading">
                  <img src={Screened} className="imgSiz" />
                  <p>Screened Personnel</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-2 mt-md-0">
              <div className="row">
                <div className="col-6 feature-heading">
                  <img src={pickup} className="imgSiz" />
                  <p>Slot Based Delivery</p>
                </div>
                <div className="col-6 feature-heading">
                  <img src={Sanitized} className="imgSiz" />
                  <p>Sanitized Van</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-2 mt-md-0">
              <div className="row">
                <div className="col-6 feature-heading">
                  <img src={Cancellations} className="imgSiz" />
                  <p>Free Cancellations 48 hours prior</p>
                </div>
                <div className="col-6 feature-heading">
                  <img src={Support} className="imgSiz" />
                  <p>24/7 Support (post booking)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

