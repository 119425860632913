import React, { useState, useEffect } from 'react';
import Loader from 'components/Loader';

export default function PageNotFound() {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => { setLoading(false) }, 2000);
  }, [])

  return (
    loading ?
      <Loader />
      :
      <section className="page_404">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="d-flex align-items-center justify-content-center h_100vh">
                <h1>404</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}