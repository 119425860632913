import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import history from '../history';
import { routeConfig } from './RouteConfig';
import PageNotFound from 'components/PageNotFound';

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        {
          ((self) => {
            let routeArray = [];
            // eslint-disable-next-line
            routeConfig.map((route, i) => {
              if (route.layout) {
                routeArray.push(
                  <RouteWithLayout exact key={i} path={route.path} layout={route.layout} component={route.component} />
                )
              }
            })
            return routeArray;
          })(this)
        }
        <Route path='*' exact={true} component={PageNotFound} />
      </Switch>
    </Router>
  )
}

const RouteWithLayout = ({ layout, component, ...rest }) => {
  return (
    <Route {...rest} render={(props) =>
      React.createElement(layout, props, React.createElement(component, props))
    } />
  );
}

export default Routes;