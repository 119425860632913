import React, { useState, useEffect } from 'react';
import NeedHelp from 'assets/images/Need-Help.png';
import { NavLink } from "react-router-dom";

export default function BookingConfirmation() {
  const [orderDetails, setOrderDetails] = useState(JSON.parse(localStorage.getItem("orderDetails")))

  useEffect(() => {
    setOrderDetails(JSON.parse(localStorage.getItem("orderDetails")))
  }, [orderDetails]);

  function airportDisplayHandler(data) {
    var airportFullDetails = []
    const airportDescShow = data.airportDetails && data.airportDetails.description && data.airportDetails.description.split("&");
    airportDescShow && airportDescShow.forEach(airport => {
      const airportContent = airport.split(":");
      airportFullDetails.push(airportContent);
    })

    return (
      <div className="my-3">
        <p className="fw6">Your Order Number: <span className="orderNo">{data.orderId}</span></p>
        {
          airportFullDetails && airportFullDetails.length > 0 &&
          airportFullDetails.map(detail => (
            <div className="information">
              <h6 className="fw6 mb-0">{detail[0]}</h6>
              <p>{detail[1]}</p>
            </div>
          ))
        }
        <div className="d-flex align-items-center flex-wrap">
          <div className="bodyFooter d-flex text-center">
            <div className="pt-3 pr-4">
              <i class="fa fa-print" aria-hidden="true"></i>
              <p>Print</p>
            </div>
            <div className="pt-3 pr-4">
              <i class="fa fa-share-alt-square" aria-hidden="true"></i>
              <p>Share</p>
            </div>
          </div>
          <div className="mt-0 mt-md-4 d-flex flex-wrap">
            {/* {
              data.service_type === 1 ?
                <NavLink target="_blank" to='/departure-details' className="fs12 mr-2" activeClassName='activeMenu'>Airport Transfer Booking Information</NavLink>
                :
                <NavLink target="_blank" to='/arrival-details' className="fs12 mr-2" activeClassName='activeMenu'>Airport Transfer Booking Information</NavLink>
            } */}
            <NavLink target="_blank" to='/booking-information' className="fs12 mr-2" activeClassName='activeMenu'>Airport Transfer Booking Information</NavLink>
            <NavLink target="_blank" to='/cancellation-and-refund-policy' className="fs12" activeClassName='activeMenu'>Cancellation and refund policy</NavLink>
          </div>
        </div>
      </div>
    )
  }

  return (
    <section className="bookingConfirmation">
      <div className="banner">
        <div className="container-fluid">
          <div className="row justify-content-center py-5">
            <div className="col-md-6 py-5">
              <div className="bannerText mt-0 d-flex align-items-center">
                <div className="text-center px-lg-5 py-5">
                  <h1 className="px-3">Let us carry your baggage from doorstep to doorstep</h1>
                  <a href="https://carterporter.in/" target="_blank" rel="noopener noreferrer">
                    <button className="submitBtn mt-4">Track Order</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="body-header text-center my-4 py-2">
              <h2 className="fw6">Thank You - Your booking is confirmed</h2>
              <h5>Welcome to Hassle free Baggage Transfer Service</h5>
            </div>
            <hr />
            <div className="row">
              <div className="col-12">
                <h4 className="fw6 text-center my-5">ORDER DETAILS</h4>
              </div>
              <div className="col-md-8">
                {
                  orderDetails && orderDetails.length > 0 &&
                  orderDetails.map(data => (
                    airportDisplayHandler(data || {})
                  ))
                }
              </div>
              <div className="col-md-4">
                <div className="card help-card p-4 text-center">
                  <img src={NeedHelp} alt="Neeed-Help" className="m-auto" />
                  <p className="mt-3 mb-2">Need Help?</p>
                  {/* <h3 className="text-info fw6">+91 636 650 1115</h3> */}
                  <h6 className="text-info fw6">
                    <a href="mailto:6ebagport@carterporter.in">6ebagport@carterporter.in</a>
                  </h6>
                  {/* <span className="mt-3">Monday to Friday 9:00 AM to 7:00 PM</span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src={`https://smetrics.goindigo.in/b/ss/indigoaviationprodenvnew/1?AQB=1&r=${document.referrer ? encodeURIComponent(document.referrer) : ''}&g=https%3A%2F%2Fwww.6ebagport.carterporter.in%2Fbooking-confirmation%2F&v65=www.6ebagport.carterporter.in%2Fbooking-confirmation%2F&pageName=CarterX%20%3A%206E%20Bag%20Port%20%3A%20Booking%20Confirmation&c55=CarterX%20%3A%206E%20Bag%20Port%20%3A%20Booking%20Confirmation&events=event138&AQE=1`} height='1' width='1' border='0' style={{ display: 'none' }} />
    </section>
  )
}