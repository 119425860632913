import React from 'react'

export default function CancellationAndRefund() {
  return (
    <section class="cancellation-and-Refund-policy">
      <div class="faq-body pb-5">
        <div class="container">
          <div class="body-header">
            <h4 class="body-heading bold">Cancellation & Refund Policy</h4>
          </div>
        </div>
        <div class="content container">
          <p class="bold">6EBagPort - For orders towards the Airport:  </p>
          <table>
            <tbody>
              <tr>
                <td>
                  <p><b>Ticket cancelled by Passenger:</b> <br />
                    Post booking confirmation before 24 Hours
                  </p>
                </td>
                <td>
                  <p>
                    100% of order value will be refunded back to source account
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p><b>Ticket cancelled by Passenger:</b> <br />
                    Post booking confirmation  after 24 Hours
                  </p>
                </td>
                <td>
                  <p>The order can be rescheduled for service on any IndiGo flight for the next 7 days. </p>
                  <p>Please use the confirmation email and write to <a href="mailto:6EBagport@carterporter.in">6EBagport@carterporter.in</a> to redeem the service at least 36 hours before the date of service.</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p><b>Ticket cancelled by Passenger post pick up of order#: </b><br />
                    Post booking confirmation before 12 – 4 hours to departure
                  </p>
                </td>
                <td>
                  <p>
                    No Refund. Baggage will be redirected back to the pick up address at extra cost. Passenger will bear the cost of redirection to the city on the same order value paid for the 1st service.. <br />
                    The order to the city will be delivered within 6 – 8 hours of next CarterX dispatch from the airport. <br />
                    If the passenger delays in redirection email for more than 12 hours after the order# is picked then additional daily storage charge at the airport will also be levied to be paid by the passenger in addition to the reschedule delivery charges. <br />
                    The redirection/reschedule should be communicated by passenger to CarterX via email with Order# to <a href="mailto:6EBagport@carterporter.in">6EBagport@carterporter.in.</a>
                  </p>
                </td>
              </tr>

              <tr>
                <td>
                  <p><b>No Response at Pickup Location:
                    No Response at Pickup Location or Not received Pickup Location update from passenger before 12 hours of departure:
                  </b><br />No response from passenger to get the pick up location for up to 12 hours of departure time. <br />
                    No response from the location contact or customer at pick up location, after 15 minutes of waiting order will be considered as cancelled. </p>
                </td>
                <td>
                  <p>The cancellation charges are 100% of order amount citing no response at location.
                  </p>
                </td>
              </tr>

              <tr>
                <td>
                  <p><b>Failure to meet CarterX before entering  Airport 120 – 60 minutes prior to departure time </b><br />
                    Meet with CarterX at the terminal is compulsory and luggage cannot be brought into the terminal or handed over to IndiGo without entering the terminal with passenger. </p>
                </td>
                <td>
                  <p>The cancellation charges are 100% of order amount citing no response at delivery location.  </p>
                  <p>Redirecting of the order to be done by passenger directly with CarterX at an extra charge. Extra charge to be borne completely by passenger.</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p><b>Flight cancelled by IndiGo</b> Bags are not. Picked up by CarterX:</p>
                  <p>Post booking confirmation before 48 – 12  hours to departure </p>
                </td>
                <td>
                  <p>The order can be rescheduled for service on any IndiGo flight for the next 7 days. </p>
                  <p>Please use the confirmation email and write to <a href="mailto:6EBagport@carterporter.in">6EBagport@carterporter.in</a> to redeem the service at least 24 hours before the time of flight departure.</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p><b>Flight cancelled by IndiGo</b> Bags are Picked up by CarterX:</p>
                  <p>Post booking confirmation before 12 – 4 hours to departure and passenger rescheduled on another IndiGo within 24 hours   </p>
                </td>
                <td>
                  <p>The baggage will be stored with CarterX if the passenger is rescheduled on a IndiGo flight within 24 hours. </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p><b>Flight cancelled by IndiGo</b> Bags are Picked up by CarterX:</p>
                  <p>Post booking confirmation before 12 – 4 hours to departure and not rescheduled on an IndiGo flight </p>
                </td>
                <td>
                  <p>Baggage will be redirected back to the pick up address at no extra charge
                    The order to the city will be delivered within 6 – 8 hours of next CarterX dispatch from the airport.
                  </p>
                  <p>If redirection is not an option: Luggage will be stored in the airport at no extra cost for another 24 hours till the next rescheduled IndiGo flight and will have to be communicated by passenger to CarterX via email with Order# to <a href="mailto:6EBagport@carterporter.in">6EBagport@carterporter.in</a> Luggage has to be collected at the airport thereafter by passenger. </p>
                  <p>If passenger is flying a non IndiGo airline then luggage order will be delivered to the passenger outside the terminal. </p>

                </td>
              </tr>
              <tr>
                <td>
                  <p><b>Post Pick up: </b> No show by passenger at the airport after 6 hours Gate meet time. Delivery at Local address back at the city.</p>
                </td>
                <td>
                  <p>Passenger will have to redirect and Reschedule order will be made with same order value and rescheduled back for delivery at the location of pick up. Local delivery to another location or delivery at the airport will apply for rescheduled order. Order amount will be extra and the same will have to paid by passenger as per charges as per <a href="www.carterporter.in">www.carterporter.in</a> </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <b>Post Pick up: </b> Delayed show of up to 06 hours after gate meet time. Delivery at Airport.</p>
                </td>
                <td>
                  <p>Passenger will have to redirect and Reschedule order will be made with local delivery charges will apply for rescheduled order for luggage storage services. Order amount will be extra and the same will have to paid by passenger as per charges as per <a href="www.carterporter.in">www.carterporter.in</a></p>
                </td>
              </tr>
              <tr>
                <td>
                  <p><b>Cancellation and passenger Rescheduled on IndiGo Flight</b></p>
                </td>
                <td>
                  <p></p>
                </td>
              </tr>
              <tr>
                <td>
                  <p><b>Reschedule of IndiGo Flight on the same day departure or up to 24 hours</b></p>

                </td>
                <td>
                  <p>No Refund. Luggage will be stored till delivery on same or rescheduled order number without any charge. </p>

                </td>
              </tr>
              <tr>
                <td>
                  <p><b>Reschedule of IndiGo Flight on alternative date of travel before date of service</b></p>
                </td>
                <td>
                  <p>No Refund. The order date can be redeemed for another date of service for up to 7 days if CarterX is notified 24 hours prior with an email to <a href="mailto:6EBagPort@carterporter.in">6EBagPort@carterporter.in</a> by passenger directly with Order# </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p><b>Reschedule of IndiGo Flight on alternative date of travel  or after date of service</b></p>
                </td>
                <td>
                  <p>Baggage will be redirected back to the pick up address at no extra charge <br />
                    The order to the city will be delivered within 6 – 8 hours of next CarterX dispatch from the airport.
                  </p>
                  <p>If redirection is not an option: Luggage will be stored in the airport at no extra cost for another 24 hours till the next rescheduled IndiGo flight and will have to be communicated by passenger to CarterX via email with Order# to <a href="mailto:6EBagPort@carterporter.in">6EBagPort@carterporter.in</a> Luggage has to be collected at the airport thereafter by passenger. </p>
                  <p>If passenger is flying a non IndiGo airline then luggage order will be delivered to the passenger outside the terminal. </p>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />

          <p class="bold">6EBagPort - For orders from the Airport:  </p>
          <table>
            <tbody>
              <tr>
                <td>
                  <p><b>Ticket cancelled by Passenger: </b> <br />
                    Post booking confirmation before 24 hours of service

                  </p>
                </td>
                <td>
                  <p>100% of order value will be refunded back to source account
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p><b>Ticket cancelled by Passenger:
                  </b><br />
                    Post booking confirmation after 24 Hours
                  </p>
                </td>
                <td>
                  <p>The order can be rescheduled for service on any IndiGo flight for the next 7 days. </p>
                  <p>Please use the confirmation email and write to <a href="mailto:6EBagport@carterporter.in">6EBagport@carterporter.in</a> to redeem the service at least 24 hours before the date of service.</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p><b>No Response at Baggage Belt to Meet CarterX</b>
                  </p>
                </td>
                <td>
                  <p>The cancellation charges are 100% of order amount citing no response at location. </p>
                  <p>CarterX or IndiGo will not  be responsible for the baggage or order if CarterX personnel is not met by the passenger at the baggage belts. </p>
                </td>
              </tr>

              <tr>
                <td>
                  <p><b>No Response at Delivery Location or lack of Delivery location update from passenger for up to 12 hours after arrival</b></p>
                  <p>No response from the location contact or customer at Delivery location, after 15 minutes of waiting, order will be considered as cancelled. </p>
                </td>
                <td>
                  <p>The cancellation charges are 100% of order amount citing no response at location. </p>
                  <p>Redirecting of the order to be done by passenger directly with CarterX at an extra charge borne by the passenger. </p>
                  <p>Redirection charges will be same as the primary order value.</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p><b>Flight cancelled by IndiGo: </b></p>
                  <p>Post booking confirmation before 48 - 2 hours before Arrival </p>
                </td>
                <td>
                  <p>The order can be rescheduled for service on any IndiGo flight for the next 7 days. </p>
                  <p>Please use the confirmation email and write to <a href="mailto:6EBagport@carterporter.in">6EBagport@carterporter.in</a> to redeem the service at least 24 hours before the date of service.</p>

                </td>
              </tr>
              <tr>
                <td>
                  <p><b>Cancellation and Reschedule on IndiGo Flight</b></p>
                </td>
                <td>
                  <p></p>
                </td>
              </tr>
              <tr>
                <td>
                  <p><b>Reschedule of IndiGo Flight on the same day of arrival</b></p>
                </td>
                <td>
                  <p>No Refund. New time of service, IndiGo Flight Number and flight time to be updated to <a href="mailto:6EBagport@carterporter.in">6EBagport@carterporter.in</a> by passenger directly notifying the change in arrival and the same service will be completed for the new flight arrival time.  </p>

                </td>
              </tr>
              <tr>
                <td>
                  <p><b>Reschedule of IndiGo Flight on alternative date of travel (before or after) date of service</b></p>
                </td>
                <td>
                  <p>Order can be Redeemed for the new date of arrival. New date of service and flight time to be updated to <a href="mailto:6EBagport@carterporter.in">6EBagport@carterporter.in</a> with Order# by passenger directly notifying the change in arrival and the order will be honoured if the date of service is up to 48 hours as alternative/rescheduled date of arrival.</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p><b>If passenger arrives on the same date of service or on another date with a Non IndiGo Airline</b></p>
                </td>
                <td>
                  <p>Order can be Redeemed for any IndiGo flight. New date of service and flight time to be updated to <a href="mailto:6EBagport@carterporter.in">6EBagport@carterporter.in</a> with Order# by passenger to opt for the same service anytime for 7 days on any other IndiGo flight for the same passenger or nominate another passenger.</p>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <p>Refunds can be tracked under Manage Orders of your account. </p>
          <p>All refunds will be processed within 7 working days. </p>
          <p>All refund payments will be processed back to the source account. Refund amount will reflect under the name ‘Carterporter Private Limited’ in the source statement of accounts.
            Terms & Conditions apply.
          </p>
          <p>Forward all queries to <a href="mailto:6EBagport@carterporter.in">6EBagport@carterporter.in</a></p>
        </div>
      </div>
    </section>
  )
}