//todo Layout Import
import UserLayout from 'layout/UserLayout';

//todo Component Import
import Landing from 'view/landing/Landing';
import TermsAndConditions from 'view/termsAndConditions/TermsAndConditions';
import Tarrif from 'view/tarrif/Tarrif';
import FAQs from 'view/faqs/FAQs';
import DepartureBookingInfo from 'view/departureBookingInfo/DepartureBookingInfo';
import ArrivalBookingInfo from 'view/arrivalBookingInfo/ArrivalBookingInfo';
import CancellationAndRefund from 'view/cancellationAndRefund/CancellationAndRefund';
import BookingInformation from 'view/bookingInformation/BookingInformation';
import BookingConfirmation from 'view/landing/form/BookingConfirmation';

export const routeConfig = [
  {
    path: '/',
    component: Landing,
    layout: UserLayout,
  },
  {
    path: '/terms-and-conditions',
    component: TermsAndConditions,
    layout: UserLayout,
  },
  {
    path: '/tarrif',
    component: Tarrif,
    layout: UserLayout,
  },
  {
    path: '/faqs',
    component: FAQs,
    layout: UserLayout,
  },
  {
    path: '/departure-details',
    component: DepartureBookingInfo,
    layout: UserLayout,
  },
  {
    path: '/arrival-details',
    component: ArrivalBookingInfo,
    layout: UserLayout,
  },
  {
    path: '/cancellation-and-refund-policy',
    component: CancellationAndRefund,
    layout: UserLayout,
  },
  {
    path: '/booking-information',
    component: BookingInformation,
    layout: UserLayout,
  },
  {
    path: '/booking-confirmation',
    component: BookingConfirmation,
    layout: UserLayout,
  },
]

