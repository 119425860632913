import React from 'react'

export default function ArrivalBookingInfo() {
  return (
    <div className='container'>
      <div className='row'>
        <div className="col-12 mt-5">
          <h4 className="text-center fw6">Booking Information</h4>
          {/* <div className="container video arrival-card">
        <div>
          <p className="mt-5 font-weight-bold mb-0">Mandatory:</p>
          <div className="cuspP mb-3">Passenger must certify status of his/her health through the Aarogya Setu App. Green/Safe is mandatory for pickup of order and delivery of Order
            </div>
          <div className="cuspP">
            <strong>Arriving passenger needs to meet CarterX personnel before leaving the terminal</strong></div>
          <div className="cuspP">
            Booking address can be modified upto 24 hours prior to the slot time</div>
          <div className="cuspP">
            Arrival bookings are accepted upto 24 hours prior to the slot time</div>
          <div className="cuspP">
            Passenger ID proof is mandatory at the time of pick up at the terminal and will be validated on delivery</div>
          <div className="cuspP">
            Bags cannot be added/subtracted once booking is confirmed</div>
          <div className="cuspP">
            <span>Maximum dimension of each bag cannot exceed 319 linear cms
            ( 81cm (H) x 119cm (W) x 119 cm (D) ). Maximum weight can be up to 32/kgs per piece.&nbsp;</span></div>
          <div className="cuspP">
            Multiple bookings can be made under single PNR</div>
          <div className="cuspP">
            No charges for excess weight are levied for Arrival passengers</div>
          <div className="cuspP">
            Upto 2 bags are accepted with each booking</div>
          <div className="cuspP">
            Customer Care Bookings are confirmed post payment</div>
          <div className="cuspP mb-3">
            Delivery will be made based on the slot time. Slot Times are prefixed based on your arrival at the airport.
          Address will be collected in detail before delivery</div>
          <div className="cuspP">
            Arrival from 12am - 3pm | Same day delivery between 5pm &ndash; 11.55pm</div>
          <div className="cuspP">
            Arrival from 3pm &ndash; 12 am|Next day delivery between 7am&ndash; 2pm</div>
          <div className="cuspP mt-3">
            <div>Orders from containment zones will not be delivered as per
            government regulations&nbsp;</div>
            <div>No delivery during curfew times imposed by the government
          </div>
            <div><span>All bags of each order will be
            delivered at the doorstep to minimise exposure. Passenger/household personnel should continue to wear mask
              while interacting with CarterX personnel at delivery location and the airport &nbsp;</span></div>
          </div>
        </div>
      </div> */}

          <div class="infoParagraph mt-5">
            <p class="heading">Departure:<span> Bags will be delivered till the check-in counters</span></p>
            <p class="heading mb-3">Arrival:<span> Bags will be collected at Baggage Belts</span></p>
            <p>Doorstep and Curbside pick up and delivery both are available.</p>
            <p>Curbside includes to or from airport parking
              or cab drop off points and pick up lanes of the airport terminal only.</p>
            <p>Departing passenger needs to meet CarterX personnel before entering the terminal</p>
            <p>Arriving Customer needs to meet CarterX personal before exiting the terminal</p>
            <p>Passenger must have completed his/her Web Check-in prior to the pick up slot time</p>
            <p>Passenger ID proof is mandatory at the time of pick up and will be validated on delivery before entering the
              terminal</p>
            <p>Security Declaration must be duly filled and signed for doorstep pick up. </p>
            <p>The Passenger must arrive at the airport at least 120 minutes prior to departure for both doorstep and
              curbisde pickup.</p>
            <p>Multiple bookings can be made under a single PNR</p>
            <p>Charges for excess number of bags will be collected by the airline as per the airline directive.</p>
            <p>We advice that excess weight be purchased on airline website for shorter wait times at counters.</p>
            <p>Maximum dimension of each bag cannot exceed 319 linear cms ( 81cm (H) x 119cm (W) x 119 cm (D) ).</p>
            <p>Maximum weight can be up to 32/kgs per piece as per CarterX carriage and is not indicative of airline weight
              limitations or excess weight.</p>
            <p>Excess weight should be purchased prior or during the time of pickup from the airline website at doorstep for
              smoother pick up and delivery.</p>
            <p>Bags cannot be added/subtracted once booking is confirmed. </p>
            <p>Booking pick up address can be modified upto 24 hours prior </p>
            <p>Doorstep Departure bookings are accepted upto 24 hours prior to departure.</p>
            <p>Curbside departure can be booked and availed up to 3 hours prior to meeting carterX at airport.</p>
            <p>Customer Care Bookings are confirmed post payment</p>
            <p>All electronic items/oversized will be charged as Fragile with INR 399 per Kg as order modification piece.
            </p>
            <p>All electronics should be in their original packing only</p>
            <p>Booking pick up address will be updated post the booking and can be modified upto 24 hours prior for local
              orders only</p>
            <p>Keep bags packed before porter arrives.</p>
            <p>CarterX personnel will call prior to allocate Suitable Pick up Slot Time and complete the
              address from the given pin code</p>
            <p>All orders will be delivered only on receipt of full payment of order and order modification</p>
            <p class="mt-3 heading">Departure Slots for local orders Doorstep Pick Up:</p>
            <p>-07 am – 11 am | Order available at Airport after 02 pm</p>
            <p>-11 am – 03 pm | Order available at Airport after 06 pm</p>
            <p>-03 pm – 07 pm | Order available at Airport after 10 pm</p>
            <p>-07 pm – 12 am | Order available at Airport after 03 am</p>
            <p>-04 am – 07 am | Order available at Airport after 10 am</p>
            <p class="mt-3 heading">Arrival Slots for Local Orders Doorstep Delivery:</p>
            <p>Arrival Before 3 pm : Order will be delivered on the same day before 11:55 pm</p>
            <p>Arrival After 3 pm : Order will be delivered on the next day before 2 pm</p>
            <p class="mt-3 heading">Arrival Cancellation and Refund: </p>
            <p>Doorstep Delivery: Cancellation any time </p>
            <p>100% Refund 24 hours before time to meet CarterX at Airport for doorstep bookings</p>
            <p>100% Refund 3 hours before time to meet CarterX at Airport for curbside</p>
            <p class="mt-3 heading">Departure Cancellation and Refund: </p>
            <p>Doorstep Delivery: Cancellation any time </p>
            <p>100% Refund 24 hours before time to meet CarterX at Airport for doorstep bookings</p>
            <p>100% Refund 3 hours before time to meet CarterX at Airport for curbside</p>
          </div>
        </div>
      </div>
    </div>
  )
}