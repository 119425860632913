export const CarterX_API = {
  getCountryCodeList: {
    url: `${process.env.REACT_APP_API_BASE_URL_1 + process.env.REACT_APP_API_BASE_URL_2}r=customer-api/getcountrycodes`,
    method: 'GET',
  },
  getCityList: {
    url: `${process.env.REACT_APP_API_BASE_URL_2}r=v2/airport-of-operation-api/region-new`,
    method: 'GET',
  },
  checkPinCode: {
    url: `${process.env.REACT_APP_API_BASE_URL_1 + process.env.REACT_APP_API_BASE_URL_2}r=v2/order-api/checkpincodeavailabilityv2`,
    method: 'POST',
  },
  getPrice: {
    url: `${process.env.REACT_APP_API_BASE_URL_1 + process.env.REACT_APP_API_BASE_URL_2}r=v3/thirdparty-corporate-api/calculation`,
    method: 'POST',
  },
  formSubmit: {
    url: `${process.env.REACT_APP_API_BASE_URL_1 + process.env.REACT_APP_API_BASE_URL_2}r=v3/thirdparty-corporate-api/booking`,
    method: 'POST',
  },
  slotTime: {
    url: `${process.env.REACT_APP_API_BASE_URL_2}r=order-api/getslots`,
    method: 'GET',
  },
  PICKUP_DROP_ADDRESS: {
    url: `${process.env.REACT_APP_API_BASE_URL_1 + process.env.REACT_APP_API_BASE_URL_2}r=v4/redeembook-api/get-pick-drop-addres`,
    method: 'POST',
  }
}


export default CarterX_API;