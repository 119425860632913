import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'routes/Routes';
import 'assets/styles/style.scss';
//* react-toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
////* react-toastify

function App() {
  return (
    <BrowserRouter>
      <Routes />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </BrowserRouter>
  );
}

export default App;
